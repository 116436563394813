import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../components/Processing/Processing';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import moment from 'moment';
import {Pagination} from 'react-bootstrap';
import fileDownload from 'js-file-download';
import axios from 'axios';

const EmailSubscribers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sells, setSells] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setLoading(true);
    getEmail(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getEmail = (pageNumber) => {
    console.log('pageNumber', pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,
    };

    axios({
      url: `${base_url}user/subscribe/get-subscribe?search=${searchQuery}`,
      params: params,
      method: 'get',
    })
      .then((res) => {
        setSells(res?.data?.data);
        setTotalPages(res?.data?.page);
        setLoading(false);
        console.log('res subscribe', res);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getEmail(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getEmail(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const deleteEmail = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/subscribe/delete-subscribe?id=${deleteId}`,
      'delete',
      '',
      '',
    )
      .then((res) => {
        console.log('res delete : ', res);
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getEmail();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const getCsv = () => {
    fetchDataFromAPI(
      `user/subscribe/get-subscribe?downloadCSVFile=true&page=${currentPage}&search=${searchQuery}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('res --> csv', res);
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  //   const markAsRead = () => {
  //     setLoading(true);
  //     fetchDataFromAPI(
  //       "user/sells?type=1&unreadAllComments=true",
  //       "patch",
  //       "",
  //       ""
  //     )
  //       .then((res) => {
  //         getEmail();
  //         setLoading(false);
  //         console.log("res", res);
  //       })
  //       .catch((error) => {
  //         setLoading(false);

  //         console.log("error", error);
  //       });
  //   };
  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Email Subscribers</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="d-flex align-items-center mt-3">
          <div className="col-md-6">
            <div className="input-group ms-4 rounded-1 overflow-hidden">
              <input
                className=""
                type="text"
                name="query"
                placeholder="Search"
                title="Enter search keyword"
                style={{
                  flex: 1,
                  padding: '8px',
                  border: '1px solid #ccc',
                }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  getEmail();
                  setCurrentPage(1);
                }}
                type="submit"
                title="Search"
                style={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  border: 'none',
                  padding: '8px 15px',
                }}>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-2">
            <div className="d-flex gap-2 ms-5 align-items-center">
              <div onClick={() => getCsv()}>
                {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                {/* <i className="bi bi-share"></i> */}
                <img
                  role="button"
                  style={{height: 20, width: 25}}
                  src={require('../../assets/images/export.png')}
                  alt="logo"
                />
                {/* </CSVLink> */}
              </div>
            </div>
          </div>

          {/* <div className="col d-flex justify-content-end ">
            <button
              type="submit"
              className="btn btn-secondary align-self-end btn__add me-2"
              onClick={() => markAsRead()}
            >
              Mark as Unread
            </button>
          </div> */}

          {/* <div className="col d-flex justify-content-end ">
                      <button
                        type="submit"
                        className="btn btn-primary align-self-end btn__add"
                        onClick={() => navigate("/salesplates/add")}
                      >
                        Add Plates
                      </button>
                    </div> */}
        </div>
        <hr />
        {/* <form className="mx-3 mt-3">
          <div className="d-flex  align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="col align-self-end">
                <button
                  type="submit"
                  className="btn btn-secondary align-self-end btn__add me-2"
                  onClick={() => markAsRead()}
                >
                  Mark as Unread
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr /> */}
        <div className="card-body">
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col">Date & Time</th>

                  <th scope="col">Email</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {sells?.map((item, index) => {
                  //   const isUnread = !item.is_read;
                  //   const rowClass = isUnread ? "fw-bold" : "";
                  return (
                    <tr className={`align-middle`} role="button">
                      <td>
                        {item?.createdAt
                          ? moment(item?.createdAt)?.format('DD/MM/YYYY h:mm a')
                          : '-'}
                      </td>

                      <td>{item?.email}</td>

                      <td className="">
                        <div className="d-flex">
                          {/* <div
                            className="edit-icon btn btn-sm btn-warning me-2"
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/sell-forms-view/${item?._id}`, {
                                state: { user: item },
                              });
                            }}
                          >
                            <i class="bi bi-eye"></i>
                          </div> */}
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteId(item?._id);
                            }}>
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-5 cursor-pointer"
                  onClick={handlePrevious}
                  role="button">
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                sells.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-5 cursor-pointer"
                    onClick={handleNext}
                    role="button">
                    Next
                  </p>
                )}
            </Pagination>
          )}

          {totalPages <= 1 && sells?.length === 0 && (
            <p className="text-center">No data available</p>
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteEmail}
        title="Delete Email"
        body="Are you sure you want to delete this record?"
      />
    </div>
  );
};

export default EmailSubscribers;
