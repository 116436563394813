import React, {useState, useEffect} from 'react';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import Processing from '../../components/Processing/Processing';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Pagination} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
// import { FormGroup } from "react-bootstrap";
import {toast} from 'react-toastify';
import fileDownload from 'js-file-download';
import DeleteModal from '../../components/DeleteModal/DeleteModal';

const GetCustomer = () => {
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const [paymentStatus, setPaymentStatus] = useState('false');
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [downloadCSVFile, setDownloadCSVFile] = useState('false');

  const navigate = useNavigate();

  useEffect(() => {
    getCustomer(currentPage);
  }, [currentPage, paymentStatus]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getCsv = async () => {
    const params = {
      paymentStatus: paymentStatus,
      downloadCSVFile: true,
    };

    try {
      const response = await axios.get(
        `${base_url}user/managerCustomer/get-manage-customer`,
        {
          params: params,
        },
      );

      fileDownload(response.data, 'data.csv');
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCustomer = async (pageNumber) => {
    console.log('page number', pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,

      // downloadCSVFile:true
    };
    console.log('params', params);
    axios({
      url: `${base_url}user/managerCustomer/get-manage-customer?searchkey=${searchQuery}`,
      params: params,
      method: 'get',
    })
      .then((response) => {
        console.log('res', response?.data);
        console.log('get customer manage', response?.data?.data);
        // console.log("conform payment", paymentStatus)
        // setDownloadCSVFile(response?.data,"data.csv")
        setCustomer(response?.data?.data);
        setTotalPages(response?.data?.totalPage);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      reg: '',
    },
    validationSchema: Yup.object({
      reg: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      // getReg(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getCustomer(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getCustomer(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/managerCustomer/delete-manage-customer?customer_id=${deleteId}`,
      'delete',
      '',
      '',
    )
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getCustomer();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleVisit = () => {
    setPaymentStatus('false');
  };

  // Event handler for selecting Lynk & iCabbi App
  const handleFinalPayment = () => {
    setPaymentStatus('true');
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Dashboard</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage Customer</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <form className="mx-3 " onSubmit={formik.handleSubmit}>
                    <div className="d-flex mb-3 align-items-center justify-content-between">
                      <div className="d-flex align-items-center"></div>
                    </div>
                  </form>
                  <div className="ms-3 col-md-3"></div>
                  <div className="row mx-2">
                    <div className="col-md-6">
                      {/* <label htmlFor="reg" className="form-label">
                        Reg:
                      </label> */}
                      <div className="input-group rounded-1 overflow-hidden">
                        <input
                          className=""
                          type="text"
                          name="query"
                          placeholder="Search"
                          title="Enter search keyword"
                          style={{
                            flex: 1,
                            padding: '8px',
                            border: '1px solid #ccc',
                          }}
                          value={searchQuery}
                          onChange={(event) =>
                            setSearchQuery(event.target.value)
                          }
                        />

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            getCustomer();
                          }}
                          type="submit"
                          title="Search"
                          style={{
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            padding: '8px 15px',
                          }}>
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex gap-2 align-items-center justify-content-end">
                        {/* <button
                          onClick={(e) => {
                            e.preventDefault();
                            getCustomer();
                          }}
                          type="submit"
                          title="Search"
                          style={{
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            padding: "8px 15px",
                            borderRadius: "5px",
                          }}
                        >
                          <i className="bi bi-search"></i>
                        </button> */}
                        <div onClick={() => getCsv()}>
                          {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                          {/* <i className="bi bi-share"></i> */}
                          <img
                            role="button"
                            style={{height: 20, width: 25}}
                            src={require('../../assets/images/export.png')}
                            alt="logo"
                          />
                          {/* </CSVLink> */}
                        </div>
                        <div className="  ">
                          <div className="col d-flex justify-content-end  ">
                            <button
                              type="submit"
                              className="btn btn-primary "
                              onClick={() => navigate('/customer/add')}>
                              Add Customer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    {/* <ul className="nav nav-tabs nav-tabs-bordered">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            paymentStatus === "false" ? "no" : ""
                          }`}
                          onClick={handleVisit}
                          data-bs-toggle="tab"
                          data-bs-target="#visit-app"
                        >
                          Final Payment
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            paymentStatus === "true" ? "yes" : ""
                          }`}
                          onClick={handleFinalPayment}
                          data-bs-toggle="tab"
                          data-bs-target="#payment-confirm"
                        >
                          Payment Pending
                        </button>
                      </li>
                    </ul> */}
                    <hr />
                    <div className="table-responsive card-body pb-0">
                      <table className="table text-nowrap  align-items-end table-hover">
                        <thead>
                          <tr className="">
                            <th scope="col" className="text-center">
                              No
                            </th>
                            {/* <th scope="col" className="text-center">
                            Reg
                          </th> */}
                            <th scope="col" className="text-center">
                              First Name
                            </th>
                            <th scope="col" className="text-center">
                              Last Name
                            </th>
                            <th scope="col" className="text-center">
                              phone
                            </th>
                            <th scope="col" className="text-center">
                              Email
                            </th>

                            <th scope="col" className="text-center">
                              Action
                            </th>
                            {/* <th scope="col">View</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {customer?.map((item, index) => {
                            return (
                              <tr
                                className="text-center"
                                role="button"
                                onClick={() =>
                                  navigate(`/customer/edit/${item?._id}`, {
                                    state: {user: item},
                                  })
                                }>
                                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                                {/* <td className="text-center">
                                {item?.rearPlate}
                              </td> */}
                                <td className="text-center">
                                  {item?.firstName ? item?.firstName : '-'}
                                </td>
                                <td className="text-center">
                                  {item?.lastName ? item?.lastName : '-'}
                                </td>
                                <td className="text-center">
                                  {item?.mobile ? item?.mobile : '-'}
                                </td>
                                <td className="text-center">
                                  {item?.email ? item?.email : '-'}
                                </td>

                                <td className="text-center">
                                  <div className="d-flex justify-content-center ">
                                    <div
                                      className="edit-icon btn btn-sm btn-warning me-2"
                                      role="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(
                                          `/customer/view/${item?._id}`,
                                          {
                                            state: {user: item},
                                          },
                                        );
                                      }}>
                                      <i class="bi bi-eye"></i>
                                    </div>
                                    <div
                                      className="edit-icon btn btn-sm btn-primary me-2"
                                      role="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(
                                          `/customer/edit/${item?._id}`,
                                          {
                                            state: {user: item},
                                          },
                                        );
                                      }}>
                                      <i class="bi bi-pencil-square"></i>
                                    </div>

                                    {/* // delete */}

                                    <div
                                      role="button"
                                      className="delete-icon btn btn-sm btn-danger"
                                      // onClick={() => deleteContent(item?._id)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteId(item?._id);
                                      }}>
                                      <i class="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-5 cursor-pointer"
                    onClick={handlePrevious}
                    role="button">
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  customer.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-5 cursor-pointer"
                      onClick={handleNext}
                      role="button">
                      Next
                    </p>
                  )}
              </Pagination>
            )}

            {totalPages <= 1 && customer?.length === 0 && (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Coustomer"
          body="Are you sure you want to delete this record?"
        />
      </section>
    </main>
  );
};

export default GetCustomer;
