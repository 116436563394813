import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../utils/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Processing from '../../components/Processing/Processing';

const HomeContentEdit = () => {
  const {state} = useLocation();

  const [loading, setLoading] = useState(false);
  console.log('state', state);

  const navigate = useNavigate();

  const handleEditorChange = (html) => {
    formik.setFieldValue('description', html);
  };

  useEffect(() => {
    getContentData();
  }, []);

  const getContentData = () => {
    formik.setFieldValue('title', state?.user?.title);
    formik.setFieldValue('description', state?.user?.description);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter title'),
    description: Yup.string().required('Please enter description'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        description: values?.description,
        title: values?.title,
      };

      fetchDataFromAPI(`user/data?id=${state?.user?._id}`, 'patch', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);
          //   navigate('/driver');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating content: ', error);
        });
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>Content Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>

                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          id="title"
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'title',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label htmlFor="lastName" className="form-label">
                          Description
                        </label>

                        {/* <textarea
                          name="description"
                          id="description"
                          className={`form-control ${
                            formik.touched.description &&
                            formik.errors.description
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.description}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'description',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          style={{height: 200}}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="text-danger">
                              {formik.errors.description}
                            </div>
                          )} */}
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.description}
                            onChange={handleEditorChange}
                            style={{height: 200}}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentEdit;
