import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { base_url, fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import axios from "axios";

const PlateViewerForms = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoading(true);

    getAdmins(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getAdmins = (pageNumber) => {
    console.log("page number", pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,
    };
    axios({
      url: `${base_url}user/contactUs/get-contactUs?type=2`,
      params: params,
      method: "get",
    })
      // fetchDataFromAPI("user/contactUs/get-contactUs?type=2", "get", "", "")
      .then((res) => {
        setAdmin(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getAdmins(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getAdmins(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
          >
            {i}
          </Pagination.Item>
        );
      } else if (paginationItems[paginationItems.length - 1] !== "...") {
        paginationItems.push("...");
      }
    }

    return paginationItems;
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/contactUs/delete-contactUs?id=${deleteId}`,
      "delete",
      "",
      ""
    )
      .then((res) => {
        toast.success("Deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getAdmins();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("customer error: ", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setLoading(false);
      });
  };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const markAsRead = (e) => {
    e?.preventDefault();
    setLoading(true);
    fetchDataFromAPI(
      "user/contactUs?type=2&unreadAllComments=true",
      "put",
      "",
      ""
    )
      .then((res) => {
        getAdmins();
        setLoading(false);
        console.log("res", res);
      })
      .catch((error) => {
        setLoading(false);

        console.log("error", error);
      });
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Plate Viewer Forms</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3 mt-3">
          <div className="d-flex  align-items-center justify-content-between">
            <div className="col align-self-end">
              <button
                // type="submit"
                className="btn btn-secondary align-self-end btn__add me-2"
                onClick={(e) => markAsRead(e)}
              >
                Mark as Unread
              </button>
            </div>
          </div>
        </form>
        <hr />
        <div className="card-body">
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col">Date & Time</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Middle Initials</th>
                  <th scope="col">Message</th>
                  <th scope="col">Subscribed</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {admin.map((item, index) => {
                  const isUnread = !item.is_read;
                  const rowClass = isUnread ? "fw-bold" : "";
                  return (
                    <tr
                      className={`align-middle ${rowClass}`}
                      role="button"
                      onClick={() => {
                        navigate(`/plate-viewer-forms-view/${item?._id}`, {
                          state: { user: item },
                        });
                      }}
                    >
                      <td>
                        {item?.createdAt
                          ? moment(item?.createdAt)?.format("DD/MM/YYYY h:mm a")
                          : "-"}
                      </td>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.email}</td>
                      <td className="">
                        {item?.middle_initials ? item?.middle_initials : "-"}
                      </td>
                      <td>
                        {item?.message?.length > 20
                          ? item?.message?.slice(0, 20) + "..."
                          : item?.message}
                      </td>
                      {/* <td>{item?.created_at}</td> */}
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={item?.is_subscribe}
                          disabled
                        />
                      </td>

                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon btn btn-sm btn-warning me-2"
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/plate-viewer-forms-view/${item?._id}`,
                                {
                                  state: { user: item },
                                }
                              );
                            }}
                          >
                            <i class="bi bi-eye"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteId(item?._id);
                            }}
                          >
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}

          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-5 cursor-pointer"
                  onClick={handlePrevious}
                  role="button"
                >
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                admin.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-5 cursor-pointer"
                    onClick={handleNext}
                    role="button"
                  >
                    Next
                  </p>
                )}
            </Pagination>
          )}

          {totalPages <= 1 && admin?.length === 0 && (
            <p className="text-center">No data available</p>
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Contact"
        body="Are you sure you want to delete this record?"
      />
    </div>
  );
};

export default PlateViewerForms;
