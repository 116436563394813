const Processing = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    background: 'linear-gradient(90deg, #256efe 0%, #256efe 100%)',
    color: '#ffffff',
    fontWeight: '500',
    width: 'auto',
    padding: '1rem 2rem',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
  };

  return (
    // <div
    //   style={{
    //     position: 'absolute',
    //     width: '100%',
    //     height: '100%',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   }}>
    //   <div style={styles}>Processing...</div>
    // </div>
    <div
      style={{position: 'relative'}}
      className="d-flex justify-content-center">
      <div
        style={{
          ...styles,
          width: 150,
          backgroundColor: 'white',
          position: 'absolute',
          top: 300,
        }}>
        Processing...
      </div>
    </div>
  );
};

export default Processing;
