import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const BlogView = () => {
  const {state} = useLocation();

  console.log('state?.user?.image', state?.user);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>View Blog</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="text-center">
                      <img
                        className="border border-3 border-primary rounded-circle"
                        src={state?.user?.image}
                        alt="Profile Img"
                        style={{height: 100, width: 100}}
                      />
                    </div>

                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-6 label">Title :</div>
                            <div className="col-md-6">{state?.user?.title}</div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 label">Sub Title :</div>
                            <div className="col-md-6">
                              {state?.user?.sub_title}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 label">Description :</div>
                            <div className="col-md-6">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: state?.user?.discription,
                                }}></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogView;
