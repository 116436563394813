import React, {useState} from 'react';
import {fetchDataFromAPI} from '../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {FormGroup} from 'react-bootstrap';
import Processing from '../../components/Processing/Processing';

const HomeContentBanner = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const validateImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 1903 && img.height === 695) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = e.target.result;
      };
      reader.onerror = () => {
        resolve(false);
      };
      reader.readAsDataURL(file);
    });
  };
  // const handleFileChange = (e) => {
  //   // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
  //   // setFile(e?.target?.files[0]);
  //   // const image = e?.target?.files[0];
  //   formik.setFieldValue('homeBanner', e.target.files[0]);
  //   const data = new FormData();
  //   data.append('homeBanner', e?.target?.files[0]);
  //   setLoading(true);
  //   fetchDataFromAPI('user/upload', 'post', data, '')
  //     .then((res) => {
  //       console.log('res: ', res?.data);
  //       setURL(res?.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log('error: ', error);
  //       setLoading(false);
  //     });
  // };

  const formik = useFormik({
    initialValues: {
      homeBanner: null,
    },
    validationSchema: Yup.object({
      homeBanner: Yup.mixed()
        .required('Please select a banner')
        .test(
          'fileDimensions',
          'Image dimensions must be 1903 x 695 pixels',
          async (value) => {
            if (!value) return false;
            const isValid = await validateImage(value);
            return isValid;
          },
        ),
    }),
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const {homeBanner} = values;
      console.log('values', values?.homeBanner);

      try {
        const formData = new FormData();
        setLoading(true);
        formData.append('banner', homeBanner);

        fetchDataFromAPI('user/banner', 'post', formData, '')
          .then((response) => {
            toast.success('Banner added successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            formik.resetForm();
            setLoading(false);
            console.log('response', response);
          })
          .catch((error) => {
            toast.error('Error while updating User', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Home Content Banner</span>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Select Banner
                        </label>
                        <FormGroup>
                          <input
                            className={`form-select ${
                              formik.touched.homeBanner &&
                              formik.errors.homeBanner
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="file"
                            name="image"
                            accept="image/*"
                            id="homeBanner"
                            // onChange={handleFileChange}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              formik.setFieldValue('homeBanner', file);

                              // Display the selected image
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  setSelectedImage(e.target.result);
                                };
                                reader.readAsDataURL(file);
                              } else {
                                setSelectedImage(null);
                              }
                            }}
                          />
                        </FormGroup>
                        {formik.touched.homeBanner &&
                          formik.errors.homeBanner && (
                            <div className="text-danger">
                              {formik.errors.homeBanner}
                            </div>
                          )}
                        {selectedImage && (
                          <div className="mt-4 ">
                            <img
                              style={{height: '100%', width: '100%'}}
                              className="border border-1 border-dark"
                              src={selectedImage}
                              alt="Selected Banner"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">
                        {!loading && (
                          <span className="indicator-label">Add Banner</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentBanner;
