import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import AddModal from "../../components/AddModal/AddModal";

const Coupons = () => {
  // hooks
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCoupons();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  // functions
  const getCoupons = async () => {
    setLoading(true);
    fetchDataFromAPI("user/coupon", "get", null, null)
      .then((response) => {
        console.log("coupons res:  ", response);
        setCoupons(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // delete coupon function
  const deleteCoupon = async () => {
    setLoading(true);
    fetchDataFromAPI(`user/coupon?id=${deleteId}`, "delete", null, null)
      .then((response) => {
        console.log("delete coupon res:  ", response);
        getCoupons();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };

  // hide Add Modal function
  const hideAddModal = () => {
    setShowAddModal(false);
  };

  // add modal fields
  const addCouponFields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      placeholder: "Enter title",
      required: true,
    },
    {
      name: "discription",
      label: "Description",
      type: "text",
      placeholder: "Enter description",
      required: true,
    },
    {
      name: "code",
      label: "Coupon code",
      type: "text",
      placeholder: "Enter coupon code",
      required: true,
    },
    {
      name: "type",
      label: "Discount type",
      type: "select",
      options: [
        { value: "", label: "Select Discount In" },
        { value: 1, label: "%" },
        { value: 2, label: "£" },
      ],
      required: true,
    },
    {
      name: "value",
      label: "Discount value",
      type: "text",
      placeholder: "Enter discount value",
      required: true,
    },
  ];

  // add coupon function
  const addCoupon = async (values) => {
    setLoading(true);
    fetchDataFromAPI("user/coupon", "post", values, null)
      .then((response) => {
        console.log("add coupon res:  ", response);
        getCoupons();
        setShowAddModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };

  // coupon list table and add coupon button like other table component
  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Coupons</li>
          </ol>
        </nav>
      </div>
      <div className="card border-0">
        <div className="card-header bg-white border-bottom-0 d-flex justify-content-between align-items-center">
          <h3 className="card-title">Coupons List</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Coupon
          </button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Code</th>
                  <th scope="col">Discount In</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((coupon, index) => (
                  <tr key={coupon?._id}>
                    <td>{index + 1}</td>
                    <td>{coupon?.code}</td>
                    <td>{coupon?.type == 1 ? "%" : "£"}</td>
                    <td>
                      {coupon?.type == 1
                        ? `${coupon?.value}%`
                        : `£${coupon?.value}`}
                    </td>
                    <td>{coupon?.discription}</td>
                    <td>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-primary me-2"
                          onClick={() =>
                            navigate(`/coupons/edit/${coupon?._id}`)
                          }
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => setDeleteId(coupon?._id)}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* add coupon modal */}
      <AddModal
          show={showAddModal}
          onHide={hideAddModal}
          title="Add Coupon"
          fields={addCouponFields}
          onAdd={addCoupon}
        />
        {/* delete coupon modal */}
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteCoupon}
        title="Delete Coupon"
        body="Are you sure you want to delete this coupon?"
      />
    </div>
  );
};

export default Coupons;
