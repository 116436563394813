import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";

const HomeContentBulletsAdd = () => {
  const [loading, setLoading] = useState(false);
  const [bulletsList, setBulletsList] = useState([]);
  const navigate = useNavigate();
  const addBullet = () => {
    const { newBullet, bullets } = formik.values;
    if (newBullet.trim() !== "") {
      const updatedBullets = [...bullets, newBullet.trim()];
      formik.setValues({
        ...formik.values,
        bullets: updatedBullets,
        newBullet: "", // Clear the input field after adding the bullet
      });
      setBulletsList(updatedBullets); // Update bulletsList state for display
    }
  };

  const formik = useFormik({
    initialValues: {
      main_title: "",
      title: "",
      bullets: [],
      newBullet: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      console.log("object");

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        main_title: values?.main_title,
        bullets: values?.bullets,
        title: values?.title,
      };

      fetchDataFromAPI(`user/data/bullets`, "post", body, "")
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });

          console.log("bullet res:", res?.data);
          navigate("/home-content/bullets");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating content: ", error);
        });
    },
  });

  const handleEditorChange = (html) => {
    formik.setFieldValue("bullets", html);
  };

  const handleEditorChangeTitle = (html) => {
    formik.setFieldValue("title", html);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/">Home Content Bullets</Link>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="col-md- mb-2">
                      <h4
                        htmlFor="main_title"
                        className="form-label text-black"
                      >
                        Main Title
                      </h4>

                      <input
                        name="main_title"
                        type="text"
                        className="form-control"
                        id="main_title"
                        style={{}}
                        value={formik.values.main_title}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "main_title",
                            e.target.value?.trimStart()
                          );
                        }}
                      />
                      {formik.touched.main_title &&
                        formik.errors.main_title && (
                          <div className="text-danger">
                            {formik.errors.main_title}
                          </div>
                        )}
                    </div>
                    <div className="row mb-3">
                      <div className="col-md- mb-5">
                        <h4 htmlFor="title" className="form-label text-black">
                          Title
                        </h4>

                        <ReactQuill
                          theme="snow"
                          value={formik.values.title}
                          onChange={handleEditorChangeTitle}
                          style={{ height: 100 }}
                          // onBlur={formik.handleBlur}

                          // style={{height: 200}}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <h4
                          htmlFor="lastName"
                          className="form-label text-black mt-4"
                        >
                          Bullets
                        </h4>
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.bullets}
                            onChange={handleEditorChange}
                            style={{ height: 100 }}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                        {/* Display added bullets */}

                        {formik.touched.bullets && formik.errors.bullets && (
                          <div className="text-danger">
                            {formik.errors.bullets}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center ">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Save Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      <span className="m-2"></span>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentBulletsAdd;
