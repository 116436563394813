import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { Button, Modal } from "react-bootstrap";
import { getUser } from "../../utils/constants";

const ViewEnquiry = () => {
  const [loading, setLoading] = useState(false);
  const [showRespondModal, setShowRespondModal] = useState(false);
  const { state } = useLocation();
  const user = JSON.parse(getUser());

  console.log("user", user);
  console.log("state", state?.user);

  const showModal = () => {
    setShowRespondModal(true);
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/getenquiry">Enquirys</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          value={
                            state?.user?.first_name +
                            " " +
                            state?.user?.last_name
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="middle_initials" className="form-label">
                          Middle Initials
                        </label>
                        <input
                          name="middle_initials"
                          type="text"
                          className="form-control"
                          id="middle_initials"
                          value={state?.user?.middle_initials}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={state?.user?.email}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile" className="form-label">
                          Phone
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={state?.user?.phone_number}
                        />
                      </div>
                    </div>

                    <div className="row mb-3"></div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="message" className="form-label">
                          Message
                        </label>
                        <textarea
                          name="message"
                          type="text"
                          className="form-control"
                          id="message"
                          value={state?.user?.message}
                        />
                      </div>
                    </div>
                    <div className="text-center mb-4">
                      <span className="fs-4">Comments</span> <br />
                      <hr className="my-1" />
                      <span>no comment yet</span>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>
                          Mark as read
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showModal()}
                          type="submit"
                          className="btn btn-secondary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-chat-dots-fill me-2"></i>
                          Respond to this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          type="submit"
                          className="btn btn-danger"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-trash-fill me-2"></i>
                          Delete this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          type="submit"
                          className="btn btn-info"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-plus-circle-fill me-2"></i>
                          Add a comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showRespondModal}
        centered
        onHide={() => setShowRespondModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Respond to valuation : TES 777</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              style={{ height: 200, overflow: "scroll" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowRespondModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary">Respond via email</Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default ViewEnquiry;
