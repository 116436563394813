import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../utils/api';
import {Button, Modal} from 'react-bootstrap';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import moment from 'moment';

const GetRegDetail = () => {
  const state = useParams();
  console.log('state', state);

  const [loading, setLoading] = useState(false);
  const [regComments, setRegComments] = useState([]);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');
  const [comments, setComments] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  console.log('deleteId', deleteId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState([]);

  console.log('data', data);

  const navigate = useNavigate();

  console.log('state -------->', state?.id);

  useEffect(() => {
    getRegData();
    getDataFromReg();
  }, []);

  const getDataFromReg = () => {
    setLoading(true);
    fetchDataFromAPI(`user/regs/admin?id=${state?.id}`, 'get', '', '')
      .then((res) => {
        console.log('object', res?.data?.listingType);
        setLoading(false);
        let data = res?.data;
        formik.setFieldValue('reg', data?.reg);
        formik.setFieldValue('loa', data?.loa);
        formik.setFieldValue('sales', data?.sales);
        formik.setFieldValue('listingType', data?.listingType);
        formik.setFieldValue('vat', data?.vat);
        formik.setFieldValue('owned', data?.owned);
        formik.setFieldValue('featured', data?.featured);
        formik.setFieldValue('active', data?.active);
        formik.setFieldValue('tag1', data?.tag1);
        formik.setFieldValue('tag2', data?.tag2);
        formik.setFieldValue('tag3', data?.tag3);
        formik.setFieldValue('tag4', data?.tag4);
        formik.setFieldValue('tag5', data?.tag5);
        formik.setFieldValue('tag6', data?.tag6);
        formik.setFieldValue('tag7', data?.tag7);
        formik.setFieldValue('tag8', data?.tag8);
        formik.setFieldValue('tag9', data?.tag9);
        formik.setFieldValue('tag10', data?.tag10);

        setData(res?.data);
      })
      .catch((error) => {
        setLoading(false);

        console.log('object', error);
      });
  };

  const getRegData = () => {
    setLoading(true);
    formik.setFieldValue('reg', data?.reg);
    formik.setFieldValue('loa', data?.loa);
    formik.setFieldValue('sales', data?.sales);
    formik.setFieldValue('listingType', data?.listingType);
    formik.setFieldValue('vat', data?.vat);
    formik.setFieldValue('owned', data?.owned);
    formik.setFieldValue('featured', data?.featured);
    formik.setFieldValue('active', data?.active);
    formik.setFieldValue('tag1', data?.tag1);
    formik.setFieldValue('tag2', data?.tag2);
    formik.setFieldValue('tag3', data?.tag3);
    formik.setFieldValue('tag4', data?.tag4);
    formik.setFieldValue('tag5', data?.tag5);
    formik.setFieldValue('tag6', data?.tag6);
    formik.setFieldValue('tag7', data?.tag7);
    formik.setFieldValue('tag8', data?.tag8);
    formik.setFieldValue('tag9', data?.tag9);
    formik.setFieldValue('tag10', data?.tag10);
    setLoading(false);
  };

  console.log('data?.listingType', data?.listingType);

  const validationSchema = Yup.object().shape({
    reg: Yup.string().required('Please enter reg'),
    loa: Yup.string()
      .matches(/^[0-9]+$/, 'LOA must contain only numbers.')
      .required('Please enter LOA'),
    sales: Yup.string()
      .matches(/^[0-9]+$/, 'Sales must contain only numbers.')
      .required('Please enter sales'),

    listingType: Yup.string().required('Please enter listingType'),
    vat: Yup.string().notRequired(),
    owned: Yup.string().notRequired(),
    featured: Yup.string().notRequired(),
    active: Yup.string().notRequired(),
    tag1: Yup.string().notRequired(),
    tag2: Yup.string().notRequired(),
    tag3: Yup.string().notRequired(),
    tag4: Yup.string().notRequired(),
    tag5: Yup.string().notRequired(),
    tag6: Yup.string().notRequired(),
    tag7: Yup.string().notRequired(),
    tag8: Yup.string().notRequired(),
    tag9: Yup.string().notRequired(),
    tag10: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      reg: '',
      loa: '',
      sales: '',
      listingType: '',
      vat: '',
      owned: '',
      featured: '',
      active: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      setLoading(true);

      const body = {
        reg: values?.reg,
        loa: values?.loa,
        sales: values?.sales,
        listingType: values?.listingType,
        vat: values?.vat,
        owned: values?.owned,
        featured: values?.featured,
        active: values?.active,
        tag1: values?.tag1,
        tag2: values?.tag2,
        tag3: values?.tag3,
        tag4: values?.tag4,
        tag5: values?.tag5,
        tag6: values?.tag6,
        tag7: values?.tag7,
        tag8: values?.tag8,
        tag9: values?.tag9,
        tag10: values?.tag10,
        // comments : ,
      };

      console.log('body', body);

      fetchDataFromAPI(`user/regs/update-reg?id=${data?._id}`, 'put', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('update member res:', res?.data);
          navigate('/get-reg');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating: ', error);
        });
    },
  });

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${data?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/regs/delete-reg?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
        navigate('/get-reg');
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    if (commentContent.trim() === '') {
      setCommentError('Please enter comment');
      return;
    }

    const body = {
      Customer_id: data?._id,
      comment: commentContent,
    };

    console.log('body --> ', body);
    setLoading(true);
    fetchDataFromAPI(`user/valutions/add-comment`, 'post', body, '')
      .then((res) => {
        console.log('res ----->', res);
        getComments();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        getDataFromReg();
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const deleteOffer = (id) => {
    setLoading(true);
    fetchDataFromAPI(`user/userOffer?id=${id}`, 'delete', '', '')
      .then((res) => {
        getDataFromReg();
        toast.success('Offer deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  let nonSpaceEntered = false;
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>View Reg</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-12 ">
                          <div className="row">
                            <div>
                              <form onSubmit={formik.handleSubmit}>
                                <div className="row mb-3">
                                  <div className="row mb-3">
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="reg"
                                        className="form-label">
                                        Reg
                                      </label>
                                      <input
                                        name="reg"
                                        type="text"
                                        className="form-control"
                                        id="reg"
                                        value={formik.values.reg}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'reg',
                                            e.target.value
                                              ?.trimStart()
                                              .toUpperCase(),
                                          );
                                        }}
                                      />
                                      {formik.touched.reg &&
                                        formik.errors.reg && (
                                          <div className="text-danger">
                                            {formik.errors.reg}
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="loa"
                                        className="form-label">
                                        Loa
                                      </label>
                                      <input
                                        name="loa"
                                        type="text"
                                        className="form-control"
                                        id="loa"
                                        value={formik.values.loa}
                                        // onChange={(e) => {
                                        //   formik.setFieldValue(
                                        //     "loa",
                                        //     e.target.value?.trimStart()
                                        //   );
                                        // }}

                                        onChange={(e) => {
                                          let input = e.target.value;
                                          const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                          if (!nonSpaceEntered) {
                                            // If no non-space character entered yet, trim spaces
                                            input = input.trimStart();
                                          }
                                          if (
                                            regex.test(input) ||
                                            input === ''
                                          ) {
                                            formik.setFieldValue('loa', input);
                                            // Check if a non-space character has been entered
                                            if (
                                              input !== '' &&
                                              !nonSpaceEntered
                                            ) {
                                              nonSpaceEntered = true;
                                            }
                                          }
                                        }}
                                      />
                                      {formik.touched.price &&
                                        formik.errors.loa && (
                                          <div className="text-danger">
                                            {formik.errors.loa}
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="sales"
                                        className="form-label">
                                        Sales
                                      </label>
                                      <input
                                        name="sales"
                                        type="text"
                                        className="form-control"
                                        id="sales"
                                        value={formik.values.sales}
                                        // onChange={(e) => {
                                        //   formik.setFieldValue(
                                        //     "sales",
                                        //     e.target.value?.trimStart()
                                        //   );
                                        // }}

                                        onChange={(e) => {
                                          let input = e.target.value;
                                          const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                          if (!nonSpaceEntered) {
                                            // If no non-space character entered yet, trim spaces
                                            input = input.trimStart();
                                          }
                                          if (
                                            regex.test(input) ||
                                            input === ''
                                          ) {
                                            formik.setFieldValue(
                                              'sales',
                                              input,
                                            );
                                            // Check if a non-space character has been entered
                                            if (
                                              input !== '' &&
                                              !nonSpaceEntered
                                            ) {
                                              nonSpaceEntered = true;
                                            }
                                          }
                                        }}
                                      />
                                      {formik.touched.price &&
                                        formik.errors.sales && (
                                          <div className="text-danger">
                                            {formik.errors.sales}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="Role"
                                        className="form-label">
                                        Listing type
                                      </label>
                                      <div className="input-group">
                                        <select
                                          name="listingType"
                                          className={`form-select ${
                                            formik.touched.listingType &&
                                            formik.errors.listingType
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          id="listingType"
                                          // value={formik.values.listingType}
                                          // onChange={(e) =>
                                          //   formik.setFieldValue(e.target.value)
                                          // }
                                          {...formik.getFieldProps(
                                            'listingType',
                                          )}
                                          aria-label="Select listingType">
                                          <option value="stock">Stock</option>
                                          <option value="comms">Comms</option>
                                          <option value="dealer">Dealer</option>
                                          {/* <!-- Add more options as needed --> */}
                                        </select>
                                      </div>
                                      {formik.touched.listingType &&
                                        formik.errors.listingType && (
                                          <div className="text-danger">
                                            {formik.errors.listingType}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag1"
                                        className="form-label">
                                        Tag1
                                      </label>
                                      <input
                                        name="tag1"
                                        type="text"
                                        className="form-control"
                                        id="tag1"
                                        value={formik.values.tag1}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag1',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag2"
                                        className="form-label">
                                        Tag2
                                      </label>
                                      <input
                                        name="tag2"
                                        type="text"
                                        className="form-control"
                                        id="tag2"
                                        value={formik.values.tag2}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag2',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag3"
                                        className="form-label">
                                        Tag3
                                      </label>
                                      <input
                                        name="tag3"
                                        type="text"
                                        className="form-control"
                                        id="tag3"
                                        value={formik.values.tag3}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag3',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag4"
                                        className="form-label">
                                        Tag4
                                      </label>
                                      <input
                                        name="tag4"
                                        type="text"
                                        className="form-control"
                                        id="tag4"
                                        value={formik.values.tag4}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag4',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag5"
                                        className="form-label">
                                        Tag5
                                      </label>
                                      <input
                                        name="tag5"
                                        type="text"
                                        className="form-control"
                                        id="tag1"
                                        value={formik.values.tag5}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag5',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label
                                        htmlFor="tag6"
                                        className="form-label">
                                        Tag6
                                      </label>
                                      <input
                                        name="tag1"
                                        type="text"
                                        className="form-control"
                                        id="tag6"
                                        value={formik.values.tag6}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag6',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="tag7"
                                        className="form-label">
                                        Tag7
                                      </label>
                                      <input
                                        name="tag7"
                                        type="text"
                                        className="form-control"
                                        id="tag7"
                                        value={formik.values.tag7}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag7',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="tag8"
                                        className="form-label">
                                        Tag8
                                      </label>
                                      <input
                                        name="tag8"
                                        type="text"
                                        className="form-control"
                                        id="tag8"
                                        value={formik.values.tag8}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag8',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="tag9"
                                        className="form-label">
                                        Tag9
                                      </label>
                                      <input
                                        name="tag9"
                                        type="text"
                                        className="form-control"
                                        id="tag9"
                                        value={formik.values.tag9}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag9',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="tag10"
                                        className="form-label">
                                        Tag10
                                      </label>
                                      <input
                                        name="tag10"
                                        type="text"
                                        className="form-control"
                                        id="tag10"
                                        value={formik.values.tag10}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'tag10',
                                            e.target.value?.trimStart(),
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12 d-flex justify-content-evenly my-2">
                                    <div className="form-check">
                                      <input
                                        name="featured"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="featured"
                                        checked={formik.values.featured}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'featured',
                                            e.target.checked,
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="featured">
                                        Featured
                                      </label>
                                      {formik.touched.featured &&
                                        formik.errors.featured && (
                                          <div className="text-danger">
                                            {formik.errors.featured}
                                          </div>
                                        )}
                                    </div>
                                    <div className="form-check">
                                      <input
                                        name="contact_permission"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="contact_permission"
                                        checked={formik.values.vat}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'vat',
                                            e.target.checked,
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="contact_permission">
                                        Vat
                                      </label>
                                    </div>

                                    <div className="form-check">
                                      <input
                                        name="contact_permission"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="contact_permission"
                                        checked={formik.values.active}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            'active',
                                            e.target.checked,
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="contact_permission">
                                        Active
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    // disabled={formik.isSubmitting || !formik.isValid}
                                  >
                                    {!loading && (
                                      <span className="indicator-label">
                                        Save Updates
                                      </span>
                                    )}
                                    {loading && (
                                      <span
                                        className="indicator-progress"
                                        style={{display: 'block'}}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </form>
                              <hr />

                              <h4 className="text-center">Offer</h4>
                              <hr />
                              <div className="container">
                                <div className="row justify-content-center mt-4">
                                  <div className="table-responsive card-body pb-0">
                                    <table className="table text-nowrap  align-items-end table-hover">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="">
                                            Date & Time
                                          </th>
                                          <th scope="col" className="">
                                            Reg
                                          </th>

                                          <th scope="col" className="">
                                            First Name
                                          </th>
                                          <th scope="col" className="">
                                            Last Name
                                          </th>
                                          <th scope="col" className="">
                                            phone
                                          </th>
                                          <th scope="col" className="">
                                            Email
                                          </th>

                                          <th scope="col" className="">
                                            Offer Price
                                          </th>
                                          <th scope="col" className="">
                                            Subscribed
                                          </th>

                                          <th scope="col">Actions</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {data?.userOfferData?.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="text-center"
                                                role="button"
                                                onClick={() => {
                                                  navigate(
                                                    `/offers/view/${item?._id}`,
                                                    {
                                                      state: {user: item},
                                                    },
                                                  );
                                                }}>
                                                {/* <td className="text-center">
                                {item?.rearPlate}
                              </td> */}
                                                <td>
                                                  {item?.createdAt
                                                    ? moment(
                                                        item?.createdAt,
                                                      ).format(
                                                        'DD/MM/YYYY h:mm a',
                                                      )
                                                    : '-'}
                                                </td>
                                                <td>{item?.reg}</td>
                                                <td className="text-center">
                                                  {item?.first_name
                                                    ? item?.first_name
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.last_name
                                                    ? item?.last_name
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.phone_number
                                                    ? item?.phone_number
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.email
                                                    ? item?.email
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.offer_price
                                                    ? item?.offer_price
                                                    : '-'}
                                                </td>

                                                <td className="text-center">
                                                  <input
                                                    type="checkbox"
                                                    checked={item?.is_subscribe}
                                                    disabled
                                                  />
                                                </td>

                                                <td className="text-center">
                                                  <div
                                                    role="button"
                                                    className="delete-icon btn btn-sm btn-danger"
                                                    onClick={() =>
                                                      deleteOffer(item?._id)
                                                    }>
                                                    <i class="bi bi-trash"></i>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          },
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <hr />

                              <h4 className="text-center">Enquiry</h4>
                              <hr />
                              <div className="container">
                                <div className="row justify-content-center mt-4">
                                  <div className="table-responsive card-body pb-0">
                                    <table className="table text-nowrap  align-items-end table-hover">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="">
                                            Date & Time
                                          </th>
                                          <th scope="col" className="">
                                            Reg
                                          </th>

                                          <th scope="col" className="">
                                            First Name
                                          </th>
                                          <th scope="col" className="">
                                            Last Name
                                          </th>
                                          <th scope="col" className="">
                                            phone
                                          </th>
                                          <th scope="col" className="">
                                            Middle Initials
                                          </th>
                                          <th scope="col" className="">
                                            Email
                                          </th>
                                          <th scope="col" className="">
                                            Subscribed
                                          </th>
                                          <th scope="col" className="">
                                            Message
                                          </th>

                                          {/* <th scope="col">View</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.inquiry?.map((item, index) => {
                                          return (
                                            <tr
                                              role="button"
                                              className="text-center"
                                              onClick={() => {
                                                navigate(
                                                  `/enquiry/edit/${item?._id}`,
                                                  {
                                                    state: {user: item},
                                                  },
                                                );
                                              }}>
                                              {/* <td className="text-center">
                                {item?.rearPlate}
                              </td> */}
                                              <td>
                                                {item?.createdAt
                                                  ? moment(
                                                      item?.createdAt,
                                                    ).format(
                                                      'DD/MM/YYYY h:mm a',
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>{item?.reg}</td>
                                              <td className="text-center">
                                                {item?.first_name
                                                  ? item?.first_name
                                                  : '-'}
                                              </td>
                                              <td className="text-center">
                                                {item?.last_name
                                                  ? item?.last_name
                                                  : '-'}
                                              </td>
                                              <td className="text-center">
                                                {item?.phone_number
                                                  ? item?.phone_number
                                                  : '-'}
                                              </td>
                                              <td className="text-center">
                                                {item?.middle_initials
                                                  ? item?.middle_initials
                                                  : '-'}
                                              </td>
                                              <td className="text-center">
                                                {item?.email
                                                  ? item?.email
                                                  : '-'}
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="checkbox"
                                                  checked={item?.is_subscribe}
                                                  disabled
                                                />
                                              </td>

                                              <td>
                                                {item?.message?.length > 20
                                                  ? item?.message?.slice(
                                                      0,
                                                      20,
                                                    ) + '...'
                                                  : item?.message}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <hr />

                              <h4 className="text-center">
                                Sales and LOA price history
                              </h4>
                              <hr />

                              <div className="container">
                                <div className="row justify-content-center mt-4">
                                  <div className="table-responsive card-body pb-0">
                                    <table className="table text-nowrap  align-items-end">
                                      <thead>
                                        <tr className="">
                                          <th
                                            scope="col"
                                            className="text-center">
                                            Date & Time
                                          </th>

                                          <th
                                            scope="col"
                                            className="text-center">
                                            Loa Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center">
                                            Old Loa Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center">
                                            Sale Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center">
                                            Old Sale Price
                                          </th>

                                          {/* <th scope="col">View</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.PriceHistoryData?.map(
                                          (item, index) => {
                                            return (
                                              <tr className="text-center">
                                                <td>
                                                  {item?.createdAt
                                                    ? moment(
                                                        item?.createdAt,
                                                      ).format(
                                                        'DD/MM/YYYY h:mm a',
                                                      )
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.loa_price
                                                    ? item?.loa_price
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.old_loa_price
                                                    ? item?.old_loa_price
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.sale_price
                                                    ? item?.sale_price
                                                    : '-'}
                                                </td>
                                                <td className="text-center">
                                                  {item?.old_sale_price
                                                    ? item?.old_sale_price
                                                    : '-'}
                                                </td>
                                              </tr>
                                            );
                                          },
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <hr />
                              <h4 className="text-center">
                                Seller's Information
                              </h4>
                              <hr />

                              <div className="col-xl-12">
                                <div className="card ">
                                  <div className="card-body pt-6 ">
                                    <div className="tab-content pt-2 ">
                                      <div
                                        className="tab-pane fade show active profile-overview"
                                        id="profile-overview">
                                        <div className="mt-3">
                                          {data?.manage_customer ? (
                                            <div className="row">
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  First Name :
                                                </div>
                                                <div className="col-md-6">
                                                  {
                                                    data?.manage_customer
                                                      ?.firstName
                                                  }
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Last Name :
                                                </div>
                                                <div className="col-md-6">
                                                  {
                                                    data?.manage_customer
                                                      ?.lastName
                                                  }
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Email :
                                                </div>
                                                <div className="col-md-6">
                                                  {data?.manage_customer?.email}
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Phone :
                                                </div>
                                                <div className="col-md-6">
                                                  {
                                                    data?.manage_customer
                                                      ?.mobile
                                                  }
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  County :
                                                </div>
                                                <div className="col-md-6">
                                                  {
                                                    data?.manage_customer
                                                      ?.country
                                                  }
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Town :
                                                </div>
                                                <div className="col-md-6">
                                                  {data?.manage_customer?.town}
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Postcode :
                                                </div>
                                                <div className="col-md-6">
                                                  {
                                                    data.manage_customer
                                                      ?.postcode
                                                  }
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  landline :
                                                </div>
                                                <div className="col-md-6">
                                                  {data.manage_customer
                                                    ?.landline
                                                    ? data.manage_customer
                                                        ?.landline
                                                    : '-'}
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Address Line 1 :
                                                </div>
                                                <div className="col-md-6">
                                                  {data.manage_customer
                                                    ?.addressLine1
                                                    ? data.manage_customer
                                                        ?.addressLine1
                                                    : '-'}
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Address Line 2 :
                                                </div>
                                                <div className="col-md-6">
                                                  {data.manage_customer
                                                    ?.addressLine2
                                                    ? data.manage_customer
                                                        ?.addressLine2
                                                    : '-'}
                                                </div>
                                              </div>
                                              <div className="col-md-6 row">
                                                <div className="col-md-6 label">
                                                  Active :
                                                </div>
                                                <div className="col-md-6">
                                                  {data?.manage_customer?.active
                                                    ? 'Yes'
                                                    : 'No'}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="text-center">
                                              No customer data available
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div
                                className="container mb-5"
                                style={{maxHeight: '400px'}}>
                                <div className="row justify-content-center mt-4">
                                  <div className="col-lg-8">
                                    <div className="card">
                                      <div className="card-body">
                                        <h4 className="card-title mb-3">
                                          Comments
                                        </h4>
                                        <hr className="my-1" />
                                        <div
                                          style={{
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                          }}>
                                          {comments?.length > 0 ? (
                                            comments.map((comment, index) => (
                                              <div
                                                key={index}
                                                className="card mb-3">
                                                <div className="card-body">
                                                  <p className="card-text">
                                                    {comment?.msg}
                                                  </p>
                                                  <p className="text-end text-muted small">
                                                    {new Date(
                                                      comment?.timestamp,
                                                    ).toLocaleString()}
                                                  </p>
                                                </div>
                                              </div>
                                            ))
                                          ) : (
                                            <p className="text-muted">
                                              No comments yet
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center">
                                {/* <div className="text-center mx-3">
                                  <button
                                    // onClick={() => showModal()}
                                    type="submit"
                                    className="btn btn-secondary"
                                    // disabled={formik.isSubmitting || !formik.isValid}
                                  >
                                    <i class="bi bi-chat-dots-fill me-2"></i>
                                    Respond to this enquiry
                                  </button>
                                </div> */}
                                <div className="text-center mx-3">
                                  <button
                                    onClick={() => setDeleteId(data?._id)}
                                    type="submit"
                                    className="btn btn-danger"
                                    // disabled={formik.isSubmitting || !formik.isValid}
                                  >
                                    <i class="bi bi-trash-fill me-2"></i>
                                    Delete
                                  </button>
                                </div>
                                <div className="text-center mx-3">
                                  <button
                                    onClick={() => showComment()}
                                    type="submit"
                                    className="btn btn-info"
                                    // disabled={formik.isSubmitting || !formik.isValid}
                                  >
                                    <i class="bi bi-plus-circle-fill me-2"></i>
                                    Add comment
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Reg"
          body="Are you sure you want to delete this record?"
        />
        {/*for commet add*/}
        <Modal
          show={showCommentModal}
          centered
          onHide={() => setShowCommentModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <textarea
                name="content"
                id="content"
                className={`form-control ${commentError ? 'is-invalid' : ''}`}
                // className={`form-control ${
                //   formik.touched.content && formik.errors.content
                //     ? 'is-invalid'
                //     : ''
                // }`}
                // value={formik.values.content}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={commentContent}
                onChange={(e) => {
                  setCommentContent(e.target.value);
                  setCommentError('');
                }}
                style={{height: 200}}
              />
              {commentError && (
                <div className="invalid-feedback">{commentError}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="text-end gap-1">
            <Button
              variant="secondary"
              onClick={() => setShowCommentModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCommentSubmit}>
              Add Comment
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </main>
  );
};

export default GetRegDetail;
