import React, {useEffect, useState} from 'react';
import {fetchDataFromAPI} from '../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import Processing from '../../components/Processing/Processing';

const NumberPlateFaq = () => {
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = () => {
    setLoading(true);
    fetchDataFromAPI(`user/faq?type=${1}`, 'get', '', '')
      .then((res) => {
        console.log('res -->', res?.data);
        setFaq(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const deleteContent = (id) => {
    fetchDataFromAPI(`user/faq?id=${id}`, 'delete', '', '')
      .then((res) => {
        toast.success('deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        console.log(res);
        getFaq();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        console.log(error);
      });
  };
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Dashboard</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Number Plate FAQ</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <div className="col d-flex justify-content-end mt-2  me-2">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      onClick={() => navigate('/number-plate/faq/add')}>
                      Add FAQ
                    </button>
                  </div>
                  <div className="card-body pb-0">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Title</th>

                          <th scope="col">Description</th>

                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {faq.map((item, index) => {
                        let truncatedAnswer = item?.answer;

                        if (truncatedAnswer && truncatedAnswer.length > 50) {
                          truncatedAnswer =
                            truncatedAnswer.slice(0, 50) + '...';
                        }
                        return (
                          <tbody>
                            <tr>
                              <td scope="row">
                                <span>{index + 1}</span>
                                {/* <a href="#">
                                <img
                                  src="assets/img/product-1.jpg"
                                  alt=""
                                />
                              </a> */}
                              </td>

                              <td>{item?.question}</td>
                              <td>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncatedAnswer,
                                  }}></span>
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center ">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() =>
                                      navigate(
                                        `/number-plate/faq/edit/${item?._id}`,
                                        {
                                          state: {user: item},
                                        },
                                      )
                                    }>
                                    <i class="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={() => deleteContent(item?._id)}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
              {/* End Top Selling */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NumberPlateFaq;
