import React, {useState} from 'react';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {Link, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
const NumberPlateFaqAdd = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleEditorChange = (html) => {
    formik.setFieldValue('answer', html);
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required('Please enter answer'),
    question: Yup.string().required('Please enter question'),
  });

  const formik = useFormik({
    initialValues: {
      answer: '',
      question: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        answer: values?.answer,
        question: values?.question,
        type: 1,
      };

      fetchDataFromAPI('user/faq', 'post', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('added successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);
          formik.resetForm();
          navigate('/number-plate/faq');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating member: ', error);
        });
    },
  });

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/number-plate/faq">Number Plate FAQ</Link>
            </li>
            <li className="breadcrumb-item active">Add Faq</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-">
                        <label htmlFor="question" className="form-label">
                          Question
                        </label>

                        <input
                          name="answer"
                          type="text"
                          className={`form-control ${
                            formik.touched.question && formik.errors.question
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="answer"
                          value={formik.values.question}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'question',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.question && formik.errors.question && (
                          <div className="text-danger">
                            {formik.errors.question}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 mt-2">
                        <label htmlFor="lastName" className="form-label">
                          Answer
                        </label>
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.answer}
                            onChange={handleEditorChange}
                            style={{height: 200}}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                        {formik.touched.answer && formik.errors.answer && (
                          <div className="text-danger">
                            {formik.errors.answer}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center ">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Add
                        {/* {!loading && (
                        <span className="indicator-label">
                          Save Updates
                        </span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{display: 'block'}}>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )} */}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NumberPlateFaqAdd;
