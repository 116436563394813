import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import Processing from "../../components/Processing/Processing";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailMessage, setFailMessage] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email."),
    }),

    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Your login logic here using the form values
      // For example, you can authenticate the user and navigate upon successful login
      const { email } = values;

      // const body = {
      //   email: email,
      // };
      setLoading(true);

      try {
        fetchDataFromAPI(
          `user/admin/forgotPassword?email=${email}`,
          "post",
          "",
          ""
        )
          .then((response) => {
            if (response.status === 200) {
              setShowSuccessMessage(true);
              formik.resetForm();
            }

            setLoading(false);
            console.log("object", response);
          })
          .catch((error) => {
            console.log("error", error?.response?.data);
            setLoading(false);
            if (error?.response?.status === 400) {
              setFailMessage(true);
              toast.error(error?.response?.message, {
                position: toast.POSITION.TOP_RIGHT,
                className: "bg-danger text-white",
              });
              formik.resetForm();
            }
          });
      } catch (error) {
        console.log("catch error: ", error);

        setLoading(false);
      }
      // Perform authentication logic here...

      // For demonstration purposes, let's assume authentication is successful
      // Redirect the user to the home page
      // localStorage.setItem(constants.USER, email); // Storing user in localStorage
      // navigate('/login');
    },
  });

  return (
    <main>
      {loading && <Processing />}
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-lg-6">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className=" pb-2">
                      <span class="card-title fs-5 text-center d-none d-lg-block fw-bold">
                        REG247
                      </span>
                      <h5 class="card-title text-center pb-0">
                        Reset Password to Your Account
                      </h5>
                    </div>

                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      {/*username field*/}
                      <div className="col-12">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            placeholder="Enter an email"
                            type="text"
                            name="email"
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "email",
                                e.target.value?.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      {/*submit button*/}
                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Reset Password
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Show the success message */}
          {showSuccessMessage && (
            <div className="alert alert-success mt-3" role="alert">
              Thank you! If your email is registered with Reg247, you will
              receive an email with instructions on how to reset your password.
            </div>
          )}
          {/* 
          {showFailMessage && (
            <div className="alert alert-danger mt-3" role="alert">
              User Not Found Please Register First
            </div>
          )} */}
        </section>
      </div>
    </main>
  );
};

export default ForgotPassword;
