import React, {useEffect, useState} from 'react';
import {fetchDataFromAPI} from '../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {toast} from 'react-toastify';

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getBlog();
  }, []);

  const getBlog = () => {
    fetchDataFromAPI('user/blog', 'get', '', '')
      .then((res) => {
        console.log('res', res);
        setBlog(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const deleteAdmin = (id) => {
    console.log('id', id);
    fetchDataFromAPI(`user/blog?id=${id}`, 'delete', '', '')
      .then((res) => {
        console.log('res delete : ', res);
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getBlog();
      })
      .catch((error) => {
        console.log('error', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Blogs</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          <div className="d-flex  ">
            <h3 className=" font-weight-bold ">Blogs List</h3>
            <div className="col d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn btn-primary "
                onClick={() => navigate('/blog/add')}>
                Add Blog
              </button>
            </div>
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end">
              <thead>
                <tr className="">
                  <th scope="col">No</th>
                  <th scope="col">Image</th>
                  <th scope="col" className="text-center">
                    Title
                  </th>
                  <th scope="col" className="text-center">
                    Action
                  </th>

                  {/* <th scope="col">View</th> */}
                </tr>
              </thead>
              <tbody>
                {blog.map((item, index) => {
                  return (
                    <tr className="align-middle">
                      <td>{index + 1}</td>

                      <td>
                        <img
                          className="border border-2 border-primary"
                          src={item?.image}
                          alt="Profile Image"
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 25,
                          }}
                        />
                      </td>
                      <td>{item?.title}</td>

                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon btn btn-sm btn-primary me-2"
                            role="button"
                            onClick={() =>
                              navigate(`/blog/edit/${item?._id}`, {
                                state: {user: item},
                              })
                            }>
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger me-2"
                            onClick={() => deleteAdmin(item?._id)}>
                            <i class="bi bi-trash"></i>
                          </div>
                          <div
                            className="edit-icon btn btn-sm btn-warning "
                            role="button"
                            onClick={() =>
                              navigate(`/blog/view/${item?._id}`, {
                                state: {user: item},
                              })
                            }>
                            <i class="bi bi-eye"></i>
                          </div>
                        </div>  
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
