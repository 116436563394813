import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";

const AdminAdd = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      lastname: "",
      email: "",
      newpass: "",
      connewpass: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please enter Username."),

      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email."),
      newpass: Yup.string()
        // .matches(
        //   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&+=])(?=\S+$).{8,}$/,
        //   'Password must be a minimum of 8 characters and should contain at least one uppercase and lowercase letter, a number, and a special character.',
        // )
        .required("Please enter Password."),
      connewpass: Yup.string()
        .oneOf(
          [Yup.ref("newpass"), null],
          "Password and repeat password do not match."
        )
        .required("Please enter repeat password."),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   const {firstname, lastname, email, connewpaass, role} = values;

      console.log("values", values);

      try {
        setLoading(true);
        const body = {
          username: values?.username,
          email: values?.email,
          password: values?.connewpass,
        };
        console.log("body", body);
        // const user = JSON.parse(getUser());

        fetchDataFromAPI("user/admin", "post", body, "")
          .then((responce) => {
            toast.success("User added successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            setLoading(false);

            navigate("/admins");
            console.log("responce", responce);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.data, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            setLoading(false);
            console.log("error", error);
          });
      } catch (error) {
        console.log("catch error: ", error);
        setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
          <Link to="/driver">Content Edit</Link>
        </li> */}
            <li className="breadcrumb-item active">
              <span>Admin Add</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>

                        <input
                          name="username"
                          type="text"
                          className="form-control"
                          id="username"
                          value={formik.values.username}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "username",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.username && formik.errors.username && (
                          <div className="text-danger">
                            {formik.errors.username}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6  ">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>

                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="row mb-3">
                        <div className="col-md-6 ">
                          <label htmlFor="newpass" className="form-label">
                            New Password
                          </label>
                          <div className="d-flex">
                            <input
                              name="newpass"
                              type={showNewPass ? "text" : "password"}
                              style={{
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                              }}
                              className="form-control"
                              id="newpass"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "newpass",
                                  e.target.value?.trimStart()
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: "0", // Adjust the radius value
                                borderBottomLeftRadius: "0", // Adjust the radius value
                              }}
                              onClick={() => setShowNewPass(!showNewPass)}
                            >
                              {showNewPass ? (
                                <i className="bi bi-eye-slash-fill"></i>
                              ) : (
                                <i className="bi bi-eye-fill"></i>
                              )}
                            </button>
                          </div>
                          {formik.touched.newpass && formik.errors.newpass && (
                            <div className="text-danger mb-2">
                              {formik.errors.newpass}
                            </div> // Add margin-bottom for spacing
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="connewpass" className="form-label">
                            Repeat New Password
                          </label>
                          <div className="d-flex">
                            <input
                              name="connewpass"
                              type={showConNewPass ? "text" : "password"}
                              className="form-control "
                              id="connewpass"
                              style={{
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                              }}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "connewpass",
                                  e.target.value?.trimStart()
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: "0", // Adjust the radius value
                                borderBottomLeftRadius: "0", // Adjust the radius value
                              }}
                              onClick={() => setShowConNewPass(!showConNewPass)}
                            >
                              {showConNewPass ? (
                                <i className="bi bi-eye-slash-fill"></i>
                              ) : (
                                <i className="bi bi-eye-fill"></i>
                              )}
                            </button>
                          </div>
                          {formik.touched.connewpass &&
                            formik.errors.connewpass && (
                              <div className="text-danger">
                                {formik.errors.connewpass}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Add Admin
                        {/* {!loading && (
                    <span className="indicator-label">
                      Save Updates
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{display: 'block'}}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )} */}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminAdd;
