import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddModal = ({ show, onHide, onAdd, fields, title }) => {

  const getSchemaShapeObject = () => {
    let finalSchema = {};
    fields.map((field) => {
      if (field.required) {
        finalSchema[field.name] = Yup.string().required(
          `${field.label} is required`
        );
      }
    });
    return finalSchema;
  };

  const formik = useFormik({
    initialValues: fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: "" }),
      {}
    ),
    validationSchema: Yup.object().shape(getSchemaShapeObject()),
    onSubmit: (values) => {
      console.log("initial values: ", formik.initialValues);
      onAdd(values);
      formik.setSubmitting(false);
    },
  });

  return (
    <Modal show={show} onHide={onHide} centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields.map((field, index) => (
            <Form.Group
              className="mb-3"
              controlId={`formBasic${field.name}`}
              key={index}
            >
              <Form.Label>{field.label}</Form.Label>
              {field.type === "select" ? (
                <select
                  className="form-select"
                  {...formik.getFieldProps(field.name)}
                  // isInvalid={
                  //   formik.touched[field.name] &&
                  //   formik.errors[field.name].toString()
                  // }
                >
                  {field.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="form-control"
                  type={field.type}
                  placeholder={field.placeholder}
                  {...formik.getFieldProps(field.name)}
                  // isInvalid={
                  //   formik.touched[field.name] &&
                  //   formik.errors[field.name].toString()
                  // }
                />
              )}
              {formik.touched[field.name] && formik.errors[field.name] ? (
                <div className="text-danger small">
                  {formik.errors[field.name]}
                </div>
              ) : null}
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddModal;
