import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import Processing from "../../components/Processing/Processing";

const GetEnquiry = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [enquiry, setEnquiry] = useState([]);

  useEffect(() => {
    setLoading(true);

    getEnquiry();
  }, []);

  const getEnquiry = () => {
    fetchDataFromAPI("user/admin", "get", "", "")
      .then((res) => {
        setEnquiry(res?.data);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Admins</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          {/* <div className="d-flex  ">
            <h3 className=" font-weight-bold ">Admins List</h3>
            <div className="col d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn btn-primary "
                onClick={() => navigate('/admins/add')}>
                Add Admin
              </button>
            </div>
          </div> */}
          {/* <hr /> */}
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end">
              <thead>
                <tr className="">
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>

                  {/* <th scope="col">View</th> */}
                </tr>
              </thead>
              <tbody>
                {enquiry.map((item, index) => {
                  return (
                    <tr className="">
                      <td>{index + 1}</td>
                      <td>{item?.username}</td>
                      <td>{item?.email}</td>

                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon btn btn-sm btn-primary me-2"
                            role="button"
                            onClick={() => navigate("/getenquiry/view")}
                            // onClick={() =>
                            //   navigate(`/getenquiry/view/${item?._id}`, {
                            //     state: {user: item},
                            //   })
                            // }
                          >
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            // onClick={() => deleteAdmin(item?._id)}
                          >
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
      </div>
    </div>
  );
};

export default GetEnquiry;
