import React, {useContext, useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {DataContext} from '../../context/dataContext';
import {Button, Modal} from 'react-bootstrap';
import Processing from '../../components/Processing/Processing';
const AcrylicView = () => {
  const {state} = useLocation();

  const navigate = useNavigate();
  const dataContext = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getComments();
  }, []);

  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)
    console.log('updatedIsCommentRead', updatedIsCommentRead);
    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead, // Convert to string because it seems like your API expects a string value
    };

    setLoading(true);
    fetchDataFromAPI(
      `user/acrylicPlates/update-acryli-plates?acrylicPayment_id=${state?.user?._id}`,
      'put',
      body,
      '',
    )
      .then((res) => {
        console.log('res ------->', res);
        getAcrylicCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        navigate('/acrylicplate');
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAcrylicCount = () => {
    fetchDataFromAPI(
      `user/acrylicPlates/get-acryli-plates/count`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('Acryli --> ', res?.data);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setAcrylicCount(res?.data);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const handleCommentSubmit = () => {
    if (commentContent.trim() === '') {
      setCommentError('Please enter comment');
      return;
    }

    const body = {
      Customer_id: state?.user?._id,
      comment: commentContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        getComments();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>View Acrylic Plate</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-3 label"> First Name :</div>
                            <div className="col-md-9">
                              {state?.user?.firstName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label"> Last Name :</div>
                            <div className="col-md-9">
                              {state?.user?.lastName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">
                              Middle Initials :
                            </div>
                            <div className="col-md-9">
                              {state?.user?.middleInitials
                                ? state?.user?.middleInitials
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Address :</div>
                            <div className="col-md-9">
                              {state?.user?.streetAddress +
                              ' ' +
                              state?.user?.town +
                              ' ' +
                              state?.user?.county +
                              ' ' +
                              state?.user?.country +
                              ',' +
                              state?.user?.postcode
                                ? state?.user?.streetAddress +
                                  ' ' +
                                  state?.user?.town +
                                  ' ' +
                                  state?.user?.county +
                                  ' ' +
                                  state?.user?.country +
                                  ',' +
                                  state?.user?.postcode
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Email :</div>
                            <div className="col-md-9">
                              {state?.user?.email ? state?.user?.email : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Phone Number :</div>
                            <div className="col-md-9">
                              {state?.user?.phone ? state?.user?.phone : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Acrylic Reg. :</div>
                            <div className="col-md-9">
                              {state?.user?.acrylicReg
                                ? state?.user?.acrylicReg
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Plate Size :</div>
                            <div className="col-md-9">
                              {state?.user?.plateSize
                                ? state?.user?.plateSize
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Font Choose :</div>
                            <div className="col-md-9">
                              {state?.user?.chooseFont
                                ? state?.user?.chooseFont
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container mb-5"
                        style={{maxHeight: '400px'}}>
                        <div className="row justify-content-center mt-4">
                          <div className="col-lg-8">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title mb-3">Comments</h4>
                                <hr className="my-1" />
                                <div
                                  style={{
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                  }}>
                                  {comments?.length > 0 ? (
                                    comments.map((comment, index) => (
                                      <div key={index} className="card mb-3">
                                        <div className="card-body">
                                          <p className="card-text">
                                            {comment?.msg}
                                          </p>
                                          <p className="text-end text-muted small">
                                            {new Date(
                                              comment?.timestamp,
                                            ).toLocaleString()}
                                          </p>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <p className="text-muted">
                                      No comments yet
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="text-center mx-3">
                          <button
                            onClick={() => funcationRead()}
                            type="submit"
                            // disabled={
                            //   state?.user?.is_read === true ? true : false
                            // } // Disable if isCommentRead is false
                            className="btn btn-primary"
                            // disabled={formik.isSubmitting || !formik.isValid}
                          >
                            <i class="bi bi-eye-fill me-2"></i>
                            {state?.user?.is_read === true
                              ? 'Mark as Unread'
                              : 'Mark as Read'}
                          </button>
                        </div>
                        <div className="text-center mx-3">
                          <button
                            onClick={() => showComment()}
                            type="submit"
                            className="btn btn-info"
                            // disabled={formik.isSubmitting || !formik.isValid}
                          >
                            <i class="bi bi-plus-circle-fill me-2"></i>
                            Add a comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value);
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default AcrylicView;
