import AddContent from "../pages/AddContent/AddContent";
import AdminAdd from "../pages/AdminAdd/AdminAdd";
import AdminEdit from "../pages/AdminEdit/AdminEdit";
import Admins from "../pages/Admins/Admins";
import HomeContentBullets from "../pages/HomeContentBullets/HomeContentBullets";
import HomeContentBulletsAdd from "../pages/HomeContentBulletsAdd/HomeContentBulletsAdd";
import HomeContentBulletsEdit from "../pages/HomeContentBulletsEdit/HomeContentBulletsEdit";
import HomeContentEdit from "../pages/HomeContentEdit/HomeContentEdit";
import HomeContentFaq from "../pages/HomeContentFaq/HomeContentFaq";
import HomeContentFaqAdd from "../pages/HomeContentFaqAdd/HomeContentFaqAdd";
import HomeContentFaqEdit from "../pages/HomeContentFaqEdit/HomeContentFaqEdit";

import GetReg from "../pages/GetReg/GetReg";
import AddPlates from "../pages/ValuationPlates/AddSalesPlates";
import EditSalesPlates from "../pages/ValuationPlates/EditSalesPlates";
import GetPlates from "../pages/ValuationPlates/GetsalesPlates";
import Dashboard from "../pages/dashboard/dashboard";
import HomeContent from "../pages/homecontent/homecontent";

import PrivateRoute from "./RouteProtection";
import GetRegDetail from "../pages/GetRegDetail/GetRegDetail";
import UploadCsv from "../pages/UploadCsv/UploadCsv";
import ViewSalesPlates from "../pages/ValuationPlates/ViewSalesPlates";
import Customer from "../pages/Customer/Customer";
import CustomerAdd from "../pages/CustomerAdd/CustomerAdd";
import GetEnquiry from "../pages/GetEnquiry/GetEnquiry";
import ViewEnquiry from "../pages/ViewEnquiry/ViewEnquiry";
import HomeContentBanner from "../pages/HomeContentBanner/HomeContentBanner";
import NumberPlateFaq from "../pages/NumberPlateFaq/NumberPlateFaq";
import NumberPlateFaqAdd from "../pages/NumberPlateFaqAdd/NumberPlateFaqAdd";
import NumberPlateFaqEdit from "../pages/NumberPlateFaqEdit/NumberPlateFaqEdit";
import Coupons from "../pages/Coupons/Coupons";
import Blog from "../pages/Blog/Blog";
import BlogAdd from "../pages/BlogAdd/BlogAdd";
import BlogEdit from "../pages/BlogEdit/BlogEdit";
import BlogView from "../pages/BlogView/BlogView";
import Enquiry from "../pages/Enquiry/Enquiry";
import EnquiryAdd from "../pages/EnquiryAdd/EnquiryAdd";
import EnquiryEdit from "../pages/EnquiryEdit/EnqiryEdit";
import EnquiryView from "../pages/EnquiryView/EnquiryView";
import Offers from "../pages/Offers/Offers";
import OffersAdd from "../pages/OffersAdd/OffersAdd";
import Payment from "../pages/Payment/payment";
import PaymentView from "../pages/PaymentView/PaymentView";
import GetAcrylicPlate from "../pages/AcrylicPlate/AcrylicPlate";
import AcrylicView from "../pages/AcrylicView/AcrylicView";
import CustomerView from "../pages/CustomerView/CustomerView";
import CustomerEdit from "../pages/CustomerEdit/CustomerEdit";
import OffersView from "../pages/OffersView/OffersView";
import Contact from "../pages/Contact/Contact";
import SellRequests from "../pages/SellRequests/SellRequests";
import ViewSellRequests from "../pages/SellRequests/ViewSellRequests";
import ContactInfo from "../pages/ContactInfo/ContactInfo";
import SalesForms from "../pages/SalesForms/SalesForms";
import SalesFormsView from "../pages/SalesForms/SalesFormsView";
import PlateViewerForms from "../pages/PlateViewerForms/PlateViewerForms";
import PlateViewerFormsView from "../pages/PlateViewerForms/PlateViewerFormsView";
import DeletedRecords from "../pages/ValuationPlates/DeletedRecords";
import EmailSubscribers from "../pages/EmailSubscribers/EmailSubscribers";

const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    title: "Dashboard",
  },

  {
    path: "/home-content",
    element: (
      <PrivateRoute>
        <HomeContent />
      </PrivateRoute>
    ),
    title: "Home Content",
  },
  {
    path: "/add-content",
    element: (
      <PrivateRoute>
        <AddContent />
      </PrivateRoute>
    ),
    title: "Add Content",
  },
  {
    path: "/home-content/edit/:id",
    element: (
      <PrivateRoute>
        <HomeContentEdit />
      </PrivateRoute>
    ),
    title: "Home Content Edit",
  },
  {
    path: "/home-content/bullets",
    element: (
      <PrivateRoute>
        <HomeContentBullets />
      </PrivateRoute>
    ),
    title: "Home Content Bullets",
  },
  {
    path: "/home-content/bullets/add",
    element: (
      <PrivateRoute>
        <HomeContentBulletsAdd />
      </PrivateRoute>
    ),
    title: "Home Content Bullets Add",
  },
  {
    path: "/home-content/bullets/edit/:id",
    element: (
      <PrivateRoute>
        <HomeContentBulletsEdit />
      </PrivateRoute>
    ),
    title: "Home Content Bullets",
  },
  {
    path: "/home-content/faq",
    element: (
      <PrivateRoute>
        <HomeContentFaq />
      </PrivateRoute>
    ),
    title: "Home Content Faq",
  },
  {
    path: "/home-content/faq/add",
    element: (
      <PrivateRoute>
        <HomeContentFaqAdd />
      </PrivateRoute>
    ),
    title: "Home Content Faq Add",
  },
  {
    path: "/home-content/faq/edit/:id",
    element: (
      <PrivateRoute>
        <HomeContentFaqEdit />
      </PrivateRoute>
    ),
    title: "Home Content Faq edit",
  },
  {
    path: "/salesplates/getplates",
    element: (
      <PrivateRoute>
        <GetPlates />
      </PrivateRoute>
    ),
    title: "Sales Plates",
  },
  {
    path: "/salesplates/getplates/edit/:id",
    element: (
      <PrivateRoute>
        <EditSalesPlates />
      </PrivateRoute>
    ),
    title: "Sales Plates Edit",
  },
  {
    path: "/salesplates/getplates/view/:id",
    element: (
      <PrivateRoute>
        <ViewSalesPlates />
      </PrivateRoute>
    ),
    title: "Sales Plates View",
  },
  {
    path: "/salesplates/add",
    element: (
      <PrivateRoute>
        <AddPlates />
      </PrivateRoute>
    ),
    title: "Sales Plates Add",
  },
  {
    path: "/admins",
    element: (
      <PrivateRoute>
        <Admins />
      </PrivateRoute>
    ),
    title: "Sales Plates Add",
  },

  {
    path: "/admins/edit/:id",
    element: (
      <PrivateRoute>
        <AdminEdit />
      </PrivateRoute>
    ),
    title: "Sales Plates ",
  },
  {
    path: "/admins/add",
    element: (
      <PrivateRoute>
        <AdminAdd />
      </PrivateRoute>
    ),
    title: "Sales Plates Add",
  },
  {
    path: "/get-reg",
    element: (
      <PrivateRoute>
        <GetReg />
      </PrivateRoute>
    ),
    title: "Reg",
  },
  {
    path: "/get-reg/view/:id",
    element: (
      <PrivateRoute>
        <GetRegDetail />
      </PrivateRoute>
    ),
    title: "Reg View",
  },
  {
    path: "/uploadcsv",
    element: (
      <PrivateRoute>
        <UploadCsv />
      </PrivateRoute>
    ),
    title: "Upload Csv",
  },
  {
    path: "/customer",
    element: (
      <PrivateRoute>
        <Customer />
      </PrivateRoute>
    ),
    title: "Customer",
  },
  {
    path: "/customer/add",
    element: (
      <PrivateRoute>
        <CustomerAdd />
      </PrivateRoute>
    ),
    title: "Customer Add",
  },
  {
    path: "/customer/view/:id",
    element: (
      <PrivateRoute>
        <CustomerView />
      </PrivateRoute>
    ),
    title: "Customer Add",
  },
  {
    path: "/customer/edit/:id",
    element: (
      <PrivateRoute>
        <CustomerEdit />
      </PrivateRoute>
    ),
    title: "Customer Add",
  },
  {
    path: "/getenquiry",
    element: (
      <PrivateRoute>
        <GetEnquiry />
      </PrivateRoute>
    ),
    title: "Get Enquiry",
  },
  {
    path: "/getenquiry/view/:id",
    element: (
      <PrivateRoute>
        <ViewEnquiry />
      </PrivateRoute>
    ),
    title: "View Enquiry",
  },

  {
    path: "/homecontentbanner",
    element: (
      <PrivateRoute>
        <HomeContentBanner />
      </PrivateRoute>
    ),
    title: "Home Content Banner",
  },

  {
    path: "/number-plate/faq",
    element: (
      <PrivateRoute>
        <NumberPlateFaq />
      </PrivateRoute>
    ),
    title: "Number Plate Faq",
  },

  {
    path: "/number-plate/faq/add",
    element: (
      <PrivateRoute>
        <NumberPlateFaqAdd />
      </PrivateRoute>
    ),
    title: "Number Plate Faq Add",
  },
  {
    path: "/number-plate/faq/edit/:id",
    element: (
      <PrivateRoute>
        <NumberPlateFaqEdit />
      </PrivateRoute>
    ),
    title: "Number Plate Faq edit",
  },
  {
    path: "/coupons",
    element: (
      <PrivateRoute>
        <Coupons />
      </PrivateRoute>
    ),
    title: "Number Plate Faq edit",
  },
  {
    path: "/blog",
    element: (
      <PrivateRoute>
        <Blog />
      </PrivateRoute>
    ),
    title: "Blogs",
  },
  {
    path: "/blog/add",
    element: (
      <PrivateRoute>
        <BlogAdd />
      </PrivateRoute>
    ),
    title: "Blogs Add",
  },
  {
    path: "/blog/edit/:id",
    element: (
      <PrivateRoute>
        <BlogEdit />
      </PrivateRoute>
    ),
    title: "Blogs Edit",
  },
  {
    path: "/blog/view/:id",
    element: (
      <PrivateRoute>
        <BlogView />
      </PrivateRoute>
    ),
    title: "Blogs View",
  },
  {
    path: "/enquiry",
    element: (
      <PrivateRoute>
        <Enquiry />
      </PrivateRoute>
    ),
    title: "Enquiry",
  },
  {
    path: "/enquiry/add",
    element: (
      <PrivateRoute>
        <EnquiryAdd />
      </PrivateRoute>
    ),
    title: "enquiry Add",
  },
  {
    path: "/enquiry/edit/:id",
    element: (
      <PrivateRoute>
        <EnquiryEdit />
      </PrivateRoute>
    ),
    title: "Enquiry Edit",
  },
  {
    path: "/enquiry/view/:id",
    element: (
      <PrivateRoute>
        <EnquiryView />
      </PrivateRoute>
    ),
    title: "Enquiry View",
  },
  {
    path: "/offers",
    element: (
      <PrivateRoute>
        <Offers />
      </PrivateRoute>
    ),
    title: "offers",
  },
  {
    path: "/offers/add",
    element: (
      <PrivateRoute>
        <OffersAdd />
      </PrivateRoute>
    ),
    title: "offers Add",
  },
  {
    path: "/offers/view/:id",
    element: (
      <PrivateRoute>
        <OffersView />
      </PrivateRoute>
    ),
    title: "Customer Add",
  },
  {
    path: "/payment",
    element: (
      <PrivateRoute>
        <Payment />
      </PrivateRoute>
    ),
    title: "Payment",
  },
  {
    path: "/payment/view/:id",
    element: (
      <PrivateRoute>
        <PaymentView />
      </PrivateRoute>
    ),
    title: "Enquiry View",
  },
  {
    path: "/acrylicplate",
    element: (
      <PrivateRoute>
        <GetAcrylicPlate />
      </PrivateRoute>
    ),
    title: "acrylicplate",
  },
  {
    path: "/acrylicplate/view/:id",
    element: (
      <PrivateRoute>
        <AcrylicView />
      </PrivateRoute>
    ),
    title: "Enquiry View",
  },

  {
    path: "/contact-info",
    element: (
      <PrivateRoute>
        <Contact />
      </PrivateRoute>
    ),
    title: "Contact-Info",
  },
  {
    path: "/contact-info/view/:id",
    element: (
      <PrivateRoute>
        <ContactInfo />
      </PrivateRoute>
    ),
    title: "Contact-Info",
  },

  {
    path: "/sell-requests",
    element: (
      <PrivateRoute>
        <SellRequests />
      </PrivateRoute>
    ),
    title: "Sell Requests",
  },
  {
    path: "/view-sell-requests/:id",
    element: (
      <PrivateRoute>
        <ViewSellRequests />
      </PrivateRoute>
    ),
    title: "View Sell Requests",
  },
  {
    path: "/sell-forms",
    element: (
      <PrivateRoute>
        <SalesForms />
      </PrivateRoute>
    ),
    title: "Sell Forms",
  },
  {
    path: "/sell-forms-view/:id",
    element: (
      <PrivateRoute>
        <SalesFormsView />
      </PrivateRoute>
    ),
    title: "Sell Forms View",
  },

  {
    path: "/plate-viewer-forms",
    element: (
      <PrivateRoute>
        <PlateViewerForms />
      </PrivateRoute>
    ),
    title: "Plate Viewer Forms",
  },
  {
    path: "/plate-viewer-forms-view/:id",
    element: (
      <PrivateRoute>
        <PlateViewerFormsView />
      </PrivateRoute>
    ),
    title: "Plate Viewer Forms View",
  },

  {
    path: "/salesplates/deleted",
    element: (
      <PrivateRoute>
        <DeletedRecords />
      </PrivateRoute>
    ),
    title: "Deleted Records",
  },
  {
    path: "/salesplates/deleted",
    element: (
      <PrivateRoute>
        <DeletedRecords />
      </PrivateRoute>
    ),
    title: "Deleted Records",
  },

  {
    path: "/email-subscribers",
    element: (
      <PrivateRoute>
        <EmailSubscribers />
      </PrivateRoute>
    ),
    title: "Email Subscribers",
  },
];

export default routes;
