import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {toast} from 'react-toastify';
import {Button, Modal} from 'react-bootstrap';
import {fetchDataFromAPI} from '../../utils/api';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import {DataContext} from '../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import RSFront from '../../assets/fonts/RS';

const ViewSalesPlates = () => {
  const {state} = useLocation();
  console.log('state', state?.user);
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showRespondModal, setShowRespondModal] = useState(false);

  const defaultResponseContent = `<div class="reg__valu--data">

  <img src="https://www.reg247.co.uk/static/media/logo.19c90d739a5717a52c80a70d5b71ab8b.svg" alt="Logo"> <br/>
  <br/>
  <div class="reg__data--inner">
      <h2>Dear ${state?.user?.firstName + ' ' + state?.user?.lastName}</h2>
      <br>
      <div class="reg__thank--text">
          <p>Thank you for choosing REG247 to carry out your number plate valuation.</p>
          <p>We have valued your registration ${
            state?.user?.registrationNumber
          } at £ {Enter Price}.</p>
      </div>
      <br/>
      <p>If you have considered selling your registration or would like to advertise your plate for sale, then please get in touch by replying to this email or calling one of our helpful team members on <a href="tel:01582347734">01582 347734</a>.</p>
      <br/>
      <p>Did you know?</p>
      <br/>
      <p>There are no fees involved at all when selling your registration, you can advertise your plate today for FREE. We simply add our commission onto your price when we advertise the plate. Our fees are only payable by the buyer when we sell the plate, so you don’t need to worry about hidden costs!</p>
      <br/>
      <p>We will handle the whole transfer process for you to make things as simple as possible, we are available to answer any questions you may have and aim to make the sale of your plate a smooth and stress - free transaction.</p>
      <br/>
      <p>Sell with us today and take advantage of the following benefits:</p>
      <br/>
      <ul>
          <li>Reach over 100,000 potential buyers each week.</li>
          <li>No fees involved</li>
          <li>Seller protection guaranteed. We will never transfer your plate without receiving full payment from the buyer.</li>
          <li>Get paid quickly. We pay our sellers within 1 hour of the transfer completing</li>
          <li>Add your chosen key words to your listing, making it easier for buyers to search, find and buy your number plate.</li>
          <li>We won’t tie you into a contract to advertise with us.</li>
          <li>Our team will deal with the whole sale process from start to finish. Meaning you can relax, knowing your plate and payment is in safe hands.</li>
          <li> We buy number plates for stock. If you are looking for a quick sale,then please give us a call on <a href="tel:01582347734">01582 347734</a>.</li>
      </ul>
      <br>
      <p>Please get in touch if you have any questions. We look forward to hearing from you soon.</p>
      <br>

      <div class="reg__regards">
          <p>Kind regards</p>
          <p><b>The REG247 Team</b></p>
      </div>
      <br>
      <div class="reg__contect--detail">
          <a href="tel:01582347734">Call: 01582 347734</a>
      </div>
      <div class="reg__contect--detail">
      <a href="mailto:info@reg247.co.uk">Email: info@reg247.co.uk</a>
      </div>
  </div>
</div>`;
  const [responseContent, setResponseContent] = useState(
    defaultResponseContent,
  );
  const [responseError, setResponseError] = useState('');

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const [isListPriceModal, setiSListPriceModal] = useState(false);
  const [isAdminPrice, setAdminPrice] = useState('');
  const [loaPrice, setLoaPrice] = useState('');
  const [adminVAT, setAdminVAT] = useState(false);
  const [isAdminError, setAdminError] = useState('');
  const [isLoaError, setLoaError] = useState('');

  const [comments, setComments] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dataContext = useContext(DataContext);

  useEffect(() => {
    getSalesData();
  }, []);

  useEffect(() => {
    setAdminVAT(state?.user?.is_vat);
  }, []);

  const getSalesData = () => {
    setLoading(true);
    formik.setFieldValue('registrationNumber', state?.user?.registrationNumber);
    formik.setFieldValue('plateHoldingStatus', state?.user?.plateHoldingStatus);
    formik.setFieldValue('keywords', state?.user?.keywords);
    formik.setFieldValue('firstName', state?.user?.firstName);
    formik.setFieldValue('lastName', state?.user?.lastName);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('phone', state?.user?.phone);
    formik.setFieldValue('streetAddress', state?.user?.streetAddress);
    formik.setFieldValue('town', state?.user?.town);
    formik.setFieldValue('country', state?.user?.country);
    formik.setFieldValue('postcode', state?.user?.postcode);
    formik.setFieldValue('is_vat', state?.user?.is_vat);

    formik.setFieldValue('confirmNumPlat', state?.user?.confirmNumPlat);
    formik.setFieldValue('contactPermission', state?.user?.contactPermission);
    formik.setFieldValue(
      'checkboxGroup',
      state?.user?.plateHoldingStatus || '',
    );
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required('Please enter reg'),
    plateHoldingStatus: Yup.string().required('Please enter plate location'),
    keywords: Yup.string().notRequired(),
    firstName: Yup.string().required('Please enter rirst name'),
    lastName: Yup.string().required('Please enter last name'),
    email: Yup.string()
      .email('Please enter a valid email')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        'Email must end with .com',
      )
      .required('Please enter an email'),

    phone: Yup.string()
      .transform((value) => (value ? value.replace(/\s/g, '') : value)) // Remove spaces
      // .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Please enter phone number'),

    streetAddress: Yup.string().required('Please enter street address'),
    town: Yup.string().required('Please enter town'),
    country: Yup.string().required('Please enter country'),
    postcode: Yup.string().required('Please enter postcode'),
    is_vat: Yup.string().notRequired(),
    checkboxGroup: Yup.string().test(
      'at-least-one-selected',
      'Please select at least one option',
      (value) => {
        return !!value;
      },
    ),
  });

  const formik = useFormik({
    initialValues: {
      registrationNumber: '',
      plateHoldingStatus: '',
      keywords: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      town: '',
      country: '',
      postcode: '',
      confirmNumPlat: '',
      contactPermission: '',
      plateHoldingStatus: '',
      checkboxGroup: '',
      is_vat: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      setLoading(true);

      const body = {
        registrationNumber: values?.registrationNumber,
        firstName: values?.firstName,
        keywords: values?.keywords,
        plateHoldingStatus: values?.plateHoldingStatus,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        streetAddress: values?.streetAddress,
        country: values?.country,
        town: values?.town,
        postcode: values?.postcode,
        contactPermission: values?.contactPermission ? true : false,
        is_vat: values?.is_vat ? true : false,
        plateHoldingStatus: values?.checkboxGroup,
      };

      console.log('body', body);

      fetchDataFromAPI(
        `user/valutions/update-valutions?valutions_id=${state?.user?._id}`,
        'put',
        body,
        '',
      )
        .then((res) => {
          setLoading(false);

          toast.success('updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('update member res:', res?.data);
          navigate('/salesplates/getplates');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating: ', error);
        });
    },
  });

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/valutions/delete-valutions?valuations_id=${deleteId}&delete_type=soft`,
      'delete',
      '',
      '',
    )
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
        navigate('/salesplates/getplates');
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    // if (commentContent.trim() === "") {
    //   setCommentError("Please enter comment");
    //   return;
    // }

    const body = {
      Customer_id: state?.user?._id,
      comment: commentContent || responseContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        getComments();
        // toast.success(res?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: "bg-success text-white",
        // });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const handleResponseSubmit = () => {
    // if (responseContent.trim() === "") {
    //   setResponseError("Response cannot be empty");
    //   return;
    // }

    const body = {
      email: state?.user?.email,
      message: responseContent,
      reg: state?.user?.registrationNumber,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/respond-to-enquery', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        handleCommentSubmit();
        toast.success(res?.message + ' ✅ ', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setShowRespondModal(false);
        setResponseContent('');
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setResponseContent('');
        setShowRespondModal(false);
      });

    // Your logic to handle the response submission
  };

  const showModal = () => {
    // setResponseContent("");
    setResponseError('');
    setShowRespondModal(true);
  };

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // funcation for read
  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.isCommentRead; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)
    setLoading(true);

    const body = {
      isCommentRead: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };

    fetchDataFromAPI(
      `user/valutions/update-valutions?valutions_id=${state?.user?._id}`,
      'put',
      body,
      '',
    )
      .then((res) => {
        console.log('res ------->', res);

        getCommentsCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);

        navigate('/salesplates/getplates');
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
      });
  };

  const getCommentsCount = () => {
    fetchDataFromAPI(
      `user/valutions/get-valutions?pending_msg=false`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('count --> ', res?.pendingMsg);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setCommentCount(res?.pendingMsg);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleEditorChange = (html) => {
    if (html.trim() === '') {
      setResponseError('Response content cannot be empty');
    } else {
      setResponseContent(html);
      setResponseError('');
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedCheckbox(id);
    formik.setFieldValue('checkboxGroup', id);
  };

  const showList = () => {
    // isAdminPrice("");
    setiSListPriceModal(true);
  };

  const listValuation = () => {
    if (isAdminPrice.trim() === '') {
      setAdminError('Please enter price');
      return;
    }
    if (loaPrice.trim() === '') {
      setLoaError('Please enter price');
      return;
    }
    const body = {
      admin_price: isAdminPrice,
      is_vat: adminVAT,
      loa_price: loaPrice,
    };
    setLoading(true);
    console.log('body', body);
    fetchDataFromAPI(`user/valutions?id=${state?.user?._id}`, 'put', body, '')
      .then((res) => {
        console.log('res val ----> ', res);
        setiSListPriceModal(false);
        setLoading(false);
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setAdminPrice('');
        navigate('/salesplates/getplates');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setiSListPriceModal(false);
        setAdminPrice('');
      });
  };

  let nonSpaceEntered = false;
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/salesplates/getplates">Valuation Requests</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="registrationNumber"
                              className="form-label">
                              Reg
                            </label>
                            <input
                              name="registrationNumber"
                              type="text"
                              className="form-control"
                              id="registrationNumber"
                              value={formik.values.registrationNumber}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'registrationNumber',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.registrationNumber &&
                              formik.errors.registrationNumber && (
                                <div className="text-danger">
                                  {formik.errors.registrationNumber}
                                </div>
                              )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="plateHoldingStatus"
                              className="form-label">
                              Plate Location
                            </label>
                            <input
                              name="plateHoldingStatus"
                              type="text"
                              className="form-control"
                              id="plateHoldingStatus"
                              value={formik.values.plateHoldingStatus}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'plateHoldingStatus',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.plateHoldingStatus &&
                              formik.errors.plateHoldingStatus && (
                                <div className="text-danger">
                                  {formik.errors.plateHoldingStatus}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="keywords" className="form-label">
                            keywords
                          </label>
                          <input
                            name="keywords"
                            type="text"
                            className="form-control"
                            id="keywords"
                            value={formik.values.keywords}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'keywords',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.keywords &&
                            formik.errors.keywords && (
                              <div className="text-danger">
                                {formik.errors.keywords}
                              </div>
                            )}
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              name="firstName"
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={formik?.values?.firstName}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'firstName',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <div className="text-danger">
                                  {formik.errors.firstName}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="lastName" className="form-label">
                              Last Name
                            </label>
                            <input
                              name="lastName"
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={formik.values.lastName}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'lastName',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.lastName &&
                              formik.errors.lastName && (
                                <div className="text-danger">
                                  {formik.errors.lastName}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label
                              htmlFor="SPSVLicenceNumber"
                              className="form-label">
                              Email
                            </label>
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              id="email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'email',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="AgreementVersion"
                              className="form-label">
                              Phone
                            </label>
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              id="phone"
                              value={formik?.values?.phone}
                              onChange={(e) => {
                                let input = e.target.value;
                                const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                if (!nonSpaceEntered) {
                                  // If no non-space character entered yet, trim spaces
                                  input = input.trimStart();
                                }
                                if (regex.test(input) || input === '') {
                                  formik.setFieldValue('phone', input);
                                  // Check if a non-space character has been entered
                                  if (input !== '' && !nonSpaceEntered) {
                                    nonSpaceEntered = true;
                                  }
                                }
                              }}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <div className="text-danger">
                                {formik.errors.phone}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="streetAddress"
                              className="form-label">
                              Street Address
                            </label>
                            <input
                              name="streetAddress"
                              type="text"
                              className="form-control"
                              id="streetAddress"
                              value={formik?.values?.streetAddress}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'streetAddress',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.streetAddress &&
                              formik.errors.streetAddress && (
                                <div className="text-danger">
                                  {formik.errors.streetAddress}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <label htmlFor="town" className="form-label">
                              Town
                            </label>
                            <input
                              name="town"
                              type="text"
                              className="form-control"
                              id="town"
                              value={formik?.values?.town}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'town',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.town && formik.errors.town && (
                              <div className="text-danger">
                                {formik.errors.town}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="country" className="form-label">
                              country
                            </label>
                            <input
                              name="country"
                              type="text"
                              className="form-control"
                              id="country"
                              value={formik?.values?.country}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'country',
                                  e.target.value?.trimStart(),
                                );
                              }}

                              // Adjust the format as needed
                            />
                            {formik.touched.country &&
                              formik.errors.country && (
                                <div className="text-danger">
                                  {formik.errors.country}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="postcode" className="form-label">
                              Postcode
                            </label>
                            <input
                              name="postcode"
                              type="text"
                              className="form-control"
                              id="postcode"
                              value={formik.values.postcode}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'postcode',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.postcode &&
                              formik.errors.postcode && (
                                <div className="text-danger">
                                  {formik.errors.postcode}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-between my-2">
                          {/* <div className="form-check">
                            <input
                              name="confirmNumPlat"
                              type="checkbox"
                              className="form-check-input"
                              id="confirmNumPlat"
                              checked={formik.values.confirmNumPlat}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "confirmNumPlat",
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="confirmNumPlat"
                            >
                              Confirm Number Plate
                            </label>
                            {formik.touched.confirmNumPlat &&
                              formik.errors.confirmNumPlat && (
                                <div className="text-danger">
                                  {formik.errors.confirmNumPlat}
                                </div>
                              )}
                          </div> */}
                          <div className="col-md-3">
                            <div className="form-check">
                              <input
                                name="contactPermission"
                                type="checkbox"
                                className="form-check-input"
                                id="contactPermission"
                                checked={formik.values.contactPermission}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'contactPermission',
                                    e.target.checked,
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="contactPermission">
                                Subscribed
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-check">
                              <input
                                name="is_vat"
                                type="checkbox"
                                className="form-check-input"
                                id="is_vat"
                                checked={formik.values.is_vat}
                                onChange={(e) => {
                                  setAdminVAT(e.target.checked);
                                  formik.setFieldValue(
                                    'is_vat',
                                    e.target.checked,
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_vat">
                                VAT registration
                              </label>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              id="certificate"
                              name="listdata"
                              // value={
                              //   formik.values.checkboxGroup === "retention"
                              // }
                              checked={
                                formik.values.checkboxGroup === 'retention'
                              }
                              onChange={() => handleCheckboxChange('retention')}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="certificate">
                              retention
                            </label>
                          </div>
                          <div className="col-md-3">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              id="held"
                              name="listdata"
                              checked={
                                formik.values.checkboxGroup === 'assigned'
                              }
                              onChange={() => handleCheckboxChange('assigned')}
                            />
                            <label className="form-check-label" htmlFor="held">
                              assigned
                            </label>
                          </div>
                          {formik.touched.checkboxGroup &&
                            formik.errors.checkboxGroup && (
                              <div className="text-danger">
                                {formik.errors.checkboxGroup}
                              </div>
                            )}
                        </div>

                        <div className="col-12 mb-15 d-flex flex-wrap">
                          <div className="form-check mb-8 col-12 col-xxl-5"></div>
                          <div className="form-check mb-8 col-12 col-xxl-7"></div>
                          {/* <div className="form-check mb-8 col-12">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="selling"
                              name="listdata"
                              checked={selectedCheckbox === "selling"}
                              onChange={() => handleCheckboxChange("selling")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="selling"
                            >
                              I am selling this number plate through a VAT
                              registered company
                            </label>
                          </div> */}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div className="container mb-5">
                      <div className="row justify-content-center mt-4">
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title mb-3">Comments</h4>
                              <hr className="my-1" />
                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'auto',
                                }}>
                                {comments?.length > 0 ? (
                                  comments.map((comment, index) => (
                                    <div key={index} className="card mb-3">
                                      <div className="card-body">
                                        <p className="card-text">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: comment?.msg,
                                            }}
                                          />
                                        </p>
                                        <p className="text-end text-muted small">
                                          {new Date(
                                            comment?.timestamp,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-muted">No comments yet</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex col-md-12 justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          onClick={() => funcationRead()}
                          type="submit"
                          // disabled={
                          //   state?.user?.isCommentRead === true ? true : false
                          // } // Disable if isCommentRead is false
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>
                          {state?.user?.isCommentRead === true
                            ? 'Mark as Unread'
                            : 'Mark as Read'}
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showModal()}
                          type="submit"
                          className="btn btn-secondary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-chat-dots-fill me-2"></i>
                          Respond to this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showList()}
                          type="button"
                          className="btn btn-success"
                          disabled={state?.user?.is_listed ? true : false}>
                          <i class="bi bi-check2"></i> {''}List this valuation
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => setDeleteId(state?.user?._id)}
                          type="submit"
                          className="btn btn-danger"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-trash-fill me-2"></i>
                          Delete this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showComment()}
                          type="submit"
                          className="btn btn-info"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-plus-circle-fill me-2"></i>
                          Add a comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Valuation Requests"
          body="Are you sure you want to delete this record?"
        />
      </section>

      {/* Input model */}
      <Modal
        show={isListPriceModal}
        centered
        onHide={() => setiSListPriceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>List This Valuation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <label className="mt-2">Sales Price</label>
            {/* <label className="mt-2">Admin Price</label> */}
            <input
              name="username"
              type="number"
              min={0}
              className={`form-control ${isAdminError ? 'is-invalid' : ''}`}
              id="username"
              value={isAdminPrice}
              // onChange={(e) => {
              //   setAdminPrice(e.target.value?.trimStart());
              //   setAdminError("");
              // }}
              onChange={(e) => {
                let value = e.target.value.trimStart();
                // Check if the input is a valid positive number without double minus sign
                if (
                  /^\d*\.?\d*$/.test(value) &&
                  !/--/.test(value) &&
                  parseFloat(value) >= 0
                ) {
                  // Allow valid positive numbers or an empty string (to allow clearing the input)
                  setAdminPrice(value);
                  setAdminError('');
                }
              }}
            />
            {isAdminError && (
              <div className="invalid-feedback">{isAdminError}</div>
            )}
            <label className="mt-2">LOA Price</label>
            {/* <label className="mt-2">Admin Price</label> */}
            <input
              name="username"
              type="number"
              min={0}
              className={`form-control ${isAdminError ? 'is-invalid' : ''}`}
              id="username"
              value={loaPrice}
              // onChange={(e) => {
              //   setAdminPrice(e.target.value?.trimStart());
              //   setAdminError("");
              // }}
              onChange={(e) => {
                let value = e.target.value.trimStart();
                // Check if the input is a valid positive number without double minus sign
                if (
                  /^\d*\.?\d*$/.test(value) &&
                  !/--/.test(value) &&
                  parseFloat(value) >= 0
                ) {
                  // Allow valid positive numbers or an empty string (to allow clearing the input)
                  setLoaPrice(value);
                  setLoaError('');
                }
              }}
            />
            {isLoaError && <div className="invalid-feedback">{isLoaError}</div>}

            <div className="form-check m-2">
              <input
                name="is_vat"
                type="checkbox"
                className="form-check-input"
                // id="is_vat"
                checked={adminVAT}
                onChange={(e) => {
                  setAdminVAT(e.target.checked);
                }}
              />
              <label
                className="form-check-label"
                // htmlFor="is_vat"
              >
                VAT registration
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setiSListPriceModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={listValuation}>
            {!loading && <span className="indicator-label">Send</span>}
            {loading && (
              <span className="indicator-progress" style={{display: 'block'}}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value);
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for email send */}
      <Modal
        size="lg"
        show={showRespondModal}
        centered
        onHide={() => setShowRespondModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Respond to valuation : {state?.user?.registrationNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ReactQuill
              theme="snow"
              className={`form-control ${responseError ? 'is-invalid' : ''}`}
              value={responseContent}
              onChange={handleEditorChange}
              // style={{ height: 150 }}

              // style={{height: 200}}
            />
            {/* <textarea
              name="content"
              id="content"
              className={`form-control ${responseError ? "is-invalid" : ""}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={responseContent}
              onChange={(e) => {
                setResponseContent(e.target.value);
                setResponseError("");
              }}
              style={{ height: 200 }}
            /> */}
            {responseError && (
              <div className="invalid-feedback">{responseError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowRespondModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResponseSubmit}>
            Respond via email
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default ViewSalesPlates;
