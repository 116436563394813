import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {toast} from 'react-toastify';
import {Button, Modal} from 'react-bootstrap';
import {fetchDataFromAPI} from '../../utils/api';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import {DataContext} from '../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';

const OffersView = () => {
  const {state} = useLocation();

  console.log('user', state?.user);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showRespondModal, setShowRespondModal] = useState(false);

  const defaultResponseContent = `Many thanks for the valuation request for ${state?.user?.registrationNumber}.<br><br>We have valued the registration mark at {Enters price here}.<br><br>Our valuation price is based on what we can realistically return to you in the current market.<br><br>If you choose to advertise you plate for a higher price, this is fine but please note it is likely to increase the sales time. Likewise, if you choose to reduce the sales price, this could help generate a quicker sale.<br><br>If you'd like to discuss with one of our experts, please call us on 01582 347734. Or, if you'd like to go ahead, please reply 'yes' to this email and we'll get started right away.<br><br>Kind regards,<br>REG247.`;
  const [responseContent, setResponseContent] = useState(
    defaultResponseContent,
  );
  const [responseError, setResponseError] = useState('');

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const [comments, setComments] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dataContext = useContext(DataContext);

  useEffect(() => {
    getSalesData();
  }, []);

  const getSalesData = () => {
    setLoading(true);

    formik.setFieldValue('reg', state?.user?.reg);
    formik.setFieldValue('first_name', state?.user?.first_name);
    formik.setFieldValue('last_name', state?.user?.last_name);
    formik.setFieldValue('middle_initials', state?.user?.middle_initials);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('phone_number', state?.user?.phone_number);
    formik.setFieldValue('offer_price', state?.user?.offer_price);

    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    reg: Yup.string().required('Please enter First name'),
    offer_price: Yup.string().required('Please enter offer price'),
    first_name: Yup.string().required('Please enter First name'),
    last_name: Yup.string().required('Please enter lastName'),
    middle_initials: Yup.string().notRequired(),
    email: Yup.string()
      .email('Please enter a valid email')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        'Email must end with .com',
      )
      .required('Please enter an email'),

    phone_number: Yup.string()

      .transform((value) => (value ? value.replace(/\s/g, '') : value)) // Remove spaces
      // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      reg: '',
      first_name: '',
      last_name: '',
      middle_initials: '',
      offer_price: '',
      email: '',
      phone_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      setLoading(true);

      const body = {
        reg: values?.reg,
        first_name: values?.first_name,
        last_name: values?.last_name,
        middle_initials: values?.middle_initials,
        email: values?.email,
        phone: values?.phone_number,
        offer_price: values?.offer_price,
      };

      console.log('body', body);

      fetchDataFromAPI(
        `user/userOffer?id=${state?.user?._id}`,
        'patch',
        body,
        '',
      )
        .then((res) => {
          setLoading(false);

          toast.success('updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('update member res:', res?.data);
          navigate('/offers');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating: ', error);
        });
    },
  });

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/userOffer?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
        navigate('/offers');
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    // if (commentContent.trim() === "" && !responseContent) {
    //   setCommentError("Please enter comment");
    //   return;
    // }

    const body = {
      Customer_id: state?.user?._id,
      comment: commentContent || responseContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res commment', res);
        getComments();
        // toast.success(res?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: "bg-success text-white",
        // });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error comment', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const handleResponseSubmit = () => {
    if (responseContent.trim() === '') {
      setResponseError('Response cannot be empty');
      return;
    }

    const body = {
      email: state?.user?.email,
      message: responseContent,
      reg: state?.user?.reg,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/respond-to-enquery', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        handleCommentSubmit();
        toast.success(res?.message + ' ✅ ', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setShowRespondModal(false);
        setResponseContent('');
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setResponseContent('');
        setShowRespondModal(false);
      });

    // Your logic to handle the response submission
  };

  const showModal = () => {
    // setResponseContent("");
    setResponseError('');
    setShowRespondModal(true);
  };

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // funcation for read
  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)

    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };

    fetchDataFromAPI(`user/userOffer?id=${state?.user?._id}`, 'patch', body, '')
      .then((res) => {
        console.log('res ------->', res);

        getOfferCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        navigate('/offers');
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getOfferCount = () => {
    fetchDataFromAPI(`user/userOffer/count`, 'get', '', '')
      .then((res) => {
        console.log('count Offer --> ', res?.data);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setOfferCount(res?.data);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleEditorChange = (html) => {
    if (html.trim() === '') {
      setResponseError('Response content cannot be empty');
    } else {
      setResponseContent(html);
      setResponseError('');
    }
  };

  let nonSpaceEntered = false;

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/offers">Offer</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Offer Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="registrationNumber"
                              className="form-label">
                              Reg
                            </label>
                            <input
                              name="reg"
                              type="text"
                              className="form-control"
                              id="reg"
                              value={formik.values.reg}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'reg',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.reg && formik.errors.reg && (
                              <div className="text-danger">
                                {formik.errors.reg}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="plateHoldingStatus"
                              className="form-label">
                              Offer Price
                            </label>

                            <input
                              name="offer_price"
                              type="text"
                              className="form-control"
                              id="offer_price"
                              value={formik.values.offer_price}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'offer_price',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.price &&
                              formik.errors.offer_price && (
                                <div className="text-danger">
                                  {formik.errors.offer_price}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              name="first_name"
                              type="text"
                              className="form-control"
                              id="first_name"
                              value={formik?.values?.first_name}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'first_name',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.first_name &&
                              formik.errors.first_name && (
                                <div className="text-danger">
                                  {formik.errors.first_name}
                                </div>
                              )}
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="lastName" className="form-label">
                              Last Name
                            </label>
                            <input
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="last_name"
                              value={formik.values.last_name}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'last_name',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.last_name &&
                              formik.errors.last_name && (
                                <div className="text-danger">
                                  {formik.errors.last_name}
                                </div>
                              )}
                          </div>
                          <div className="col-md-3">
                            <label
                              htmlFor="SPSVLicenceNumber"
                              className="form-label">
                              Email
                            </label>
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              id="email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'email',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3">
                            <label
                              htmlFor="AgreementVersion"
                              className="form-label">
                              Phone
                            </label>
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              id="phone"
                              value={formik?.values?.phone_number}
                              onChange={(e) => {
                                let input = e.target.value;
                                const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                if (!nonSpaceEntered) {
                                  // If no non-space character entered yet, trim spaces
                                  input = input.trimStart();
                                }
                                if (regex.test(input) || input === '') {
                                  formik.setFieldValue('phone_number', input);
                                  // Check if a non-space character has been entered
                                  if (input !== '' && !nonSpaceEntered) {
                                    nonSpaceEntered = true;
                                  }
                                }
                              }}
                            />
                            {formik.touched.phone_number &&
                              formik.errors.phone_number && (
                                <div className="text-danger">
                                  {formik.errors.phone_number}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <hr />
                    <h4 className="text-center">Seller's Information</h4>
                    <hr />

                    <div className="col-xl-12">
                      <div className="card ">
                        <div className="card-body pt-6 ">
                          <div className="tab-content pt-2 ">
                            <div
                              className="tab-pane fade show active profile-overview"
                              id="profile-overview">
                              <div className="mt-3">
                                {state?.user?.id?.manage_customer ? (
                                  <div className="row">
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        First Name :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.firstName
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Last Name :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.lastName
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Email :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.email
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Country :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.country
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Town :
                                      </div>
                                      <div className="col-md-6">
                                        {state?.user?.id?.manage_customer?.town}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Postcode :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.postcode
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        landline :
                                      </div>
                                      <div className="col-md-6">
                                        {
                                          state?.user?.id?.manage_customer
                                            ?.landline
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Address Line 1 :
                                      </div>
                                      <div className="col-md-6">
                                        {state?.user?.id?.manage_customer
                                          ?.addressLine1
                                          ? state?.user?.id?.manage_customer
                                              ?.addressLine1
                                          : '-'}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="col-md-6 label">
                                        Address Line 2 :
                                      </div>
                                      <div className="col-md-6">
                                        {state?.user?.id?.manage_customer
                                          ?.addressLine2
                                          ? state?.user?.id?.manage_customer
                                              ?.addressLine2
                                          : '-'}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-center">
                                    No customer data available
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div
                      className="container mb-5"
                      style={{maxHeight: '400px'}}>
                      <div className="row justify-content-center mt-4">
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title mb-3">Comments</h4>
                              <hr className="my-1" />
                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'auto',
                                }}>
                                {comments?.length > 0 ? (
                                  comments.map((comment, index) => (
                                    <div key={index} className="card mb-3">
                                      <div className="card-body">
                                        <p className="card-text">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: comment?.msg,
                                            }}
                                          />
                                        </p>
                                        <p className="text-end text-muted small">
                                          {new Date(
                                            comment?.timestamp,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-muted">No comments yet</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          onClick={() => funcationRead()}
                          type="submit"
                          // disabled={
                          //   state?.user?.is_read === true ? true : false
                          // } // Disable if isCommentRead is false
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>
                          {state?.user?.is_read === true
                            ? 'Mark as Unread'
                            : 'Mark as Read'}
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showModal()}
                          type="submit"
                          className="btn btn-secondary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-chat-dots-fill me-2"></i>
                          Respond to this offer
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => setDeleteId(state?.user?._id)}
                          type="submit"
                          className="btn btn-danger"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-trash-fill me-2"></i>
                          Delete this offer
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showComment()}
                          type="submit"
                          className="btn btn-info"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-plus-circle-fill me-2"></i>
                          Add a comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Offer Requests"
          body="Are you sure you want to delete this record?"
        />
      </section>

      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value?.trimStart());
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for email send */}
      <Modal
        show={showRespondModal}
        centered
        onHide={() => setShowRespondModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Respond to offer : {state?.user?.reg}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ReactQuill
              theme="snow"
              className={`form-control ${responseError ? 'is-invalid' : ''}`}
              value={responseContent}
              onChange={handleEditorChange}
              // style={{ height: 150 }}

              // style={{height: 200}}
            />
            {/* <textarea
              name="content"
              id="content"
              className={`form-control ${responseError ? "is-invalid" : ""}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={responseContent}
              onChange={(e) => {
                setResponseContent(e.target.value);
                setResponseError("");
              }}
              style={{ height: 200 }}
            /> */}
            {responseError && (
              <div className="invalid-feedback">{responseError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowRespondModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResponseSubmit}>
            Respond via email
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default OffersView;
