import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../../utils/api";
const CustomerEdit = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  console.log("state", state?.user);

  const navigate = useNavigate();

  useEffect(() => {
    getSalesData();
  }, []);

  const getSalesData = () => {
    setLoading(true);

    formik.setFieldValue("firstName", state?.user?.firstName);
    formik.setFieldValue("lastName", state?.user?.lastName);
    formik.setFieldValue("email", state?.user?.email);
    formik.setFieldValue("mobile", state?.user?.mobile);
    formik.setFieldValue("landline", state?.user?.landline);
    formik.setFieldValue("country", state?.user?.country);
    formik.setFieldValue("addressLine1", state?.user?.addressLine1);
    formik.setFieldValue("addressLine2", state?.user?.addressLine2);
    formik.setFieldValue("town", state?.user?.town);
    formik.setFieldValue("postcode", state?.user?.postcode);
    formik.setFieldValue("active", state?.user?.active);

    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter firstname"),
    lastName: Yup.string().required("Please enter lastname"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Email must end with .com"
      )
      .required("Please enter an email"),

    mobile: Yup.string()
      .transform((value) => (value ? value.replace(/\s/g, "") : value)) // Remove spaces
      // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),

    landline: Yup.string().notRequired(""),

    addressLine1: Yup.string().notRequired(""),
    addressLine2: Yup.string().notRequired(""),
    town: Yup.string().required("Please enter Town"),
    country: Yup.string().required("Please enter country"),
    postcode: Yup.string().required("Please enter postcode"),
    active: Yup.string().notRequired(""),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      landline: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      postcode: "",
      active: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      setLoading(true);

      const body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        mobile: values?.mobile,
        landline: values?.landline,
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        town: values?.town,
        country: values?.country,
        postcode: values?.postcode,
        active: values?.active ? values?.active : false,
      };

      console.log("body", body);

      fetchDataFromAPI(
        `user/managerCustomer/update-manage-customer?customer_id=${state?.user?._id}`,
        "put",
        body,
        ""
      )
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("update member res:", res?.data);
          navigate("/customer");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating: ", error);
        });
    },
  });

  return (
    <main id="main" className="main">
      {/* {loading && <Processing />} */}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/customer">Customer</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="form-label">
                          Firstname
                        </label>
                        <input
                          name="firstname"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={formik.values.firstName}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "firstName",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="form-label">
                          Lastname
                        </label>
                        <input
                          name="lastname"
                          type="text"
                          className="form-control"
                          id="lastname"
                          value={formik.values.lastName}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "lastName",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <div className="text-danger">
                            {formik.errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label
                          htmlFor="SPSVLicenceNumber"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="mobile" className="form-label">
                          Phone
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={formik?.values?.mobile}
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers
                            if (regex.test(input) || input === "") {
                              formik.setFieldValue("mobile", input);
                            }
                          }}
                        />
                        {formik.touched.mobile && formik.errors.mobile && (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="landline" className="form-label">
                          Landline (optional)
                        </label>
                        <input
                          name="landline"
                          type="text"
                          className="form-control"
                          id="landline"
                          value={formik.values.landline}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "landline",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.landline && formik.errors.landline && (
                          <div className="text-danger">
                            {formik.errors.landline}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="addressline" className="form-label">
                          Address line 1 (optional)
                        </label>
                        <input
                          name="addressline"
                          type="text"
                          className="form-control"
                          id="addressline"
                          value={formik?.values?.addressLine1}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "addressLine1",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.addressLine1 &&
                          formik.errors.addressLine1 && (
                            <div className="text-danger">
                              {formik.errors.addressLine1}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="addresslineoptional"
                          className="form-label"
                        >
                          Address line 2 (optional)
                        </label>
                        <input
                          name="addresslineoptional"
                          type="text"
                          className="form-control"
                          id="addressLine2"
                          value={formik?.values?.addressLine2}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "addressLine2",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.addressLine2 &&
                          formik.errors.addressLine2 && (
                            <div className="text-danger">
                              {formik.errors.addressLine2}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <label htmlFor="town" className="form-label">
                          Town
                        </label>
                        <input
                          name="town"
                          type="text"
                          className="form-control"
                          id="town"
                          value={formik?.values?.town}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "town",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.town && formik.errors.town && (
                          <div className="text-danger">
                            {formik.errors.town}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="county" className="form-label">
                          County
                        </label>
                        <input
                          name="county"
                          type="text"
                          className="form-control"
                          id="county"
                          value={formik?.values?.country}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "country",
                              e.target.value?.trimStart()
                            );
                          }}

                          // Adjust the format as needed
                        />
                        {formik.touched.country && formik.errors.country && (
                          <div className="text-danger">
                            {formik.errors.country}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="postcode" className="form-label">
                          Postcode
                        </label>
                        <input
                          name="postcode"
                          type="text"
                          className="form-control"
                          id="postcode"
                          value={formik.values.postcode}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "postcode",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.postcode && formik.errors.postcode && (
                          <div className="text-danger">
                            {formik.errors.postcode}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 align-self-center d-flex gap-2">
                        <label className="form-check-label" htmlFor="active">
                          Active?
                        </label>
                        <div className="form-check">
                          <input
                            name="active"
                            type="checkbox"
                            className="form-check-input"
                            id="active"
                            checked={formik.values.active}
                            onChange={(e) => {
                              formik.setFieldValue("active", e.target.checked);
                            }}
                          />

                          {formik.touched.active && formik.errors.active && (
                            <div className="text-danger">
                              {formik.errors.active}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Edit Customer</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CustomerEdit;
