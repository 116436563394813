import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";

const SalesFormsView = () => {
  const { state } = useLocation();
  console.log("enquiry: ", state?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)

    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };
    fetchDataFromAPI(`user/sells?id=${state?.user?._id}`, "patch", body, "")
      .then((res) => {
        console.log("res ------->", res);

        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        setLoading(false);
        navigate("/sell-forms");
      })
      .catch((error) => {
        setLoading(false);

        console.log("error", error);
      });
  };
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/sell-forms">Sell Forms</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Sell Forms Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-start">
          <div className="col-xl-12">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview"
                  >
                    <div className="mt-3">
                      {state?.user ? (
                        <div className="row">
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Reg :</div>
                            <div className="col-md-6">
                              {state?.user?.registrationNumber}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Email :</div>
                            <div className="col-md-6">{state?.user?.email}</div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">First Name :</div>
                            <div className="col-md-6">
                              {state?.user?.firstName}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Last Name :</div>
                            <div className="col-md-6">
                              {state?.user?.lastName}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Town :</div>
                            <div className="col-md-6">{state?.user?.town}</div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Postcode :</div>
                            <div className="col-md-6">
                              {state?.user?.postcode}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Price :</div>
                            <div className="col-md-6">
                              £{state?.user?.price}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">
                              Street Address :
                            </div>
                            <div className="col-md-6">
                              {state?.user?.streetAddress}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Phone :</div>
                            <div className="col-md-6">{state?.user?.phone}</div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">Keywords :</div>
                            <div className="col-md-6">
                              {state?.user?.keywords
                                ? state?.user?.keywords
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">country :</div>
                            <div className="col-md-6">
                              {state?.user?.country}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">county :</div>
                            <div className="col-md-6">
                              {state?.user?.county}
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6 label">
                              Plate Holding Status :
                            </div>
                            <div className="col-md-6">
                              {state?.user?.plateHoldingStatus}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center">
                          No customer data available
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="text-center mx-3">
                      <button
                        onClick={() => funcationRead()}
                        type="submit"
                        // disabled={state?.user?.is_read === true ? true : false} // Disable if isCommentRead is false
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        <i class="bi bi-eye-fill me-2"></i>
                        {state?.user?.is_read === true
                          ? "Mark as Unread"
                          : "Mark as Read"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SalesFormsView;
