import React, {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import {FormGroup} from 'react-bootstrap';
import axios from 'axios';
import PopUpModal from '../../components/PopUpModal/PopUpModal';

const UploadCsv = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [email, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [csvData, setCsvData] = useState([]);
  const [isValidFormat, setIsValidFormat] = useState(false);
  useEffect(() => {
    getCustomer();
  }, []);
  const reset = () => {
    ref.current.value = '';
  };

  const getCustomer = async () => {
    setLoading(true);

    axios({
      url: `${base_url}user/managerCustomer/get-manage-customer?page=-1`,
      method: 'get',
    })
      .then((response) => {
        console.log('res', response?.data);
        console.log('get customer manage --->', response?.data?.data);
        // console.log("conform payment", paymentStatus)
        // setDownloadCSVFile(response?.data,"data.csv")
        setEmail(response?.data?.data);

        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      csvFile: null,
      vat: false,
      // owned: false,
      // featured: false,
      email: '',
      type: '',
    },
    validationSchema: Yup.object({
      //   firstname: Yup.string().required('Please enter first name.'),
      vat: Yup.string().notRequired(),
      // featured: Yup.string().required("Please Selete featured."),
      // owned: Yup.string().notRequired(),
      csvFile: Yup.mixed().required('Please selete a csv'),
      type: Yup.string().required('Please select type.'),
      email: Yup.string()
        // .email("Invalid email")
        .required('Please select your email.'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const {vat, featured, csvFile, email, type, owned} = values;
      try {
        await formik.validateForm(); // Validate the form

        if (!isValidFormat) {
          throw new Error('Invalid CSV format.');
        }

        // If form is valid and CSV format is valid, open the modal and make the API call
        setShowModal(true);
      } catch (error) {
        console.log('Validation Error:', error);
        // Handle validation error...
      }
    },
    // console.log("values", values);

    // try {
    //   const formData = new FormData();
    //   setLoading(true);
    //   formData.append("vat", vat);
    //   formData.append("featured", featured);
    //   formData.append("owned", owned);
    //   formData.append("csvFile", csvFile);
    //   formData.append("customerEmail", email);
    //   formData.append("type", type);

    //   axios
    //     .post(
    //       "https://reg247.co.uk:8443/api/v1/user/csv/upload-CSV",

    //       formData
    //     )
    //     .then((responce) => {
    //       toast.success("Upload successful.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: "bg-success text-white",
    //       });
    //       setLoading(false);
    //       formik.resetForm();
    //       navigate("/uploadcsv");
    //       console.log("responce", responce);
    //     })
    //     .catch((error) => {
    //       toast.error("Error while updating Csv", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: "bg-danger text-white",
    //       });
    //       setLoading(false);
    //       console.log("error", error);
    //     });
    // } catch (error) {
    //   console.log("catch error: ", error);
    //   setLoading(false);
    // }
  });

  // Function to handle closing the modal without uploading CSV
  const handleCloseModal = () => {
    setShowModal(false);
  };

  function convertToCSV(data) {
    if (data.length === 0) {
      return '';
    }

    const headers = Object.keys(data[0]);
    const csvRows = [];

    // Add the headers row
    csvRows.push(headers.join(','));

    // Add the data rows
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
        return `"${escaped}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  // Function to download the CSV file
  function downloadCSV(csv, filename) {
    const csvBlob = new Blob([csv], {type: 'text/csv'});
    const url = URL.createObjectURL(csvBlob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Function to handle confirmation of uploading CSV
  const handleUploadConfirmation = async () => {
    const {vat, featured, csvFile, email, type, owned} = formik.values;
    console.log('type', type);
    setShowModal(false);
    const formData = new FormData();
    setLoading(true);
    formData.append('vat', vat);
    // formData.append("featured", featured);
    // formData.append("owned", owned);
    formData.append('csvFile', csvFile);
    formData.append('customerEmail', email);
    formData.append('listingType', type);

    try {
      const response = await axios.post(
        'https://www.reg247.co.uk/api/v1/user/csv/upload-CSV',
        formData,
      );

      toast.success('Upload successful', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-success text-white',
      });

      setLoading(false);
      navigate('/uploadcsv');
      formik.resetForm();
      setCsvData([]);
      reset();
      console.log('response', response);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-danger text-white',
      });

      setLoading(false);
      console.log('error', error?.response?.data?.data);

      const errorData = error?.response?.data?.data;
      if (errorData) {
        const csv = convertToCSV(errorData);
        downloadCSV(csv, 'error_data.csv');
      }
    }

    setShowModal(false); // Close modal after API call
  };

  const handleCsvFileChange = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const file = e.target.files[0];
    console.log('file', file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target.result;
        const lines = result.split(/\r\n|\n/);
        const data = lines.map((line) => line.split(','));
        const isValid = validateCsvFormat(data);
        setIsValidFormat(isValid);
        console.log('Is valid format after change:', isValid);
        if (isValid) {
          // If new file is valid, update CSV data and field value
          setCsvData(data);
          formik.setFieldValue('csvFile', file);
        } else {
          // If new file is invalid, clear CSV data and display error message
          setCsvData([]);
          formik.setFieldValue('csvFile', null);
          toast.error(
            'Invalid CSV format. Please upload a CSV file with the correct format.',
            {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            },
          );
          reset();
        }
      };
      reader.readAsText(file);
      formik.setFieldError('csvFile', '');
    }
  };

  const validateCsvFormat = (data) => {
    // Define your CSV format validation logic here
    // Example: Check if the first row contains the expected headers
    const expectedHeaders = [
      'reg',
      'loa',
      'sales',
      'tag1',
      'tag2',
      'tag3',
      'tag4',
      'tag5',
      'tag6',
      'tag7',
      'tag8',
      'tag9',
      'tag10',
    ];
    const headers = data[0];
    return (
      headers.length === expectedHeaders.length &&
      expectedHeaders.every((header, index) => header === headers[index])
    );
  };

  return (
    <main id="main" className="main">
      {/* {/ {loading && <Processing />} /} */}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">
              <span>Upload csv</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* {/ End Page Title /} */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* {/ Bordered Tabs /} */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* {/ Profile Edit Form /} */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Selete Csv
                        </label>
                        <FormGroup>
                          <input
                            className={` form-control form-control-file ${
                              formik.touched.csvFile && formik.errors.csvFile
                                ? 'is-invalid'
                                : ''
                            }`}
                            ref={ref}
                            type="file"
                            name="file"
                            accept={'.csv'}
                            id="csvFile"
                            onChange={(e) => handleCsvFileChange(e)}
                            // onChange={(e) =>
                            //   formik.setFieldValue("csvFile", e.target.files[0])
                            // }
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formik.touched.csvFile && formik.errors.csvFile && (
                          <div className="text-danger">
                            {formik.errors.csvFile}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Customer's email
                        </label>

                        <select
                          // className="form-select"

                          className={`form-select ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          {...formik.getFieldProps('email')}>
                          <option value="">Customer's email</option>
                          {email
                            .filter((customer) => customer.active) // Filter out only active emails
                            .map((customer) => (
                              <option key={customer._id} value={customer._id}>
                                {customer.email}
                              </option>
                            ))}
                        </select>

                        {/* <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                        /> */}
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="type" className="form-label">
                          Listing Type
                        </label>
                        <div className="input-group">
                          <select
                            name="type"
                            className={`form-select ${
                              formik.touched.type && formik.errors.type
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="type"
                            // value={formik.values.role}
                            // onChange={(e) =>
                            //   formik.setFieldValue(e.target.value)
                            // }
                            {...formik.getFieldProps('type')}
                            aria-label="Select type">
                            <option value="">Select a type...</option>
                            <option value="stock">Stock</option>
                            <option value="comms">Comms</option>
                            <option value="dealer">Dealer</option>
                          </select>
                        </div>
                        {formik.touched.type && formik.errors.type && (
                          <div className="text-danger">
                            {formik.errors.type}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3 ">
                      <div className="col-md-6 d-flex   ">
                        <label htmlFor="vat" className="me-2">
                          Vat
                        </label>
                        <input
                          name="vat"
                          type="checkbox"
                          className="form-check-input"
                          id="vat"
                          checked={formik.values.vat}
                          onChange={(e) => {
                            formik.setFieldValue('vat', e.target.checked);
                          }}
                        />
                        {formik.touched.vat && formik.errors.vat && (
                          <div className="text-danger">{formik.errors.vat}</div>
                        )}
                      </div>
                      {/* <div className="col-md-4 d-flex gap-3">
                        <label htmlFor="featured" className="form-check">
                          featured
                        </label>
                        <input
                          name="featured"
                          type="checkbox"
                          className="form-check-input"
                          id="featured"
                          checked={formik.values.featured}
                          onChange={(e) => {
                            formik.setFieldValue('featured', e.target.checked);
                          }}
                        />
                        {formik.touched.featured && formik.errors.featured && (
                          <div className="text-danger">
                            {formik.errors.featured}
                          </div>
                        )}
                      </div> */}
                      {/* <div className="col-md-12 d-flex gap-3">
                        <label htmlFor="featured" className="form-check">
                          Owned
                        </label>
                        <input
                          name="owned"
                          type="checkbox"
                          className="form-check-input"
                          id="owned"
                          checked={formik.values.owned}
                          onChange={(e) => {
                            formik.setFieldValue("owned", e.target.checked);
                          }}
                        />
                        {formik.touched.owned && formik.errors.owned && (
                          <div className="text-danger">
                            {formik.errors.owned}
                          </div>
                        )}
                      </div> */}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={
                        //   !formik.dirty || !formik.isValid || !isValidFormat
                        // }

                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Upload CSV</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>

                  <hr />

                  <>
                    <hr />
                    <div className="table-responsive">
                      <table className="table text-nowrap  table-striped align-items-end table-hover">
                        <thead>
                          <tr>
                            <th>reg</th>
                            <th>loa</th>
                            <th>sales</th>
                            <th>tag1</th>
                            <th>tag2</th>
                            <th>tag3</th>
                            <th>tag4</th>
                            <th>tag5</th>
                            <th>tag6</th>
                            <th>tag7</th>
                            <th>tag8</th>
                            <th>tag9</th>
                            <th>tag10</th>
                          </tr>
                        </thead>
                        {isValidFormat && (
                          <tbody>
                            {csvData.slice(0, 11).map((row, index) =>
                              index != 0 ? (
                                <tr key={index}>
                                  {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                  ))}
                                </tr>
                              ) : null,
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </>

                  {/* <div>
                    {formik.touched.csvFile && (
                      <div className="text-danger">
                        {!isValidFormat &&
                          "Invalid CSV format. Please upload a CSV file with the correct format."}
                      </div>
                    )}
                  </div> */}
                  {/* <div className="table-responsive">
                    <h4 className="">CSV Format for Upload:</h4>
                    <table className="table text-nowrap  align-items-end table-hover">
                      <thead>
                        <tr>
                          <th>reg</th>
                          <th>loa</th>
                          <th>sales</th>
                          <th>tag1</th>
                          <th>tag2</th>
                          <th>tag3</th>
                          <th>tag4</th>
                          <th>tag5</th>
                          <th>tag6</th>
                          <th>tag7</th>
                          <th>tag8</th>
                          <th>tag9</th>
                          <th>tag10</th>
                          <th>vat</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>DDD 111</td>
                          <td>10000</td>
                          <td>10000</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>true</td>
                        </tr>
                        <tr>
                          <td>REG 111</td>
                          <td>10000</td>
                          <td>10000</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>false</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal
        show={showModal}
        onHide={handleCloseModal}
        onDone={handleUploadConfirmation} // Pass the confirmation function
        title="Upload CSV Confirmation"
        body="Are you sure you want to upload this CSV?"
      />
    </main>
  );
};

export default UploadCsv;
