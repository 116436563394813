import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { getUser } from "../../utils/constants";
const Admins = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const user = JSON.parse(getUser());

  console.log("user", user);

  useEffect(() => {
    setLoading(true);

    getAdmins();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getAdmins = () => {
    fetchDataFromAPI("user/admin", "get", "", "")
      .then((res) => {
        setAdmin(res?.data);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  // const deleteAdmin = (id) => {
  //   console.log('id', id);
  //   fetchDataFromAPI(`user/admin?id=${id}`, 'delete', '', '')
  //     .then((res) => {
  //       console.log('res delete : ', res);
  //       toast.success('Deleted successfully ✅', {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: 'bg-success text-white',
  //       });
  //       getAdmins();
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //       toast.error(error?.response?.data?.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: 'bg-danger text-white',
  //       });
  //     });
  // };

  // const deleteContent = () => {
  //   setLoading(true);
  //   fetchDataFromAPI(`user/admin?id=${deleteId}`, "delete", "", "")
  //     .then((res) => {
  //       toast.success("Deleted successfully ✅", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: "bg-success text-white",
  //       });
  //       getAdmins();
  //       setDeleteId(null);
  //       setShowDeleteModal(false);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("customer error: ", error);
  //       toast.error(error?.response?.data?.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: "bg-danger text-white",
  //       });
  //       setLoading(false);
  //     });
  // };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteAdmin = () => {
    if (user?.email === admin.find((admin) => admin._id === deleteId)?.email) {
      toast.error("You cannot delete your own admin account.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
      setShowDeleteModal(false);
      return; // Exit the function without further processing
    }

    fetchDataFromAPI(`user/admin?id=${deleteId}`, "delete", "", "")
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        setShowDeleteModal(false);

        getAdmins();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        console.log("failed to delete admin: ", err);
      });
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Admins</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          <div className="d-flex  ">
            <h3 className=" font-weight-bold ">Admins List</h3>
            <div className="col d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn btn-primary "
                onClick={() => navigate("/admins/add")}
              >
                Add Admin
              </button>
            </div>
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>

                  {/* <th scope="col">View</th> */}
                </tr>
              </thead>
              <tbody>
                {admin.map((item, index) => {
                  return (
                    <tr
                      role="button"
                      onClick={() => {
                        navigate(`/admins/edit/${item?._id}`, {
                          state: { user: item },
                        });
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item?.username}</td>
                      <td>{item?.email}</td>

                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon btn btn-sm btn-primary me-2"
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/admins/edit/${item?._id}`, {
                                state: { user: item },
                              });
                            }}
                          >
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteId(item?._id);
                            }}
                          >
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteAdmin}
        title="Delete Admin"
        body="Are you sure you want to delete this record?"
      />
    </div>
  );
};

export default Admins;
