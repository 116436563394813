import React from "react";
import { Button, Modal } from "react-bootstrap";
const PopUpModal = ({ show, onHide, title, body, onDone }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={onDone}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpModal;
