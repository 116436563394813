import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import constants, { saveUserLocally } from "../../utils/constants";
import Processing from "../../components/Processing/Processing";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email."),
      password: Yup.string()
        .matches(/^\S*$/, "Space not valid in password.")
        .required("Please enter your Password."),
    }),

    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Your login logic here using the form values
      // For example, you can authenticate the user and navigate upon successful login
      const { email, password } = values;
      console.log("email", email);
      console.log("password", password);

      setLoading(true);
      try {
        const body = {
          email: email,
          password: password,
        };

        console.log("body", body);

        fetchDataFromAPI("user/admin/login", "post", body, "")
          .then((response) => {
            toast.success("Login successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            console.log("admin login responce", response);
            saveUserLocally(JSON.stringify(response?.data));
            navigate("/");
          })
          .catch((error) => {
            toast.error(error?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            console.log("catch error:", error?.message);

            if (
              error?.response?.data &&
              typeof error?.response?.data === "string"
            ) {
              setStatus("");
              setSubmitting(false);
              setLoading(false);
              alert(error?.response?.data);
              return;
            }
            setStatus("The login details are incorrect");
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        console.log("catch error message: ", error?.message);
        setStatus("The login details are incorrect");
        toast.error(error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setSubmitting(false);
        setLoading(false);
      }
      // Perform authentication logic here...

      // For demonstration purposes, let's assume authentication is successful
      // Redirect the user to the home page
      // localStorage.setItem(constants.USER, email); // Storing user in localStorage
      // navigate('/');
    },
  });

  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER) {
      navigate("/");
    }
  }, []);

  return (
    <main>
      {loading && <Processing />}
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">REG247-ADMIN</span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your email &amp; password to login
                      </p>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Email
                        </label>
                        <input
                          placeholder="Please enter your email"
                          type="text"
                          name="email"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                          }`}
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>
                        <div className="d-flex">
                          <input
                            placeholder="Password"
                            type={showPass ? "text" : "password"}
                            name="password"
                            className={`form-control ${
                              formik.touched.password && formik.errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            id="yourPassword"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "password",
                                e.target.value?.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            required
                            style={{
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: "0", // Adjust the radius value
                              borderBottomLeftRadius: "0", // Adjust the radius value
                            }}
                            onClick={() => setShowPass(!showPass)}
                          >
                            {showPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          {!loading && (
                            <span className="indicator-label">Login</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="text-center mt-3">
                        <Link to={"/forgotpassword"}>Forgot password?</Link>
                      </div>
                      {/* <div className="col-12">
                        <p className="small mb-0">
                          Don't have account?{' '}
                          <a href="pages-register.html">Create an account</a>
                        </p>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default LoginPage;
