import React, {useState, useEffect} from 'react';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import Processing from '../../components/Processing/Processing';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Pagination} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {FormGroup} from 'react-bootstrap';
import fileDownload from 'js-file-download';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import {toast} from 'react-toastify';
import moment from 'moment';

const GetReg = () => {
  const [reg, setReg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [listingType, setListingType] = useState('all');
  console.log('listingType', listingType);
  const handleChange = (e) => {
    setListingType(e.target.value);
    setCurrentPage(1);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getReg(currentPage);
  }, [currentPage, listingType]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getCsv = () => {
    console.log('currentPage ----->', currentPage);
    setLoading(true);
    fetchDataFromAPI(
      `user/regs?downloadCSVFile=true&page=-1&listingType=${listingType}&search=${searchQuery}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        setLoading(false);
        console.log('res', res);
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
      });
  };

  const getReg = async (pageNumber) => {
    setLoading(true);
    const params = {
      page: pageNumber,
    };
    console.log('searchQuery', searchQuery);
    let searchText = searchQuery ? searchQuery : null;
    console.log('searchText', searchText);

    axios({
      url: `${base_url}user/regs?search=${searchQuery}&listingType=${listingType}`,
      params: params,
      method: 'get',
    })
      .then((response) => {
        console.log('res --->', response?.data?.totalPages);
        console.log('get reg', response);
        setReg(response?.data?.data);
        // if (searchText === 'null') {
        //   setCurrentPage(1);
        // }

        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
    // fetchDataFromAPI(`user/regs?page=2`, 'get', '', '')
    //   .then((res) => {
    //     console.log('Content res: ', res);
    //     setReg(res?.data);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log('Content error: ', error);
    //     setLoading(false);
    //   });
  };

  const formik = useFormik({
    initialValues: {
      reg: '',
    },
    validationSchema: Yup.object({
      reg: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      // getReg(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getReg(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getReg(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const formikcsv = useFormik({
    initialValues: {
      csvFile: null,
    },
    validationSchema: Yup.object({
      csvFile: Yup.object().required('Please Selete a csv'),
    }),
    onSubmit: (values) => {
      console.log('values', values);

      try {
        const formData = new FormData();
        setLoading(true);
        formData.append('csvFile', formikcsv.values.csvFile);
        fetchDataFromAPI('user/csv/upload-CSV', 'post', formData, '')
          .then((res) => {
            console.log('res', res);
          })
          .catch((error) => {
            console.log('error', error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.log('error', error);
      }
    },
  });

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/regs/delete-reg?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        console.log('res ', res);
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setSearchQuery('');
        getReg();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  function formatNumber(num) {
    // Convert the number to a string, handling different input types
    var numberString = typeof num === 'number' ? num.toString() : String(num);

    // Handle non-decimal numbers by adding ".00"
    if (!numberString.includes('.') && numberString !== '0') {
      numberString += '.00';
    }

    // Split the number into integer and decimal parts
    var parts = numberString.split('.');

    // Format the integer part with commas using Intl.NumberFormat
    var formatter = new Intl.NumberFormat('en-US');
    var formattedInteger = formatter.format(parseInt(parts[0], 10));

    // Combine the formatted integer and decimal parts
    var formattedNumber = formattedInteger + (parts[1] ? '.' + parts[1] : '');

    return formattedNumber;
  }

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Dashboard</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Reg</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <form className="mx-3 mt-3" onSubmit={formik.handleSubmit}>
                    <div className="d-flex mb-3 align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="">
                          {/* <label htmlFor="reg" className="form-label">
                            Reg :
                          </label> */}

                          <div className="text-center align-self-end ms-2 d-flex">
                            <input
                              className="me-1"
                              type="text"
                              name="query"
                              placeholder="Search"
                              title="Enter search keyword"
                              style={{
                                flex: 1,
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                              }}
                              value={searchQuery}
                              onChange={(event) =>
                                setSearchQuery(event.target.value)
                              }
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentPage(1);
                                getReg();
                              }}
                              type="submit"
                              title="Search"
                              style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '8px 15px',
                                borderRadius: '5px',
                              }}>
                              <i className="bi bi-search"></i>
                            </button>

                            <div className="col-md-6 ms-3">
                              <div className="input-group">
                                <select
                                  name="listingType"
                                  className={`form-select`}
                                  id="listingType"
                                  value={listingType}
                                  onChange={handleChange}
                                  aria-label="Select listingType">
                                  <option value="all" label="All" />
                                  <option value="stock">Stock</option>
                                  <option value="comms">Comms</option>
                                  <option value="dealer">Dealer</option>
                                  {/* <!-- Add more options as needed --> */}
                                </select>
                              </div>
                            </div>

                            <div className="d-flex gap-2 ms-5 align-items-center">
                              <div onClick={() => getCsv()}>
                                {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                                {/* <i className="bi bi-share"></i> */}
                                <img
                                  role="button"
                                  style={{height: 20, width: 25}}
                                  src={require('../../assets/images/export.png')}
                                  alt="logo"
                                />
                                {/* </CSVLink> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="m-2"> */}
                      {/* <div className="d-flex justify-content-center align-items-center align-self-end">
                        <FormGroup>
                          <input
                            className={`form-select ${
                              formikcsv.touched.csvFile &&
                              formikcsv.errors.csvFile
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="file"
                            name="file"
                            accept={'.csv'}
                            id="csvFile"
                            onChange={(e) =>
                              formikcsv.setFieldValue(
                                'csvFile',
                                e.target.files[0],
                              )
                            }
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formikcsv.touched.csvFile &&
                          formikcsv.errors.csvFile && (
                            <div className="text-danger">
                              {formikcsv.errors.csvFile}
                            </div>
                          )}
                        <div className="text-center ms-2">
                          <button
                            type="submit"
                            onClick={formikcsv.handleSubmit}
                            className="btn btn-primary ">
                            {!loading && (
                              <span className="indicator-label">Add Csv</span>
                            )}
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{display: 'block'}}>
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        {uploadStatus && <p>{uploadStatus}</p>}
                      </div> */}
                      {/* </div> */}
                    </div>
                  </form>
                  <div className="table-responsive card-body pb-0">
                    <table className="table text-nowrap  align-items-end table-hover">
                      <thead>
                        <tr className="">
                          <th scope="col">Reg</th>

                          <th scope="col">Price</th>
                          <th scope="col">VAT</th>
                          <th scope="col">Active</th>
                          <th scope="col">LOA</th>
                          <th scope="col"> Date Added</th>
                          {/* <th scope="col">Listing type</th> */}

                          {/* <th scope="col">Featured</th> */}
                          {/* <th scope="col">Active</th> */}
                          <th scope="col">Seller Name</th>
                          <th scope="col">LOA Date</th>
                          <th scope="col">Action</th>

                          {/* <th scope="col">View</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reg.map((item, index) => {
                          return (
                            <tr
                              role="button"
                              onClick={() => {
                                navigate(`/get-reg/view/${item?._id}`);
                              }}>
                              <td>{item?.reg}</td>

                              <td>
                                £
                                {formatNumber(
                                  parseFloat(item?.sales).toFixed(2),
                                )}
                              </td>

                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={item?.vat}
                                  // disabled
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={item?.active}
                                  // disabled
                                />
                              </td>
                              <td>
                                £
                                {formatNumber(parseFloat(item?.loa).toFixed(2))}
                              </td>

                              <td>
                                {item?.createdAt
                                  ? moment(item?.createdAt).format(
                                      'DD/MM/YYYY h:mm a',
                                    )
                                  : '-'}
                              </td>

                              {/* <td>{item?.listingType}</td> */}

                              {/* <td>{item?.featured ? "True" : "False"}</td> */}
                              {/* <td>{item?.active ? "True" : "False"}</td> */}

                              <td>
                                {item?.manage_customer?.firstName +
                                  ' ' +
                                  item?.manage_customer?.lastName}
                              </td>
                              <td>
                                {item?.createdAt
                                  ? moment(item?.updatedAt).format(
                                      'DD/MM/YYYY h:mm a',
                                    )
                                  : '-'}
                              </td>

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/get-reg/view/${item?._id}`);
                                    }}>
                                    <i class="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    className="edit-icon btn btn-sm btn-danger me-2"
                                    role="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteId(item?._id);
                                    }}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-5 cursor-pointer"
                    onClick={handlePrevious}
                    role="button">
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  reg.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-5 cursor-pointer"
                      onClick={handleNext}
                      role="button">
                      Next
                    </p>
                  )}
              </Pagination>
            )}

            {totalPages <= 1 && reg?.length === 0 && (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
      </section>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Reg"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default GetReg;
