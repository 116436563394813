import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";
import ReactQuill from "react-quill";
const HomeContentBulletsEdit = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [editingBulletIndex, setEditingBulletIndex] = useState(-1); // Initialize as -1 to signify no bullet is being edited initially

  const navigate = useNavigate();

  console.log("state", state);

  useEffect(() => {
    getContentData();
  }, []);

  const getContentData = () => {
    formik.setFieldValue("title", state?.user?.title);
    formik.setFieldValue("bullets", state?.user?.bullets[0]);
    formik.setFieldValue("main_title", state?.user?.main_title);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    bullets: Yup.string().notRequired(),
  });

  const handleEditorChange = (html) => {
    formik.setFieldValue("title", html);
  };

  const handleEditorChangeBullets = (html) => {
    console.log("html", html);
    //formik.setFieldValue("bullets", [...formik.values.bullets, newBullet]);
    formik.setFieldValue("bullets", html);
  };

  const formik = useFormik({
    initialValues: {
      main_title: "",
      title: "",
      bullets: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      console.log("object");

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        main_title: values?.main_title,
        bullets: values?.bullets,
        title: values?.title,
      };

      fetchDataFromAPI(
        `user/data/bullets?id=${state?.user?._id}`,
        "patch",
        body,
        ""
      )
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });

          console.log("bullet res:", res?.data);
          navigate("/home-content/bullets");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating content: ", error);
        });
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Home Content Bullet Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>Home Content Bullet Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* {/ End Page Title /} */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* {/ Bordered Tabs /} */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* {/ Profile Edit Form /} */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="col-md- mb-5">
                      <h4
                        htmlFor="main_title"
                        className="form-label text-black"
                      >
                        Main Title
                      </h4>

                      <input
                        name="main_title"
                        type="text"
                        className="form-control"
                        id="main_title"
                        style={{}}
                        value={formik.values.main_title}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "main_title",
                            e.target.value?.trimStart()
                          );
                        }}
                      />
                      {formik.touched.main_title &&
                        formik.errors.main_title && (
                          <div className="text-danger">
                            {formik.errors.main_title}
                          </div>
                        )}
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <h4 htmlFor="title" className="form-label text-black">
                          Title
                        </h4>

                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.title}
                            onChange={handleEditorChange}
                            style={{ height: 100 }}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 ">
                        <h4
                          htmlFor="lastName"
                          className="form-label text-black mt-4"
                        >
                          Bullets
                        </h4>
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.bullets}
                            onChange={handleEditorChangeBullets}
                            style={{ height: 200 }}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                        {/* <ul>
                          {state?.user?.bullets.map((bullet, index) => (
                            <li key={index}>
                              {editingBulletIndex === index ? (
                                <input
                                  className="form-control"
                                  type="text"
                                  value={formik.values.bullets[index]}
                                  onChange={(e) => {
                                    const newBullets = [
                                      ...formik.values.bullets,
                                    ];
                                    newBullets[index] = e.target.value;
                                    formik.setFieldValue("bullets", newBullets);
                                  }}
                                  onBlur={() => setEditingBulletIndex(-1)}
                                />
                              ) : (
                                <>
                                  {bullet}
                                  {"\t\t"}
                                  <span
                                    role="button"
                                    className="edit-icon ml-2 text-black fs-6"
                                    onClick={() => setEditingBulletIndex(index)}
                                  >
                                    <i className="bi bi-pen"></i>
                                  </span>
                                </>
                              )}
                            </li>
                          ))}
                        </ul> */}

                        {/* <textarea
                          name="bullets"
                          id="bullets"
                          className={`form-control ${
                            formik.touched.bullets && formik.errors.bullets
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.bullets}
                          onChange={(e) => {
                            formik.setFieldValue('bullets', e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          style={{height: 200}}
                        /> */}
                        {/* 
                        <textarea
                          name="description"
                          id="description"
                          className={`form-control ${
                            formik.touched.bullets && formik.errors.bullets
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.bullets}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'bullets',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          style={{height: 200}}
                        /> */}
                        {/* {formik.touched.bullets && formik.errors.bullets && (
                          <div className="text-danger">
                            {formik.errors.bullets}
                          </div>
                        )} */}
                      </div>
                    </div>
                    {/* <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add new bullet"
                        value={newBullet}
                        onChange={(e) => setNewBullet(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={addNewBullet}
                      >
                        Add
                      </button>
                    </div> */}
                    <div className="text-center ">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Save Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      <span className="m-2"></span>
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentBulletsEdit;
