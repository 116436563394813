import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {DataContext} from '../../context/dataContext';
import {Button, Modal} from 'react-bootstrap';
import Processing from '../../components/Processing/Processing';
const PaymentView = () => {
  const {state} = useLocation();
  console.log('state', state?.user);

  const [isBalancePaid, setIsBalancePaid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setIsBalancePaid(state?.user?.finalPaymentConfirmation);
    getComments();
  }, []);

  const onBalancePayableStatusChange = (value) => {
    fetchDataFromAPI(
      `user/payment/update-payment-details?payment_id=${state?.user?._id}`,
      'put',
      {finalPaymentConfirmation: value},
      '',
    )
      .then((res) => {
        setIsBalancePaid(value);
        toast.success('Status updated successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
      })
      .catch((e) => {
        toast.error('Failed to update status!', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  function formatNumber(num) {
    // Convert the number to a string, handling different input types
    var numberString = typeof num === 'number' ? num.toString() : String(num);

    // Handle non-decimal numbers by adding ".00"
    if (!numberString.includes('.') && numberString !== '0') {
      numberString += '.00';
    }

    // Split the number into integer and decimal parts
    var parts = numberString.split('.');

    // Format the integer part with commas using Intl.NumberFormat
    var formatter = new Intl.NumberFormat('en-US');
    var formattedInteger = formatter.format(parseInt(parts[0], 10));

    // Combine the formatted integer and decimal parts
    var formattedNumber = formattedInteger + (parts[1] ? '.' + parts[1] : '');

    return formattedNumber;
  }

  const navigate = useNavigate();
  const dataContext = useContext(DataContext);

  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)
    console.log('updatedIsCommentRead', updatedIsCommentRead);

    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };
    fetchDataFromAPI(
      `user/payment/update-payment-details?id=${state?.user?._id}`,
      'put',
      body,
      '',
    )
      .then((res) => {
        console.log('res ------->', res);
        getAcrylicCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        navigate('/payment');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const getAcrylicCount = () => {
    fetchDataFromAPI(`user/payment/get-payment-details/count`, 'get', '', '')
      .then((res) => {
        console.log('count --> ', res?.data);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setOrderCount(res?.data);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const handleCommentSubmit = () => {
    if (commentContent.trim() === '') {
      setCommentError('Please enter comment');
      return;
    }

    const body = {
      Customer_id: state?.user?._id,
      comment: commentContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        getComments();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/payment">Registration Orders</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>View Payment</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-3 label">Reg :</div>
                            <div className="col-md-3">{state?.user?.reg}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">First Name :</div>
                            <div className="col-md-3">
                              {state?.user?.firstName}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Last Name :</div>
                            <div className="col-md-3">
                              {state?.user?.lastName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">
                              Middle Initials :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.middleInitials
                                ? state?.user?.middleInitials
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Address :</div>
                            <div className="col-md-9">
                              {state?.user?.streetAddress +
                                ' ' +
                                state?.user?.town +
                                ' ' +
                                state?.user?.county +
                                ' ' +
                                state?.user?.country +
                                ',' +
                                state?.user?.postcode}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Email :</div>
                            <div className="col-md-4">{state?.user?.email}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Phone Number :</div>
                            <div className="col-md-3">
                              {state?.user?.phone ? state?.user?.phone : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Vehicle Suitability :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.vehicleSuitability ? 'Yes' : 'No'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Nominee Details :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.nomineeDetails
                                ? state?.user?.nomineeDetails
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Style Plate :</div>
                            <div className="col-md-9">
                              {state?.user?.styleNumberPlate ? (
                                <>£{state.user.styleNumberPlate}</>
                              ) : (
                                <>-</>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Rear Plate :</div>
                            <div className="col-md-9">
                              {state?.user?.rearPlate}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Payment Confirm :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.finalPaymentConfirmation
                                ? 'Yes'
                                : 'No'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Total :</div>
                            <div className="col-md-3">
                              £
                              {formatNumber(
                                parseFloat(state?.user?.total).toFixed(2),
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Deposit :</div>
                            <div className="col-md-3">
                              £{state?.user?.deposit}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Balance Payable :
                            </div>
                            <div className="col-md-3">
                              £
                              {formatNumber(
                                parseFloat(
                                  state?.user?.balance_payable,
                                ).toFixed(2),
                              )}
                              {/* {state?.user?.balance_payable?.toFixed(2)} */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Confirm balance paid :
                            </div>
                            <div className="col-md-3">
                              <input
                                type="checkbox"
                                checked={isBalancePaid}
                                onChange={(e) =>
                                  onBalancePayableStatusChange(e.target.checked)
                                }
                                className="form-check"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Description :</div>
                            <div className="col-md-3 ">
                              <span className="">
                                {state?.user?.description}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="row">
                              <div className="col-md-3 label">
                                Shipping Name :
                              </div>
                              <div className="col-md-3">
                                {state?.user?.shipping?.name}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3 label">
                                Shipping Address:
                              </div>
                              <div className="col-md-9">
                                {state?.user?.shipping?.address?.line1 +
                                  ' ' +
                                  state?.user?.shipping?.address?.city +
                                  ' ' +
                                  state?.user?.shipping?.address?.state +
                                  ' ' +
                                  state?.user?.shipping?.address?.country +
                                  ' ' +
                                  ',' +
                                  ' ' +
                                  state?.user?.shipping?.address?.postal_code}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container mb-5"
                        style={{maxHeight: '400px'}}>
                        <div className="row justify-content-center mt-4">
                          <div className="col-lg-8">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title mb-3">Comments</h4>
                                <hr className="my-1" />
                                <div
                                  style={{
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                  }}>
                                  {comments?.length > 0 ? (
                                    comments.map((comment, index) => (
                                      <div key={index} className="card mb-3">
                                        <div className="card-body">
                                          <p className="card-text">
                                            {comment?.msg}
                                          </p>
                                          <p className="text-end text-muted small">
                                            {new Date(
                                              comment?.timestamp,
                                            ).toLocaleString()}
                                          </p>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <p className="text-muted">
                                      No comments yet
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="text-center mx-3">
                          <button
                            onClick={() => funcationRead()}
                            type="submit"
                            // disabled={
                            //   state?.user?.is_read === true ? true : false
                            // } // Disable if isCommentRead is false
                            className="btn btn-primary"
                            // disabled={formik.isSubmitting || !formik.isValid}
                          >
                            <i class="bi bi-eye-fill me-2"></i>
                            {state?.user?.is_read === true
                              ? 'Mark as Unread'
                              : 'Mark as Read'}
                          </button>
                        </div>
                        <div className="text-center mx-3">
                          <button
                            onClick={() => showComment()}
                            type="submit"
                            className="btn btn-info"
                            // disabled={formik.isSubmitting || !formik.isValid}
                          >
                            <i class="bi bi-plus-circle-fill me-2"></i>
                            Add a comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value);
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default PaymentView;
