import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import constants from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import {DataContext} from '../../context/dataContext';
const Sidebar = () => {
  const routes = [
    {title: 'Dashboard', path: '/'},
    {
      title: 'Home',
      content: [
        {title: 'Home Content', path: '/home-content'},
        {title: 'Home Content Bullets', path: '/home-content/bullets'},
        {title: 'Home Content Banner', path: '/homecontentbanner'},
        {title: 'Home Content FAQ', path: '/home-content/faq'},
        // { title: "Number Plate FAQ", path: "/number-plate/faq" },
      ],
    },
    // {title: 'Home Content', path: '/home-content'},
    // {title: 'Home Content Bullets', path: '/home-content/bullets'},
    // {title: 'Home Content FAQ', path: '/home-content/faq'},
    {title: 'Blog', path: '/blog'},
    {title: 'Manage Customer', path: '/customer'},
    {title: 'Upload Csv', path: '/uploadcsv'},
    {title: 'Valuation Requests', path: '/salesplates/getplates'},

    // ! Hide
    {title: 'Sell Requests', path: '/sell-requests'},
    // ! Hide
    {title: 'Offers', path: '/offers'},

    {title: 'Enquiry', path: '/enquiry'},
    // { title: "Get Enquiry", path: "/getenquiry" },
    {title: 'Registrations', path: '/get-reg'},
    {title: 'Acrylic Orders', path: '/acrylicplate'},
    {title: 'Registration Orders', path: '/payment'},
    // {title: 'Coupons', path: '/coupons'},
    // { title: "Enquiry", path: "/enquiry" },
    {title: 'Admins', path: '/admins'},
    {title: 'Email Subscribers', path: '/email-subscribers'},
    {title: 'Contact Forms', path: '/contact-info'},
    // { title: "Sell Forms", path: "/sell-forms" },
    {title: 'Plate Viewer Forms', path: '/plate-viewer-forms'},
  ];
  const navigate = useNavigate();
  const dataContext = useContext(DataContext);

  // console.log("dataContext?.commetCount", dataContext?.commetCount);

  const logout = async () => {
    localStorage.removeItem(constants.USER);
    navigate('/login');
    window.location.reload();
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [enguiryCount, setEnguiryCount] = useState(0);
  const [offerCount, setofferCount] = useState(0);
  const [sellCount, setSellCount] = useState(0);
  const [contactCount, setContactCount] = useState(0);
  const [plateCount, setPlateCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [acrylicCount, setAcrylicCount] = useState(0);
  const [showDataContextCount, setShowDataContextCount] = useState(false);

  useEffect(() => {
    getComments(); // Initial call
    getEnquiry();
    getOfferCount();
    getSells();
    getContact();
    getPlate();
    getOrder();
    getAcrylic();
  }, [dataContext]);

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-valutions?pending_msg=false`,
      'get',
      '',
      '',
    )
      .then((res) => {
        // console.log("count --> ", res?.pendingMsg);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getEnquiry = () => {
    fetchDataFromAPI('user/inquiry/?pending_msg=false', 'get', '', '')
      .then((res) => {
        // console.log("enguiry --> ", res?.pendingMsg);
        setEnguiryCount(res?.pendingMsg);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getOfferCount = () => {
    fetchDataFromAPI(`user/userOffer/count`, 'get', '', '')
      .then((res) => {
        // console.log("res offer -->", res);
        setofferCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getSells = () => {
    fetchDataFromAPI(`user/sells/count`, 'get', '', '')
      .then((res) => {
        // console.log("res sell-->", res);
        setSellCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getContact = () => {
    fetchDataFromAPI(`user/contactUs/count?type=1`, 'get', '', '')
      .then((res) => {
        // console.log("res ------>  --->", res?.data);
        setContactCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const getPlate = () => {
    fetchDataFromAPI(`user/contactUs/count?type=2`, 'get', '', '')
      .then((res) => {
        setPlateCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getAcrylic = () => {
    fetchDataFromAPI(
      `user/acrylicPlates/get-acryli-plates/count`,
      'get',
      '',
      '',
    )
      .then((res) => {
        setAcrylicCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getOrder = () => {
    fetchDataFromAPI('user/payment/get-payment-details/count', 'get', '', '')
      .then((res) => {
        // console.log("res --<>>>>>", res);
        setOrderCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (
      dataContext?.commentCount === undefined ||
      dataContext.enquiryCount === undefined ||
      dataContext.offerCount === undefined ||
      dataContext.sellCount === undefined ||
      dataContext.contactCount === undefined ||
      dataContext.plateCount === undefined ||
      dataContext.orderCount === undefined ||
      dataContext.acrylicCount === undefined ||
      dataContext.acrylicCount === true ||
      dataContext.orderCount === true ||
      dataContext.plateCount === true ||
      dataContext.contactCount === true ||
      dataContext.sellCount === true ||
      dataContext.offerCount === true ||
      dataContext.enquiryCount === true ||
      dataContext?.commentCount === true
    ) {
      getComments();
      getEnquiry();
      getOfferCount();
      getSells();
      getContact();
      getPlate();
      getPlate();
      getAcrylic();
      setShowDataContextCount(false); // Hide dataContext count if it's not available
    } else {
      setShowDataContextCount(true); // Show dataContext count if it's available
    }
  }, [
    dataContext?.commentCount,
    dataContext.enquiryCount,
    dataContext.offerCount,
    dataContext.sellCount,
    dataContext.contactCount,
    dataContext.plateCount,
    dataContext.orderCount,
    dataContext.acrylicCount,
  ]);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {routes?.map((val, index) => {
          return val?.content ? (
            <li className="nav-item">
              <Link
                className={isOpened ? 'nav-link' : 'nav-link collapsed'}
                data-bs-target="#components-nav"
                data-bs-toggle="collapse"
                onClick={() => setIsOpened(!isOpened)}>
                <i className="bi bi-menu-button-wide"></i>
                <span>{val?.title}</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </Link>
              <ul
                id="components-nav"
                className="nav-content collapse text-decoration-none"
                data-bs-parent="#sidebar-nav">
                {val?.content?.map((item, index) => (
                  <li>
                    <Link to={item?.path}>
                      <i className="bi bi-circle"></i>
                      <span>{item?.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className={
                  selectedIndex === index ? 'nav-link' : 'nav-link collapsed'
                }
                to={val.path}
                onClick={() => setSelectedIndex(index)}>
                {/* <i className="bi bi-grid"></i> */}
                {val?.title === 'Valuation Requests' ? (
                  <i class="bi bi-receipt"></i>
                ) : val?.title === 'Admins' ? (
                  <i class="bi bi-people"></i>
                ) : val?.title === 'Dashboard' ? (
                  <i class="bi bi-house"></i>
                ) : val?.title === 'Registrations' ? (
                  <i class="bi bi-list-ol"></i>
                ) : val?.title === 'Upload Csv' ? (
                  <i class="bi bi-filetype-csv"></i>
                ) : val?.title === 'Manage Customer' ? (
                  <i class="bi bi-person-gear"></i>
                ) : val?.title === 'Get Enquiry' ? (
                  <i class="bi bi-chat-left-dots"></i>
                ) : val?.title === 'Coupons' ? (
                  <i class="bi bi-percent"></i>
                ) : val?.title === 'Enquiry' ? (
                  <i class="bi bi-question-circle"></i>
                ) : val?.title === 'Acrylic Orders' ? (
                  <i class="bi bi-card-text"></i>
                ) : val?.title === 'Offers' ? (
                  <i class="bi bi-gift-fill"></i>
                ) : val?.title === 'Registration Orders' ? (
                  <i class="bi bi-credit-card-2-front-fill"></i>
                ) : val?.title === 'Blog' ? (
                  <i class="bi bi-substack"></i>
                ) : val?.title === 'Contact Forms' ? (
                  <i class="bi bi-person-rolodex"></i>
                ) : val?.title === 'Sell Requests' ? (
                  <i class="bi bi-envelope-paper"></i>
                ) : val?.title === 'Sell Forms' ? (
                  <i class="bi bi-person-rolodex"></i>
                ) : val?.title === 'Plate Viewer Forms' ? (
                  <i class="bi bi-person-rolodex"></i>
                ) : val?.title === 'Email Subscribers' ? (
                  <i class="bi bi-envelope"></i>
                ) : null}
                <span>{val?.title}</span>

                {/* {val?.title === "Valuation Requests" && commentsCount > 0 && (
                  <span className="badge bg-danger ms-4">{commentsCount}</span>
                )} */}
                {/* Display dataContext?.commentCount here */}
                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {/* Display dataContext?.commentCount if available */}
                {showDataContextCount &&
                  val?.title === 'Valuation Requests' && (
                    <span className="badge bg-info ms-2">
                      {dataContext?.commentCount}
                    </span>
                  )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount &&
                  val?.title === 'Valuation Requests' && (
                    <span className="badge bg-danger ms-4">
                      {commentsCount}
                    </span>
                  )}

                {showDataContextCount && val?.title === 'Enquiry' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.enquiryCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Enquiry' && (
                  <span className="badge bg-danger ms-4">{enguiryCount}</span>
                )}

                {showDataContextCount && val?.title === 'Offers' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.offerCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Offers' && (
                  <span className="badge bg-danger ms-4">{offerCount}</span>
                )}

                {showDataContextCount && val?.title === 'Sell Requests' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.sellCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Sell Requests' && (
                  <span className="badge bg-danger ms-4">{sellCount}</span>
                )}

                {showDataContextCount && val?.title === 'Sell Forms' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.sellCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Sell Forms' && (
                  <span className="badge bg-danger ms-4">{sellCount}</span>
                )}

                {showDataContextCount && val?.title === 'Contact Forms' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.contactCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Contact Forms' && (
                  <span className="badge bg-danger ms-4">{contactCount}</span>
                )}

                {showDataContextCount &&
                  val?.title === 'Plate Viewer Forms' && (
                    <span className="badge bg-info ms-2">
                      {dataContext?.plateCount}
                    </span>
                  )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount &&
                  val?.title === 'Plate Viewer Forms' && (
                    <span className="badge bg-danger ms-4">{plateCount}</span>
                  )}

                {showDataContextCount &&
                  val?.title === 'Registration Orders' && (
                    <span className="badge bg-info ms-2">
                      {dataContext?.orderCount}
                    </span>
                  )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount &&
                  val?.title === 'Registration Orders' && (
                    <span className="badge bg-danger ms-4">{orderCount}</span>
                  )}

                {showDataContextCount && val?.title === 'Acrylic Orders' && (
                  <span className="badge bg-info ms-2">
                    {dataContext?.acrylicCount}
                  </span>
                )}

                {/* Display commentsCount if dataContext?.commentCount is not available */}
                {!showDataContextCount && val?.title === 'Acrylic Orders' && (
                  <span className="badge bg-danger ms-4">{acrylicCount}</span>
                )}
              </Link>
            </li>
          );
        })}

        {/* <li className="nav-item">
          <Link className="nav-link " to="index.html">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link className="nav-link collapsed" to="/home-content">
            <i className="bi bi-grid"></i>
            <span>Home Content</span>
          </Link>
        </li> */}

        {/* <li className="nav-item">
          <Link className="nav-link collapsed" to="/home-content/bullets">
            <i className="bi bi-grid"></i>
            <span>Home Content Bullets</span>
          </Link>
        </li> */}

        {/* <li className="nav-item">
          <Link className="nav-link collapsed" to="/home-content/faq">
            <i className="bi bi-grid"></i>
            <span>Home Content FAQ</span>
          </Link>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link collapsed" onClick={logout} to={'/login'}>
            <i class="bi bi-box-arrow-left"></i>
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
