import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../components/Processing/Processing';

const HomeContentBullets = () => {
  const [contentBullets, setContentBullets] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getContentBullets();
  }, []);

  const getContentBullets = () => {
    setLoading(true);
    fetchDataFromAPI(`user/data/bullets`, 'get', '', '')
      .then((res) => {
        console.log(' res: ', res);
        setContentBullets(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        setLoading(false);
      });
  };

  const deleteContent = (id) => {
    fetchDataFromAPI(`user/data/bullets?id=${id}`, 'delete', '', '')
      .then((res) => {
        console.log(' res: ', res);
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getContentBullets();
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* {/ <h1>Dashboard</h1> /} */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Home Content Bullets</li>
          </ol>
        </nav>
      </div>
      {/* {/ End Page Title /} */}
      <section className="section dashboard">
        <div className="row">
          {/* {/ Left side columns /} */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <div className="col d-flex justify-content-end mt-2  me-2">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      onClick={() => navigate('/home-content/bullets/add')}>
                      Add Bullets
                    </button>
                  </div>
                  <div className="card-body pb-0">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">No</th>

                          <th scope="col">Main Title</th>
                          <th scope="col">Title</th>

                          {/* {/ <th scope="col">Description</th> /} */}

                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {contentBullets.map((item, index) => {
                        let title = item?.title;

                        if (title && title.length > 100) {
                          title = title.slice(0, 100) + '...';
                        }
                        return (
                          <tbody>
                            <tr>
                              <td scope="row">
                                <span>{index + 1}</span>
                                {/* <a href="#">
                                  <img
                                    src="assets/img/product-1.jpg"
                                    alt=""
                                  />
                                </a> */}
                              </td>

                              <td className="col-md-2">{item?.main_title}</td>
                              <td>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: title,
                                  }}
                                />
                              </td>
                               {/* <td><span>{item?.bullets}</span></td>  */}
                              <td className="text-center">
                                <div className="d-flex justify-content-center ">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() =>
                                      navigate(
                                        `/home-content/bullets/edit/${item?._id}`,
                                        {
                                          state: {user: item},
                                        },
                                      )
                                    }>
                                    <i class="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={() => deleteContent(item?._id)}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentBullets;