import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const EnquiryView = () => {
  const {state} = useLocation();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>View Enquiry</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-3 label">First Name :</div>
                            <div className="col-md-3">{state?.user?.first_name ? state?.user?.first_name : "-"}</div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">Middle Initials :</div>
                            <div className="col-md-3">
                              {state?.user?.middle_initials ? state?.user?.middle_initials : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Last Name :</div>
                            <div className="col-md-3">
                              {state?.user?.last_name ? state?.user?.last_name : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Email :</div>
                            <div className="col-md-3">
                              {state?.user?.email ? state?.user?.email : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Phone Number :</div>
                            <div className="col-md-3">
                              {state?.user?.phone_number ? state?.user?.phone_number : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Is Subscribe :</div>
                            <div className="col-md-3">
                              {state?.user?.is_subscribe? "Yes" : "No"}
                            </div>
                          </div> <div className="row">
                            <div className="col-md-3 label">Message :</div>
                            <div className="col-md-3">
                              {state?.user?.message ? state?.user?.message : "-"}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EnquiryView;
