import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import routes from "./routes/routes";
import Header from "./components/header/header";
import Sidebar from "./components/sidebar/sidebar";
import Error from "./pages/Error/Error";
import Login from "./pages/LoginPage/LoginPage";
import FormNewPass from "./pages/FormNewPass/FormNewPass";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

const App = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== "/login" &&
        pathname !== "/forgotpassword" &&
        pathname !== "/reset-password" &&
        pathname !== "/404" && <Header />}

      {pathname !== "/login" &&
        pathname !== "/forgotpassword" &&
        pathname !== "/reset-password" &&
        pathname !== "/404" && <Sidebar />}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {routes.map(({ path, element, title }, index) => (
          <Route key={index} path={path} element={element} />
        ))}

        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

export default App;
