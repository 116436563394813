import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {toast} from 'react-toastify';
import {Button, Modal} from 'react-bootstrap';
import {fetchDataFromAPI} from '../../utils/api';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import {DataContext} from '../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';

const ViewSellRequests = () => {
  const {state} = useLocation();
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  console.log('state-->', state?.user);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showRespondModal, setShowRespondModal] = useState(false);

  const defaultResponseContent = `Many thanks for the valuation request for ${state?.user?.registrationNumber}.\n\nWe have valued the registration mark at {Enters price here}.\n\nOur valuation price is based on what we can realistically return to you in the current market.\n\nIf you choose to advertise you plate for a higher price, this is fine but please note it is likely to increase the sales time. Likewise, if you choose to reduce the sales price, this could help generate a quicker sale.\n\nIf you'd like to discuss with one of our experts, please call us on 01582 347734. Or, if you'd like to go ahead, please reply 'yes' to this email and we'll get started right away.\n\nKind regards,\nREG247.`;
  const [responseContent, setResponseContent] = useState(
    defaultResponseContent,
  );
  const [responseError, setResponseError] = useState('');

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState('');

  const [isListPriceModal, setiSListPriceModal] = useState(false);
  const [isAdminPrice, setAdminPrice] = useState('');
  const [adminVAT, setAdminVAT] = useState(false);

  const [commentError, setCommentError] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const [comments, setComments] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dataContext = useContext(DataContext);

  useEffect(() => {
    getSellData();
  }, []);

  useEffect(() => {
    setAdminVAT(state?.user?.is_vat);
  }, []);

  const getSellData = () => {
    setLoading(true);
    formik.setFieldValue('registrationNumber', state?.user?.registrationNumber);
    formik.setFieldValue('price', state?.user?.price);
    formik.setFieldValue('keywords', state?.user?.keywords);
    formik.setFieldValue('firstName', state?.user?.firstName);
    formik.setFieldValue('lastName', state?.user?.lastName);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('phone', state?.user?.phone);
    formik.setFieldValue('streetAddress', state?.user?.streetAddress);
    formik.setFieldValue('town', state?.user?.town);
    formik.setFieldValue('country', state?.user?.country);
    formik.setFieldValue('postcode', state?.user?.postcode);
    formik.setFieldValue('is_vat', state?.user?.is_vat);
    formik.setFieldValue('contact_permission', state?.user?.contact_permission);
    formik.setFieldValue(
      'checkboxGroup',
      state?.user?.plateHoldingStatus || '',
    );

    formik.setFieldValue('confirmNumPlat', state?.user?.confirmNumPlat);
    formik.setFieldValue('contact_permission', state?.user?.contact_permission);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required('Please enter reg'),
    price: Yup.string().required('Please enter plate price'),
    keywords: Yup.string().notRequired(),
    firstName: Yup.string().required('Please enter first name'),
    lastName: Yup.string().required('Please enter last name'),
    email: Yup.string()
      .email('Please enter a valid email')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        'Email must end with .com',
      )
      .required('Please enter an email'),

    phone: Yup.string()

      .transform((value) => (value ? value.replace(/\s/g, '') : value)) // Remove spaces
      // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),

    streetAddress: Yup.string().required('Please enter street address'),
    town: Yup.string().required('Please enter town'),
    country: Yup.string().required('Please enter country'),
    postcode: Yup.string().required('Please enter postcode'),
    is_vat: Yup.string().notRequired(),

    checkboxGroup: Yup.string().test(
      'at-least-one-selected',
      'Please select at least one option',
      (value) => {
        return !!value;
      },
    ),
  });

  const formik = useFormik({
    initialValues: {
      registrationNumber: '',
      price: '',
      keywords: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      town: '',
      country: '',
      postcode: '',
      confirmNumPlat: '',
      contact_permission: '',
      plateHoldingStatus: '',
      checkboxGroup: '',
      is_vat: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      setLoading(true);

      const body = {
        registrationNumber: values?.registrationNumber,
        firstName: values?.firstName,
        keywords: values?.keywords,
        price: values?.price,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        streetAddress: values?.streetAddress,
        country: values?.country,
        town: values?.town,
        postcode: values?.postcode,
        contactPermission: values?.contact_permission ? true : false,
        confirmNumPlat: values?.confirmNumPlat,
        plateHoldingStatus: values?.checkboxGroup,
        is_vat: values?.is_vat ? true : false,
      };

      console.log('body', body);

      fetchDataFromAPI(`user/sells?id=${state?.user?._id}`, 'patch', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('update member res:', res?.data);
          navigate('/sell-requests');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating: ', error);
        });
    },
  });

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/sells?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
        navigate('/sell-requests');
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    if (commentContent.trim() === '') {
      setCommentError('Please enter comment');
      return;
    }

    const body = {
      Customer_id: state?.user?._id,
      comment: commentContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        getComments();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const handleResponseSubmit = () => {
    if (responseContent.trim() === '') {
      setResponseError('Response cannot be empty');
      return;
    }

    const body = {
      email: state?.user?.email,
      message: responseContent,
      reg: state?.user?.registrationNumber,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/respond-to-enquery', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        toast.success(res?.message + ' ✅ ', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setShowRespondModal(false);
        setResponseContent('');
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setResponseContent('');
        setShowRespondModal(false);
      });

    // Your logic to handle the response submission
  };

  const showModal = () => {
    // setResponseContent("");
    setResponseError('');
    setShowRespondModal(true);
  };

  const showList = () => {
    // isAdminPrice("");
    setiSListPriceModal(true);
  };

  const showComment = () => {
    setCommentContent('');
    setCommentError('');
    setShowCommentModal(true);
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/valutions/get-comment?valution_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // funcation for read
  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)

    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };
    // const body = {
    //   is_read: "true",
    // };
    fetchDataFromAPI(`user/sells?id=${state?.user?._id}`, 'patch', body, '')
      .then((res) => {
        console.log('res ------->', res);

        getSellCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setLoading(false);
        navigate('/sell-requests');
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
      });
  };

  const getSellCount = () => {
    fetchDataFromAPI(`user/sells/count`, 'get', '', '')
      .then((res) => {
        console.log('sells --> ', res?.data);

        dataContext?.setSellCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCheckboxChange = (id) => {
    setSelectedCheckbox(id);
    formik.setFieldValue('checkboxGroup', id);
  };

  const listValuation = () => {
    const body = {
      user_price: state?.user?.price,
      admin_price: isAdminPrice ? isAdminPrice : state?.user?.price,
      is_vat: adminVAT,
      email: state?.user?.email,
      reg: state?.user?.registrationNumber,
      user_name: state?.user?.firstName + ' ' + state?.user?.lastName,
    };

    setLoading(true);
    console.log('body', body);
    fetchDataFromAPI(
      `user/sells?id=${state?.user?._id}&isEmailSend=true`,
      'put',
      body,
      '',
    )
      .then((res) => {
        console.log('res email ----> ', res);
        setiSListPriceModal(false);
        setLoading(false);
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setAdminPrice('');
        navigate('/sell-requests');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setiSListPriceModal(false);
        setAdminPrice('');
      });
  };

  let nonSpaceEntered = false;
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/sell-requests">Sell Requests</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="registrationNumber"
                              className="form-label">
                              Reg
                            </label>
                            <input
                              name="registrationNumber"
                              type="text"
                              className="form-control"
                              id="registrationNumber"
                              value={formik.values.registrationNumber}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'registrationNumber',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.registrationNumber &&
                              formik.errors.registrationNumber && (
                                <div className="text-danger">
                                  {formik.errors.registrationNumber}
                                </div>
                              )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="plateHoldingStatus"
                              className="form-label">
                              Price
                            </label>
                            <input
                              name="price"
                              type="text"
                              className="form-control"
                              id="price"
                              value={formik.values.price}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'price',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.price && formik.errors.price && (
                              <div className="text-danger">
                                {formik.errors.price}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="keywords" className="form-label">
                            keywords
                          </label>
                          <input
                            name="keywords"
                            type="text"
                            className="form-control"
                            id="keywords"
                            value={formik.values.keywords}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'keywords',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.keywords &&
                            formik.errors.keywords && (
                              <div className="text-danger">
                                {formik.errors.keywords}
                              </div>
                            )}
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              name="firstName"
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={formik?.values?.firstName}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'firstName',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <div className="text-danger">
                                  {formik.errors.firstName}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="lastName" className="form-label">
                              Last Name
                            </label>
                            <input
                              name="lastName"
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={formik.values.lastName}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'lastName',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.lastName &&
                              formik.errors.lastName && (
                                <div className="text-danger">
                                  {formik.errors.lastName}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label
                              htmlFor="SPSVLicenceNumber"
                              className="form-label">
                              Email
                            </label>
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              id="email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'email',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="AgreementVersion"
                              className="form-label">
                              Phone
                            </label>
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              id="phone"
                              value={formik?.values?.phone}
                              onChange={(e) => {
                                let input = e.target.value;
                                const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                if (!nonSpaceEntered) {
                                  // If no non-space character entered yet, trim spaces
                                  input = input.trimStart();
                                }
                                if (regex.test(input) || input === '') {
                                  formik.setFieldValue('phone', input);
                                  // Check if a non-space character has been entered
                                  if (input !== '' && !nonSpaceEntered) {
                                    nonSpaceEntered = true;
                                  }
                                }
                              }}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <div className="text-danger">
                                {formik.errors.phone}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="streetAddress"
                              className="form-label">
                              Street Address
                            </label>
                            <input
                              name="streetAddress"
                              type="text"
                              className="form-control"
                              id="streetAddress"
                              value={formik?.values?.streetAddress}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'streetAddress',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.streetAddress &&
                              formik.errors.streetAddress && (
                                <div className="text-danger">
                                  {formik.errors.streetAddress}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <label htmlFor="town" className="form-label">
                              Town
                            </label>
                            <input
                              name="town"
                              type="text"
                              className="form-control"
                              id="town"
                              value={formik?.values?.town}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'town',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.town && formik.errors.town && (
                              <div className="text-danger">
                                {formik.errors.town}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="country" className="form-label">
                              Country
                            </label>
                            <input
                              name="country"
                              type="text"
                              className="form-control"
                              id="country"
                              value={formik?.values?.country}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'country',
                                  e.target.value?.trimStart(),
                                );
                              }}

                              // Adjust the format as needed
                            />
                            {formik.touched.country &&
                              formik.errors.country && (
                                <div className="text-danger">
                                  {formik.errors.country}
                                </div>
                              )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="postcode" className="form-label">
                              Postcode
                            </label>
                            <input
                              name="postcode"
                              type="text"
                              className="form-control"
                              id="postcode"
                              value={formik.values.postcode}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'postcode',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.postcode &&
                              formik.errors.postcode && (
                                <div className="text-danger">
                                  {formik.errors.postcode}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-between my-2">
                          <div className="form-check">
                            <input
                              name="confirmNumPlat"
                              type="checkbox"
                              className="form-check-input"
                              id="confirmNumPlat"
                              checked={formik.values.confirmNumPlat}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'confirmNumPlat',
                                  e.target.checked,
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="confirmNumPlat">
                              Confirm number plate
                            </label>
                            {formik.touched.confirmNumPlat &&
                              formik.errors.confirmNumPlat && (
                                <div className="text-danger">
                                  {formik.errors.confirmNumPlat}
                                </div>
                              )}
                          </div>
                          <div className="form-check">
                            <input
                              name="contact_permission"
                              type="checkbox"
                              className="form-check-input"
                              id="contact_permission"
                              checked={formik.values.contact_permission}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'contact_permission',
                                  e.target.checked,
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="contact_permission">
                              Subscribed
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              name="is_vat"
                              type="checkbox"
                              className="form-check-input"
                              id="is_vat"
                              checked={formik.values.is_vat}
                              onChange={(e) => {
                                setAdminVAT(e.target.checked);

                                formik.setFieldValue(
                                  'is_vat',
                                  e.target.checked,
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="is_vat">
                              VAT registration
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-15 d-flex flex-wrap ">
                          <div className="form-check mb-8 col-6 col-xxl-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="certificate"
                              name="listdata"
                              // value={
                              //   formik.values.checkboxGroup === "retention"
                              // }
                              checked={
                                formik.values.checkboxGroup === 'retention'
                              }
                              onChange={() => handleCheckboxChange('retention')}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="certificate">
                              retention
                            </label>
                          </div>
                          <div className="form-check mb-8 col-6 col-xxl-7">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="held"
                              name="listdata"
                              checked={
                                formik.values.checkboxGroup === 'assigned'
                              }
                              onChange={() => handleCheckboxChange('assigned')}
                            />
                            <label className="form-check-label" htmlFor="held">
                              assigned
                            </label>
                          </div>
                          {/* <div className="form-check mb-8 col-12">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="selling"
                              name="listdata"
                              checked={selectedCheckbox === "selling"}
                              onChange={() => handleCheckboxChange("selling")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="selling"
                            >
                              I am selling this number plate through a VAT
                              registered company
                            </label>
                          </div> */}
                          {formik.touched.checkboxGroup &&
                            formik.errors.checkboxGroup && (
                              <div className="text-danger">
                                {formik.errors.checkboxGroup}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div
                      className="container mb-5"
                      style={{maxHeight: '400px'}}>
                      <div className="row justify-content-center mt-4">
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title mb-3">Comments</h4>
                              <hr className="my-1" />
                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'auto',
                                }}>
                                {comments?.length > 0 ? (
                                  comments.map((comment, index) => (
                                    <div key={index} className="card mb-3">
                                      <div className="card-body">
                                        <p className="card-text">
                                          {comment?.msg}
                                        </p>
                                        <p className="text-end text-muted small">
                                          {new Date(
                                            comment?.timestamp,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-muted">No comments yet</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          onClick={() => funcationRead()}
                          type="submit"
                          // disabled={
                          //   state?.user?.is_read === true ? true : false
                          // } // Disable if isCommentRead is false
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>

                          {state?.user?.is_read === true
                            ? 'Mark as Unread'
                            : 'Mark as Read'}
                          {/* Mark as read */}
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showModal()}
                          type="submit"
                          className="btn btn-secondary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-chat-dots-fill me-2"></i>
                          Respond to this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showList()}
                          type="button"
                          className="btn btn-success"
                          disabled={state?.user?.is_listed ? true : false}>
                          <i class="bi bi-check2"></i> {''}List this sell
                          request
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => setDeleteId(state?.user?._id)}
                          type="submit"
                          className="btn btn-danger"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-trash-fill me-2"></i>
                          Delete this sell request
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showComment()}
                          type="submit"
                          className="btn btn-info"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-plus-circle-fill me-2"></i>
                          Add a comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Valuation Requests"
          body="Are you sure you want to delete this record?"
        />
      </section>

      {/* Input model */}
      <Modal
        show={isListPriceModal}
        centered
        onHide={() => setiSListPriceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>List This Sell Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <label>User Price</label>
            <input
              name="username"
              type="text"
              className="form-control"
              id="username"
              value={state?.user?.price}
            />
            {/* <label className="mt-2">Admin Price</label> */}
            <label className="mt-2">Sales Price</label>
            <input
              name="username"
              min={0}
              type="number"
              className="form-control "
              id="username"
              value={isAdminPrice}
              onChange={(e) => {
                let value = e.target.value.trimStart();
                // Check if the input is a valid positive number without double minus sign
                if (
                  /^\d*\.?\d*$/.test(value) &&
                  !/--/.test(value) &&
                  parseFloat(value) >= 0
                ) {
                  // Allow valid positive numbers or an empty string (to allow clearing the input)
                  setAdminPrice(value);
                }
              }}
            />
            <div className="form-check m-2">
              <input
                name="is_vat"
                type="checkbox"
                className="form-check-input"
                // id="is_vat"
                checked={adminVAT}
                onChange={(e) => {
                  setAdminVAT(e.target.checked);
                }}
              />
              <label
                className="form-check-label"
                // htmlFor="is_vat"
              >
                VAT registration
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setiSListPriceModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={listValuation}>
            {!loading && <span className="indicator-label">Send</span>}
            {loading && (
              <span className="indicator-progress" style={{display: 'block'}}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value);
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for email send */}
      <Modal
        show={showRespondModal}
        centered
        onHide={() => setShowRespondModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Respond to valuation : {state?.user?.registrationNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${responseError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={responseContent}
              onChange={(e) => {
                setResponseContent(e.target.value);
                setResponseError('');
              }}
              style={{height: 200}}
            />
            {responseError && (
              <div className="invalid-feedback">{responseError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowRespondModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResponseSubmit}>
            Respond via email
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default ViewSellRequests;
