import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import Processing from "../../components/Processing/Processing";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import moment from "moment";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [isValuation, setValuation] = useState([]);
  const [isSell, setSell] = useState([]);
  const [isOffer, setOffer] = useState([]);
  const [isAcrylic, setAcrylic] = useState([]);
  const [isPayment, setPayment] = useState([]);
  const [isContact, setContact] = useState([]);
  const [isPlateViewer, setPlateViewer] = useState([]);

  function formatNumber(num) {
    // Convert the number to a string, handling different input types
    var numberString = typeof num === "number" ? num.toString() : String(num);

    // Handle non-decimal numbers by adding ".00"
    if (!numberString.includes(".") && numberString !== "0") {
      numberString += ".00";
    }

    // Split the number into integer and decimal parts
    var parts = numberString.split(".");

    // Format the integer part with commas using Intl.NumberFormat
    var formatter = new Intl.NumberFormat("en-US");
    var formattedInteger = formatter.format(parseInt(parts[0], 10));

    // Combine the formatted integer and decimal parts
    var formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "");

    return formattedNumber;
  }

  useEffect(() => {
    valuation();
    sell();
    offer();
    acrylic();
    registration();
    contact();
    plateViewer();
  }, []);

  const valuation = () => {
    fetchDataFromAPI(
      "user/valutions/get-valutions?isDeleted=false",
      "get",
      "",
      ""
    )
      .then((res) => {
        console.log("res", res);
        setValuation(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sell = () => {
    fetchDataFromAPI("user/sells", "get", "", "")
      .then((res) => {
        console.log("res", res);
        setSell(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const offer = () => {
    fetchDataFromAPI("user/userOffer", "get", "", "")
      .then((res) => {
        setOffer(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const acrylic = () => {
    fetchDataFromAPI("user/acrylicPlates/get-acryli-plates", "get", "", "")
      .then((res) => {
        console.log("res", res);
        setAcrylic(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const registration = () => {
    fetchDataFromAPI("user/payment/get-payment-details", "get", "", "")
      .then((res) => {
        console.log("res", res);
        setPayment(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const contact = () => {
    fetchDataFromAPI("user/contactUs/get-contactUs?type=1", "get", "", "")
      .then((res) => {
        setContact(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const plateViewer = () => {
    fetchDataFromAPI("user/contactUs/get-contactUs?type=2", "get", "", "")
      .then((res) => {
        setPlateViewer(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <></>
    // <>
    //   <main id="main" className="main">
    //     <div className="pagetitle">
    //       <h1>Dashboard</h1>
    //     </div>
    //     {/* End Page Title */}
    //     <section className="section">
    //       <div className="row">
    //         <div className="col-lg-6">
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Valuation List</h5>
    //               {/* Default Table */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table text-nowrap table-striped">
    //                   <thead>
    //                     <tr className="">
    //                       <th scope="col" className="">
    //                         Date & Time
    //                       </th>
    //                       <th scope="col" className="">
    //                         Reg
    //                       </th>
    //                       <th scope="col" className="">
    //                         First Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Middle Initials
    //                       </th>
    //                       <th scope="col" className="">
    //                         Last Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Phone
    //                       </th>
    //                       <th scope="col" className="">
    //                         Email
    //                       </th>

    //                       {/* <th scope="col">View</th> */}
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isValuation?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isValuation.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td>
    //                               {item?.createdAt
    //                                 ? moment(item?.createdAt).format(
    //                                     "DD/MM/YYYY h:mm a"
    //                                   )
    //                                 : "-"}
    //                             </td>
    //                             {/* <td>{(currentPage - 1) * 10 + (index + 1)}</td> */}
    //                             <td className="">{item?.registrationNumber}</td>
    //                             <td className="">{item?.firstName}</td>
    //                             <td className="">
    //                               {item?.middleInitials
    //                                 ? item?.middleInitials
    //                                 : "-"}
    //                             </td>
    //                             <td className="">{item?.lastName}</td>
    //                             <td className="">
    //                               {item?.phone ? item?.phone : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.email ? item?.email : "-"}
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Default Table Example */}
    //             </div>
    //           </div>
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Offers List</h5>
    //               {/* Dark Table */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table text-nowrap table-striped">
    //                   <thead>
    //                     <tr className="">
    //                       <th scope="col" className="">
    //                         Date & Time
    //                       </th>
    //                       <th scope="col" className="">
    //                         Reg
    //                       </th>
    //                       <th scope="col" className="">
    //                         First Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Last Name
    //                       </th>
    //                       {/* <th scope="col"className="">Middle Initials</th> */}
    //                       {/* <th scope="col"className="">Last Name</th> */}
    //                       <th scope="col" className="">
    //                         Email
    //                       </th>
    //                       <th scope="col" className="">
    //                         Phone Number
    //                       </th>

    //                       <th scope="col" className="">
    //                         Offer price
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isOffer?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isOffer.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td>
    //                               {item?.createdAt
    //                                 ? moment(item?.createdAt).format(
    //                                     "DD/MM/YYYY h:mm a"
    //                                   )
    //                                 : "-"}
    //                             </td>
    //                             <td>{item?.reg}</td>
    //                             <td className="">{item?.first_name}</td>
    //                             <td className="">{item?.last_name}</td>
    //                             {/* <td className="">{item?.middle_initials}</td> */}
    //                             {/* <td className="">{item?.last_name}</td> */}
    //                             <td className="">
    //                               {item?.email ? item?.email : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.phone_number
    //                                 ? item?.phone_number
    //                                 : "-"}
    //                             </td>

    //                             <td className="">
    //                               £
    //                               {formatNumber(
    //                                 parseFloat(item?.offer_price).toFixed(2)
    //                               )}
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Dark Table */}
    //             </div>
    //           </div>
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Acrylic Orders List</h5>

    //               {/* Active Table */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table text-nowrap table-striped">
    //                   <thead>
    //                     <tr>
    //                       <th scope="col">Date & Time</th>
    //                       <th scope="col" className="text-center">
    //                         First Name
    //                       </th>
    //                       <th scope="col" className="text-center">
    //                         Last Name
    //                       </th>
    //                       <th scope="col" className="text-center">
    //                         Email
    //                       </th>
    //                       <th scope="col" className="text-center">
    //                         Acrylic Reg.
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isAcrylic.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isAcrylic.map((item, index) => (
    //                         <tr key={index}>
    //                           <td scope="row">
    //                             <span>
    //                               {item?.createdAt
    //                                 ? moment(item?.createdAt).format(
    //                                     "DD/MM/YYYY h:mm a"
    //                                   )
    //                                 : "-"}
    //                             </span>
    //                           </td>
    //                           <td className="text-center">{item?.firstName}</td>
    //                           <td className="text-center">{item?.lastName}</td>
    //                           <td className="text-center">
    //                             {item?.email ? item?.email : "-"}
    //                           </td>
    //                           <td className="text-center">
    //                             {item?.acrylicReg ? item?.acrylicReg : "-"}
    //                           </td>
    //                         </tr>
    //                       ))
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Active Table */}
    //             </div>
    //           </div>
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Contact Forms List</h5>

    //               {/* Active Table */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table text-nowrap table-striped ">
    //                   <thead>
    //                     <tr>
    //                       <th scope="col">Date & Time</th>
    //                       <th scope="col">First Name</th>
    //                       <th scope="col">Last Name</th>
    //                       <th scope="col">Email</th>
    //                       <th scope="col">Middle Initials</th>
    //                       <th scope="col">Message</th>
    //                       <th scope="col">Subscribed</th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isContact?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isContact.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td>
    //                               {item?.createdAt
    //                                 ? moment(item?.createdAt)?.format(
    //                                     "DD/MM/YYYY h:mm a"
    //                                   )
    //                                 : "-"}
    //                             </td>
    //                             <td>{item?.first_name}</td>
    //                             <td>{item?.last_name}</td>
    //                             <td>{item?.email}</td>
    //                             <td className="">
    //                               {item?.middle_initials
    //                                 ? item?.middle_initials
    //                                 : "-"}
    //                             </td>
    //                             <td>
    //                               {item?.message?.length > 20
    //                                 ? item?.message?.slice(0, 20) + "..."
    //                                 : item?.message}
    //                             </td>
    //                             {/* <td>{item?.created_at}</td> */}
    //                             <td className="text-center">
    //                               <input
    //                                 type="checkbox"
    //                                 checked={item?.is_subscribe}
    //                                 disabled
    //                               />
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Tables without borders */}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-6">
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Sell Request List</h5>
    //               {/* Table with stripped rows */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table table-striped text-nowrap">
    //                   <thead>
    //                     <tr className="">
    //                       <th scope="col" className="">
    //                         Date & Time
    //                       </th>
    //                       <th scope="col" className="">
    //                         Reg
    //                       </th>
    //                       <th scope="col" className="">
    //                         Price
    //                       </th>
    //                       <th scope="col" className="">
    //                         First Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Last Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Middle Initials
    //                       </th>
    //                       <th scope="col" className="">
    //                         Phone
    //                       </th>
    //                       <th scope="col" className="">
    //                         Email
    //                       </th>
    //                       <th scope="col" className="">
    //                         Postcode
    //                       </th>
    //                       <th scope="col" className="">
    //                         Town
    //                       </th>

    //                       {/* <th scope="col">View</th> */}
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isSell?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isSell.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td scope="row">
    //                               <span>
    //                                 {/* {(currentPage - 1) * 10 + (index + 1)} */}
    //                                 {item?.createdAt
    //                                   ? moment(item?.createdAt).format(
    //                                       "DD/MM/YYYY h:mm a"
    //                                     )
    //                                   : "-"}
    //                               </span>
    //                             </td>
    //                             <td className="">{item?.registrationNumber}</td>
    //                             <td className="">
    //                               £
    //                               {formatNumber(
    //                                 parseFloat(item?.price).toFixed(2)
    //                               )}
    //                             </td>
    //                             <td className="">{item?.firstName}</td>
    //                             <td className="">{item?.lastName}</td>
    //                             <td className="">
    //                               {item?.middleInitials
    //                                 ? item?.middleInitials
    //                                 : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.phone ? item?.phone : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.email ? item?.email : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.postcode ? item?.postcode : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.town ? item?.town : "-"}
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>

    //               {/* End Table with stripped rows */}
    //             </div>
    //           </div>
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Registration Orders List</h5>
    //               {/* Table with hoverable rows */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table table-striped text-nowrap">
    //                   <thead>
    //                     <tr className="">
    //                       <th scope="col">Date & Time</th>
    //                       <th scope="col">Reg</th>
    //                       <th scope="col" className="">
    //                         First Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Last Name
    //                       </th>
    //                       <th scope="col" className="">
    //                         Email
    //                       </th>
    //                       <th scope="col" className="">
    //                         Confirm Payment
    //                       </th>

    //                       {/* <th scope="col">View</th> */}
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isPayment?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isPayment.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td scope="row">
    //                               {/* <span>{(currentPage -1) * 10 + (index + 1)}</span> */}
    //                               <span>
    //                                 {item?.createdAt
    //                                   ? moment(item?.createdAt).format(
    //                                       "DD/MM/YYYY h:mm a"
    //                                     )
    //                                   : "-"}
    //                               </span>
    //                             </td>
    //                             <td className="">{item?.reg}</td>
    //                             <td className="">{item?.firstName}</td>
    //                             <td className="">{item?.lastName}</td>
    //                             <td className="">
    //                               {item?.email ? item?.email : "-"}
    //                             </td>
    //                             <td className="">
    //                               {item?.finalPaymentConfirmation
    //                                 ? "Yes"
    //                                 : "No"}
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Table with hoverable rows */}
    //             </div>
    //           </div>
    //           <div className="card">
    //             <div className="card-body">
    //               <h5 className="card-title">Plate Viewer Forms List</h5>

    //               {/* Bordered Table */}
    //               <div className="table-responsive card-body pb-0">
    //                 <table className="table table-striped text-nowrap">
    //                   <thead>
    //                     <tr>
    //                       <th scope="col">Date & Time</th>
    //                       <th scope="col">First Name</th>
    //                       <th scope="col">Last Name</th>
    //                       <th scope="col">Email</th>
    //                       <th scope="col">Middle Initials</th>
    //                       <th scope="col">Message</th>
    //                       <th scope="col">Subscribed</th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     {isPlateViewer?.length === 0 ? (
    //                       <tr>
    //                         <td colSpan="5" className="text-center">
    //                           No data available
    //                         </td>
    //                       </tr>
    //                     ) : (
    //                       isPlateViewer.map((item) => {
    //                         return (
    //                           <tr>
    //                             <td>
    //                               {item?.createdAt
    //                                 ? moment(item?.createdAt)?.format(
    //                                     "DD/MM/YYYY h:mm a"
    //                                   )
    //                                 : "-"}
    //                             </td>
    //                             <td>{item?.first_name}</td>
    //                             <td>{item?.last_name}</td>
    //                             <td>{item?.email}</td>
    //                             <td className="">
    //                               {item?.middle_initials
    //                                 ? item?.middle_initials
    //                                 : "-"}
    //                             </td>
    //                             <td>
    //                               {item?.message?.length > 20
    //                                 ? item?.message?.slice(0, 20) + "..."
    //                                 : item?.message}
    //                             </td>
    //                             {/* <td>{item?.created_at}</td> */}
    //                             <td className="text-center">
    //                               <input
    //                                 type="checkbox"
    //                                 checked={item?.is_subscribe}
    //                                 disabled
    //                               />
    //                             </td>
    //                           </tr>
    //                         );
    //                       })
    //                     )}
    //                   </tbody>
    //                 </table>
    //               </div>
    //               {/* End Bordered Table */}

    //               {/* End Primary Color Bordered Table */}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </main>
    // </>
  );
};

export default Dashboard;
