import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const AdminEdit = () => {
  const { state } = useLocation();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log("state", state);

  useEffect(() => {
    getAdminDetails();
  }, []);

  const getAdminDetails = () => {
    formik.setFieldValue("email", state?.user?.email);
    formik.setFieldValue("username", state?.user?.username);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter email"),
    username: Yup.string().required("Please enter username"),
    newPass: Yup.string()
      // .matches(
      //   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$)[a-zA-Z0-9]{8}$/,
      //   "Password must be exactly 8 characters long and should only contain alphanumeric characters."
      // )
      .notRequired(),
    conNewPass: Yup.string()
      .oneOf(
        [Yup.ref("newPass"), null],
        "New password and repeat new password do not match."
      )
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      newPass: "",
      conNewPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      if (values?.newPass && !values?.conNewPass) {
        formik.setFieldError("conNewPass", "Please enter repeat new password");
        return;
      }
      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        email: values?.email,
        username: values?.username,
        newPassword: values?.conNewPass,
      };

      fetchDataFromAPI(`user/admin?id=${state?.user?._id}`, "patch", body, "")
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("content res:", res?.data);
          navigate("/admins");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating content: ", error);
        });
    },
  });

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
            <Link to="/driver">Content Edit</Link>
          </li> */}
            <li className="breadcrumb-item active">
              <span>Admin Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>

                        <input
                          name="username"
                          type="text"
                          className="form-control"
                          id="username"
                          value={formik.values.username}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "username",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.username && formik.errors.username && (
                          <div className="text-danger">
                            {formik.errors.username}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6  ">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>

                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="row col-mb-3" /> */}
                    {/* <label>Change Password</label> */}
                    {/* <hr className="mt-0" /> */}
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New password
                        </label>

                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? "text" : "password"}
                            className="form-control"
                            style={{
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "newPass",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: "0", // Adjust the radius value
                              borderBottomLeftRadius: "0", // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}
                          >
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Repeat new password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? "text" : "password"}
                            className="form-control"
                            style={{
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "conNewPass",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: "0", // Adjust the radius value
                              borderBottomLeftRadius: "0", // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}
                          >
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Update
                        {/* {!loading && (
                      <span className="indicator-label">
                        Save Updates
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{display: 'block'}}>
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )} */}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminEdit;
