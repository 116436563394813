import { createContext, useState } from "react";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [commetCount, setCommentCount] = useState();
  const [enquiryCount, setEnquiryCount] = useState();
  const [offerCount, setOfferCount] = useState();
  const [sellCount, setSellCount] = useState();
  const [contactCount, setContactCount] = useState();
  const [plateCount, setPlateCount] = useState();
  const [acrylicCount, setAcrylicCount] = useState();
  const [orderCount, setOrderCount] = useState();

  const value = {
    commetCount,
    setCommentCount,
    enquiryCount,
    setEnquiryCount,
    offerCount,
    setOfferCount,
    sellCount,
    setSellCount,
    contactCount,
    setContactCount,
    plateCount,
    setPlateCount,
    acrylicCount,
    setAcrylicCount,
    orderCount,
    setOrderCount,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
export { DataContext, DataProvider };
