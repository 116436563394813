import React, {useContext, useEffect, useState} from 'react';
import Processing from '../../components/Processing/Processing';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {FormGroup} from 'react-bootstrap';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import {Button, Modal} from 'react-bootstrap';
import {DataContext} from '../../context/dataContext';
const EnquiryEdit = () => {
  const {state} = useLocation();

  console.log('state?.user?._id', state?.user?._id);
  console.log('state', state);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showRespondModal, setShowRespondModal] = useState(false);

  const [showCommentModal, setShowCommentModal] = useState(false);

  const defaultResponseContent = `Many thanks for the valuation request for ${state?.user?.reg}.<br><br>We have valued the registration mark at {Enters price here}.<br><br>Our valuation price is based on what we can realistically return to you in the current market.<br><br>If you choose to advertise you plate for a higher price, this is fine but please note it is likely to increase the sales time. Likewise, if you choose to reduce the sales price, this could help generate a quicker sale.<br><br>If you'd like to discuss with one of our experts, please call us on 01582 347734. Or, if you'd like to go ahead, please reply 'yes' to this email and we'll get started right away.<br><br>Kind regards,<br>REG247.`;
  const [responseContent, setResponseContent] = useState(
    defaultResponseContent,
  );
  const [responseError, setResponseError] = useState('');

  const [commentContent, setCommentContent] = useState('');
  const [commentError, setCommentError] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const [comments, setComments] = useState([]);

  console.log('object', comments);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dataContext = useContext(DataContext);

  useEffect(() => {
    getData();
    getComments();
  }, []);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getData = () => {
    setLoading(true);
    formik.setFieldValue('reg', state?.user?.reg);
    formik.setFieldValue('first_name', state?.user?.first_name);
    formik.setFieldValue('middle_initials', state?.user?.middle_initials);
    formik.setFieldValue('last_name', state?.user?.last_name);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('phone_number', state?.user?.phone_number);
    formik.setFieldValue('message', state?.user?.message);

    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Please enter first name'),
    middle_initials: Yup.string().notRequired(),
    last_name: Yup.string().required('Please enter last name'),
    email: Yup.string()
      .email('Please enter valid email')
      .required('Please enter email'),
    phone_number: Yup.string()
      //   .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .notRequired(),

    message: Yup.string().required('Please enter message'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      middle_initials: '',
      last_name: '',
      email: '',
      phone_number: '',
      message: '',
      reg: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      const body = {
        first_name: values?.first_name,
        middle_initials: values?.middle_initials,
        last_name: values.last_name,
        email: values?.email,
        phone_number: values?.phone_number,
        message: values?.message,
        reg: values?.reg,
      };

      console.log('body', body);

      fetchDataFromAPI(`user/inquiry?id=${state?.user?._id}`, 'patch', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('Added successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);
          formik.resetForm();
          navigate('/enquiry');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error', error);
        });
    },
  });

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // funcation for read
  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.isCommentRead; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)
    setLoading(true);

    const body = {
      isCommentRead: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };
    fetchDataFromAPI(`user/inquiry?id=${state?.user?._id}`, 'patch', body, '')
      .then((res) => {
        console.log('res ------->', res);

        getCommentsCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        navigate('/enquiry');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getCommentsCount = () => {
    fetchDataFromAPI(`user/inquiry/?pending_msg=false`, 'get', '', '')
      .then((res) => {
        console.log('count --> ', res?.pendingMsg);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setEnquiryCount(res?.pendingMsg);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const deleteContent = () => {
    console.log('delete');
    setLoading(true);
    fetchDataFromAPI(`user/inquiry?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        console.log('res', res);
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
        navigate('/enquiry');
      })
      .catch((error) => {
        console.log('customer error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    // if (commentContent.trim() === "") {
    //   setCommentError("Please enter comment");
    //   return;
    // }

    const body = {
      inquiry_customer_id: state?.user?._id,
      comment: commentContent || responseContent,
    };
    setLoading(true);
    fetchDataFromAPI('user/inquiry/add-comment', 'post', body, '')
      .then((res) => {
        console.log('res', res);

        getComments();

        // toast.success(res?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: "bg-success text-white",
        // });
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setShowCommentModal(false);
        setCommentContent('');
      });
  };

  const handleResponseSubmit = () => {
    if (responseContent.trim() === '') {
      setResponseError('Response cannot be empty');
      return;
    }

    const body = {
      email: state?.user?.email,
      message: responseContent,
      reg: state?.user?.reg,
    };
    setLoading(true);
    fetchDataFromAPI('user/valutions/respond-to-enquery', 'post', body, '')
      .then((res) => {
        console.log('res', res);
        handleCommentSubmit();
        toast.success(res?.message + ' ✅ ', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        setShowRespondModal(false);
        // setResponseContent("");
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        setResponseContent('');
        setShowRespondModal(false);
      });

    // Your logic to handle the response submission
  };

  const showModal = () => {
    setShowRespondModal(true);
    setResponseError('');
    // setResponseContent("");
  };

  const showComment = () => {
    setShowCommentModal(true);
    setCommentError('');
    setCommentContent('');
  };

  const getComments = () => {
    fetchDataFromAPI(
      `user/inquiry/get-comment?inquery_id=${state?.user?._id}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('comment --> ', res);
        setComments(res?.data[0]?.comments?.reverse());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleEditorChange = (html) => {
    if (html.trim() === '') {
      setResponseError('Response content cannot be empty');
    } else {
      setResponseContent(html);
      setResponseError('');
    }
  };

  let nonSpaceEntered = false;
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/enquiry">Enquiry</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="registrationNumber"
                              className="form-label">
                              Reg
                            </label>
                            <input
                              name="registrationNumber"
                              type="text"
                              className="form-control"
                              id="registrationNumber"
                              value={formik.values.reg}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'reg',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.reg && formik.errors.reg && (
                              <div className="text-danger">
                                {formik.errors.reg}
                              </div>
                            )}
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="registrationNumber"
                              className="form-label">
                              Middle Initials
                            </label>
                            <input
                              name="registrationNumber"
                              type="text"
                              className="form-control"
                              id="registrationNumber"
                              value={formik.values.middle_initials}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'middle_initials',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.middle_initials &&
                              formik.errors.middle_initials && (
                                <div className="text-danger">
                                  {formik.errors.middle_initials}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              name="first_name"
                              type="text"
                              className="form-control"
                              id="first_name"
                              value={formik?.values?.first_name}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'first_name',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.first_name &&
                              formik.errors.first_name && (
                                <div className="text-danger">
                                  {formik.errors.first_name}
                                </div>
                              )}
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="lastName" className="form-label">
                              lastName
                            </label>
                            <input
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="last_name"
                              value={formik.values.last_name}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'last_name',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.last_name &&
                              formik.errors.last_name && (
                                <div className="text-danger">
                                  {formik.errors.last_name}
                                </div>
                              )}
                          </div>
                          <div className="col-md-3">
                            <label
                              htmlFor="SPSVLicenceNumber"
                              className="form-label">
                              Email
                            </label>
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              id="email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'email',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3">
                            <label
                              htmlFor="AgreementVersion"
                              className="form-label">
                              Phone
                            </label>
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              id="phone"
                              value={formik?.values?.phone_number}
                              onChange={(e) => {
                                let input = e.target.value;
                                const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                                if (!nonSpaceEntered) {
                                  // If no non-space character entered yet, trim spaces
                                  input = input.trimStart();
                                }
                                if (regex.test(input) || input === '') {
                                  formik.setFieldValue('phone_number', input);
                                  // Check if a non-space character has been entered
                                  if (input !== '' && !nonSpaceEntered) {
                                    nonSpaceEntered = true;
                                  }
                                }
                              }}
                            />
                            {formik.touched.phone_number &&
                              formik.errors.phone_number && (
                                <div className="text-danger">
                                  {formik.errors.phone_number}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row mb-3"></div>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <label htmlFor="message" className="form-label">
                              message
                            </label>
                            <textarea
                              name="message"
                              type="text"
                              className="form-control"
                              id="message"
                              value={formik.values.message}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'message',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            {formik.touched.message &&
                              formik.errors.message && (
                                <div className="text-danger">
                                  {formik.errors.message}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div
                      className="container mb-5"
                      style={{maxHeight: '400px'}}>
                      <div className="row justify-content-center mt-4">
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title mb-3">Comments</h4>
                              <hr className="my-1" />
                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'auto',
                                }}>
                                {comments?.length > 0 ? (
                                  comments.map((comment, index) => (
                                    <div key={index} className="card mb-3">
                                      <div className="card-body">
                                        <p className="card-text">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: comment?.msg,
                                            }}
                                          />
                                          {/* {comment?.msg} */}
                                        </p>
                                        <p className="text-end text-muted small">
                                          {new Date(
                                            comment?.timestamp,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-muted">No comments yet</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          onClick={() => funcationRead()}
                          type="submit"
                          // disabled={
                          //   state?.user?.isCommentRead === true ? true : false
                          // } // Disable if isCommentRead is false
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>
                          {state?.user?.isCommentRead === true
                            ? 'Mark as Unread'
                            : 'Mark as Read'}
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showModal()}
                          type="submit"
                          className="btn btn-secondary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-chat-dots-fill me-2"></i>
                          Respond to this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => setDeleteId(state?.user?._id)}
                          type="submit"
                          className="btn btn-danger"
                          // disabled
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-trash-fill me-2"></i>
                          Delete this enquiry
                        </button>
                      </div>
                      <div className="text-center mx-3">
                        <button
                          onClick={() => showComment()}
                          type="submit"
                          className="btn btn-info"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-plus-circle-fill me-2"></i>
                          Add a comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Enquiry Requests"
          body="Are you sure you want to delete this record?"
        />
      </section>

      {/*for commet add*/}
      <Modal
        show={showCommentModal}
        centered
        onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              name="content"
              id="content"
              className={`form-control ${commentError ? 'is-invalid' : ''}`}
              // className={`form-control ${
              //   formik.touched.content && formik.errors.content
              //     ? 'is-invalid'
              //     : ''
              // }`}
              // value={formik.values.content}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={commentContent}
              onChange={(e) => {
                setCommentContent(e.target.value);
                setCommentError('');
              }}
              style={{height: 200}}
            />
            {commentError && (
              <div className="invalid-feedback">{commentError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowCommentModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for email send */}
      <Modal
        show={showRespondModal}
        centered
        onHide={() => setShowRespondModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Respond to Enquiry : {state?.user?.reg}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ReactQuill
              theme="snow"
              className={`form-control ${responseError ? 'is-invalid' : ''}`}
              value={responseContent}
              onChange={handleEditorChange}
              // style={{ height: 150 }}

              // style={{height: 200}}
            />
            {responseError && (
              <div className="invalid-feedback">{responseError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1">
          <Button
            variant="secondary"
            onClick={() => setShowRespondModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResponseSubmit}>
            Respond via email
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default EnquiryEdit;
