import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const CustomerView = () => {
  const {state} = useLocation();

  console.log('state', state?.user);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/customer">Customer</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>View Customer</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-center">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-3 label">First Name :</div>
                            <div className="col-md-3">
                              {state?.user?.firstName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">Last Name :</div>
                            <div className="col-md-3">
                              {state?.user?.lastName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">Email :</div>
                            <div className="col-md-9">
                              {state?.user?.email ? state?.user?.email : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Phone :</div>
                            <div className="col-md-9">
                              {state?.user?.mobile ? state?.user?.mobile : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Postcode :</div>
                            <div className="col-md-9">
                              {state?.user?.postcode
                                ? state?.user?.postcode
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Town :</div>
                            <div className="col-md-9">
                              {state?.user?.town ? state?.user?.town : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">County :</div>
                            <div className="col-md-9">
                              {state?.user?.country
                                ? state?.user?.country
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Landline :</div>
                            <div className="col-md-9">
                              {state?.user?.landline
                                ? state?.user?.landline
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Address Line 1 :
                            </div>
                            <div className="col-md-9">
                              {state?.user?.addressLine1
                                ? state?.user?.addressLine1
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">
                              Address Line 2 :
                            </div>
                            <div className="col-md-9">
                              {state?.user?.addressLine2
                                ? state?.user?.addressLine2
                                : '-'}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Active :</div>
                            <div className="col-md-9">
                              {state?.user?.active ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CustomerView;
