import React, {useState, useEffect} from 'react';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import Processing from '../../components/Processing/Processing';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Pagination} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import moment from 'moment';
import fileDownload from 'js-file-download';
import DeleteModal from '../../components/DeleteModal/DeleteModal';

const GetPayment = () => {
  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  console.log('deleteId', deleteId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  useEffect(() => {
    getPayment(currentPage);
    console.log('current page', currentPage);
  }, [currentPage]);

  const getPayment = async (pageNumber) => {
    console.log('page number', pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,
    };

    axios({
      url: `${base_url}user/payment/get-payment-details?search=${searchQuery}`,
      params: params,
      method: 'get',
    })
      .then((response) => {
        console.log('res', response?.data?.totalPage);
        console.log('get reg payment -->', response?.data?.data);
        setPayment(response?.data?.data);
        setTotalPages(response?.data?.totalPage);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      reg: '',
    },
    validationSchema: Yup.object({
      reg: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      // getReg(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getPayment(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getPayment(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };
  const deletePayment = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/payment/delete-payment-Detials?payment_id=${deleteId}`,
      'delete',
      '',
      '',
    )
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getPayment();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };
  const markAsRead = () => {
    setLoading(true);
    fetchDataFromAPI(
      'user/payment/update-payment-details?unreadAllComments=true',
      'put',
      '',
      '',
    )
      .then((res) => {
        getPayment();
        setLoading(false);
        console.log('res', res);
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
      });
  };

  const getCsv = () => {
    fetchDataFromAPI(
      `user/payment/get-payment-details?downloadCSVFile=true&page=${currentPage}&search=${searchQuery}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('res --> csv', res);
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Dashboard</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Payment</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <form className="mx-3 mt-3" onSubmit={formik.handleSubmit}>
                    <div className="d-flex  align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="">
                          {/* <label htmlFor="reg" className="form-label">
                            Acrylic Reg :
                          </label> */}

                          <div className="input-group ms-4 rounded-1 overflow-hidden">
                            <input
                              // className="me-1"
                              type="text"
                              name="query"
                              placeholder="Search"
                              title="Enter search keyword"
                              style={{
                                flex: 1,
                                padding: '8px',
                                border: '1px solid #ccc',
                                // borderRadius: "5px",
                              }}
                              value={searchQuery}
                              onChange={(event) =>
                                setSearchQuery(event.target.value)
                              }
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                getPayment();
                                setCurrentPage(1);
                              }}
                              type="submit"
                              title="Search"
                              style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '8px 15px',
                                // borderRadius: "5px",
                              }}>
                              <i className="bi bi-search"></i>
                            </button>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="d-flex gap-2 ms-5 align-items-center">
                            <div onClick={() => getCsv()}>
                              {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                              {/* <i className="bi bi-share"></i> */}
                              <img
                                role="button"
                                style={{height: 20, width: 25}}
                                src={require('../../assets/images/export.png')}
                                alt="logo"
                              />
                              {/* </CSVLink> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="col align-self-end">
                          <button
                            type="submit"
                            className="btn btn-secondary align-self-end btn__add me-2"
                            onClick={() => markAsRead()}>
                            Mark as Unread
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <hr />
                  <div className="table-responsive card-body pb-0">
                    <table className="table text-nowrap  align-items-end table-hover">
                      <thead>
                        <tr className="">
                          <th scope="col">Date & Time</th>
                          <th scope="col">Reg</th>
                          <th scope="col" className="">
                            First Name
                          </th>
                          <th scope="col" className="">
                            Last Name
                          </th>
                          <th scope="col" className="">
                            Email
                          </th>
                          <th scope="col" className="">
                            Confirm Payment
                          </th>
                          <th scope="col">Action</th>

                          {/* <th scope="col">View</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {payment.map((item, index) => {
                          const isUnread = !item.is_read;
                          const rowClass = isUnread ? 'fw-bold' : '';
                          return (
                            <tr
                              className={`align-middle ${rowClass}`}
                              role="button"
                              onClick={() => {
                                navigate(`/payment/view/${item?._id}`, {
                                  state: {user: item},
                                });
                              }}>
                              <td scope="row">
                                {/* <span>{(currentPage -1) * 10 + (index + 1)}</span> */}
                                <span>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        'DD/MM/YYYY h:mm a',
                                      )
                                    : '-'}
                                </span>
                              </td>
                              <td className="">{item?.reg}</td>
                              <td className="">{item?.firstName}</td>
                              <td className="">{item?.lastName}</td>
                              <td className="">
                                {item?.email ? item?.email : '-'}
                              </td>
                              {/* <td className="">
                                
                                {item?.finalPaymentConfirmation ? 'Yes' : 'No'}
                              </td> */}

                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={item?.finalPaymentConfirmation}
                                />
                              </td>

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-warning me-2"
                                    role="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/payment/view/${item?._id}`, {
                                        state: {user: item},
                                      });
                                    }}>
                                    <i class="bi bi-eye"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteId(item?._id);
                                    }}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-5 cursor-pointer"
                    onClick={handlePrevious}
                    role="button">
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  payment.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-5 cursor-pointer"
                      onClick={handleNext}
                      role="button">
                      Next
                    </p>
                  )}
              </Pagination>
            )}

            {totalPages <= 1 && payment?.length === 0 && (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
      </section>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deletePayment}
        title="Delete Registration Orders"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default GetPayment;
