import React, {useContext, useEffect, useState} from 'react';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {toast} from 'react-toastify';
import fileDownload from 'js-file-download';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import axios from 'axios';
import {Pagination} from 'react-bootstrap';
import moment from 'moment';
import {DataContext} from '../../context/dataContext';

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offerCount, setofferCount] = useState(0);

  const dataContext = useContext(DataContext);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getOffers(currentPage);
    getOfferCount();
  }, [currentPage]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getOffers = async (pageNumber) => {
    console.log('page number', pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,
    };

    axios({
      url: `${base_url}user/userOffer?search=${searchQuery}`,
      params: params,
      method: 'get',
    })
      .then((response) => {
        console.log('res ---------->', response?.data?.totalPages);
        console.log('get reg', response?.data?.data);
        setOffers(response?.data?.data);
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getOffers(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getOffers(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const getOfferCount = () => {
    fetchDataFromAPI(`user/userOffer/count`, 'get', '', '')
      .then((res) => {
        console.log('res offer -->', res);
        setofferCount(res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getCsv = () => {
    fetchDataFromAPI(
      `user/userOffer?downloadCSVfile=true&page=${currentPage}&search=${searchQuery}`,
      'get',
      '',
      '',
    )
      .then((res) => {
        console.log('res', res);
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/userOffer?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success('Deleted successfully ✅', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getOffers();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setLoading(false);
      });
  };

  const markAsRead = () => {
    setLoading(true);

    fetchDataFromAPI('user/userOffer?unreadAllComments=true', 'patch', '', '')
      .then((res) => {
        getOffers();
        setLoading(false);

        console.log('res', res);
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
      });
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Offers</li>{' '}
            <span className="badge  bg-danger text-center ms-4">
              {offerCount}
            </span>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="col-md-6">
              <div className="input-group ms-4 rounded-1 overflow-hidden">
                {/* <label htmlFor="reg" className="form-label">
                          Reg:
                        </label> */}
                <input
                  className=""
                  type="text"
                  name="query"
                  placeholder="Search"
                  title="Enter search keyword"
                  style={{
                    flex: 1,
                    padding: '8px',
                    border: '1px solid #ccc',
                  }}
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    getOffers();
                    setCurrentPage(1);
                  }}
                  type="submit"
                  title="Search"
                  style={{
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    padding: '8px 15px',
                  }}>
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex gap-2 ms-5 align-items-center">
                <div onClick={() => getCsv()}>
                  {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                  {/* <i className="bi bi-share"></i> */}
                  <img
                    role="button"
                    style={{height: 20, width: 25}}
                    src={require('../../assets/images/export.png')}
                    alt="logo"
                  />
                  {/* </CSVLink> */}
                </div>
              </div>
            </div>

            <div className="col d-flex justify-content-end ">
              <button
                type="submit"
                className="btn btn-secondary align-self-end btn__add me-2"
                onClick={() => markAsRead()}>
                Mark as Unread
              </button>
            </div>

            {/* <div className="col d-flex justify-content-end ">
                      <button
                        type="submit"
                        className="btn btn-primary align-self-end btn__add"
                        onClick={() => navigate("/salesplates/add")}
                      >
                        Add Plates
                      </button>
                    </div> */}
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col" className="">
                    Date & Time
                  </th>
                  <th scope="col" className="">
                    Reg
                  </th>
                  <th scope="col" className="">
                    First Name
                  </th>
                  <th scope="col" className="">
                    Last Name
                  </th>
                  {/* <th scope="col"className="">Middle Initials</th> */}
                  {/* <th scope="col"className="">Last Name</th> */}
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Phone Number
                  </th>

                  <th scope="col" className="">
                    Offer price
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((item, index) => {
                  const isUnread = !item.is_read;

                  const rowClass = isUnread ? 'fw-bold' : '';
                  return (
                    <tr
                      className={`align-middle ${rowClass}`}
                      role="button"
                      onClick={() => {
                        navigate(`/offers/view/${item?._id}`, {
                          state: {user: item},
                        });
                      }}>
                      <td>
                        {item?.createdAt
                          ? moment(item?.createdAt).format('DD/MM/YYYY h:mm a')
                          : '-'}
                      </td>
                      <td>{item?.reg}</td>
                      <td className="">{item?.first_name}</td>
                      <td className="">{item?.last_name}</td>
                      {/* <td className="">{item?.middle_initials}</td> */}
                      {/* <td className="">{item?.last_name}</td> */}
                      <td className="">{item?.email ? item?.email : '-'}</td>
                      <td className="">
                        {item?.phone_number ? item?.phone_number : '-'}
                      </td>

                      <td className="">
                        £{item?.offer_price ? item?.offer_price : '-'}
                      </td>
                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon btn btn-sm btn-primary me-2"
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/offers/view/${item?._id}`, {
                                state: {user: item},
                              });
                            }}>
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            // onClick={() => deleteContent(item?._id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteId(item?._id);
                            }}>
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-5 cursor-pointer"
                  onClick={handlePrevious}
                  role="button">
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                offers.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-5 cursor-pointer"
                    onClick={handleNext}
                    role="button">
                    Next
                  </p>
                )}
            </Pagination>
          )}

          {totalPages <= 1 && offers?.length === 0 && (
            <p className="text-center">No data available</p>
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Offer Request"
        body="Are you sure you want to delete this record?"
      />
    </div>
  );
};

export default Offers;
