import React, { useState } from 'react';
import Processing from '../../components/Processing/Processing';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchDataFromAPI } from '../../utils/api';
import { toast } from 'react-toastify';
import { FormGroup } from 'react-bootstrap';

const EnquiryAdd = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    //   const [url, setURL] = useState(null);
    const validationSchema = Yup.object().shape({
        // image: Yup.string().required('Please select image'),
        first_name: Yup.string().required('Please enter first name'),
        middle_initials: Yup.string().required('Please enter middle initials'),
        last_name: Yup.string().required('Please enter last name'),
        email: Yup.string().email("Please enter valid email").required("Please enter email"),
        phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Please enter a phone number'),
        is_subscribe: Yup.string().required('please select is subscribe '),
        message: Yup.string().required('Please enter message'),

    });

    const formik = useFormik({
        initialValues: {
            first_name: '',
            middle_initials: '',
            last_name: '',
            email: '',
            phone_number: '',
            is_subscribe: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log('values', values);

            //   setLoading(true);
            //   const admin = JSON.parse(getUser());
            const body = {
                first_name: values?.first_name,
                middle_initials: values?.middle_initials,
                last_name: values.last_name,
                email: values?.email,
                phone_number: values?.phone_number,
                is_subscribe: values?.is_subscribe,
                message: values?.message,
            };
            console.log("body", body)
            fetchDataFromAPI('user/inquiry', 'post', body, '')
                .then((res) => {
                    setLoading(false);

                    toast.success('Added successfully ✅', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'bg-success text-white',
                    });
                    console.log('content res:', res?.data);
                    formik.resetForm();
                    navigate('/enquiry');
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error?.response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'bg-danger text-white',
                    });
                    console.log('Error', error);
                });
        },
    });

    return (
        <main id="main" className="main">
            {loading && <Processing />}
            <div className="pagetitle">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
                        <li className="breadcrumb-item active">
                            <span>Add Enquiry</span>
                        </li>
                    </ol>
                </nav>
            </div>
            {/* End Page Title */}
            <section className="section profile">
                <div className="row">
                    <div className="card">
                        <div className="card-body pt-3">
                            {/* Bordered Tabs */}

                            <div className="tab-content">
                                <div className="pt-3" id="profile-edit">
                                    {/* Profile Edit Form */}
                                    <form onSubmit={formik.handleSubmit}>
                                        {/* <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Selete Image
                        </label>
                        <FormGroup>
                          <input
                            className={`form-select ${
                              formik.touched.image && formik.errors.image
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="file"
                            name="image"
                            accept="image/*"
                            id="image"
                            // onChange={(e) =>
                            //   formik.setFieldValue('image', e.target.files[0])
                            // }
                            onChange={handleFileChange}
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formik.touched.image &&
                          formik.errors.image &&
                          !url && (
                            <div className="text-danger">
                              {formik.errors.image}
                            </div>
                          )}
                      </div>
                    </div> */}
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="first_name" className="form-label">
                                                    first name
                                                </label>

                                                <input
                                                    name="first_name"
                                                    type="text"
                                                    className="form-control"
                                                    id="first_name"
                                                    value={formik.values.first_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'first_name',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.first_name && formik.errors.first_name && (
                                                    <div className="text-danger">
                                                        {formik.errors.first_name}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="middle_initials" className="form-label">
                                                    Middle Initials
                                                </label>

                                                <input
                                                    name="middle_initials"
                                                    type="text"
                                                    className="form-control"
                                                    id="middle_initials"
                                                    value={formik.values.middle_initials}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'middle_initials',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.middle_initials &&
                                                    formik.errors.middle_initials && (
                                                        <div className="text-danger">
                                                            {formik.errors.middle_initials}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="last_name" className="form-label">
                                                    Last Name
                                                </label>

                                                <input
                                                    name="last_name"
                                                    type="text"
                                                    className="form-control"
                                                    id="last_name"
                                                    value={formik.values.last_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'last_name',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.last_name &&
                                                    formik.errors.last_name && (
                                                        <div className="text-danger">
                                                            {formik.errors.last_name}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email" className="form-label">
                                                    Email
                                                </label>

                                                <input
                                                    name="email"
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    value={formik.values.email}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'email',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.email &&
                                                    formik.errors.email && (
                                                        <div className="text-danger">
                                                            {formik.errors.email}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="phone_number" className="form-label">
                                                    Phone Number
                                                </label>

                                                <input
                                                    name="phone_number"
                                                    type="text"
                                                    className="form-control"
                                                    id="phone_number"
                                                    value={formik.values.phone_number}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'phone_number',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.phone_number &&
                                                    formik.errors.phone_number && (
                                                        <div className="text-danger">
                                                            {formik.errors.phone_number}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="is_subscribe" className="form-label">
                                                    is subscribe
                                                </label>
                                                {/* <select id="inputName5" className="form-control" {...formik.getFieldProps("is_subscribe")}>
                                                    <option >is subscribe</option>
                                                    <option value={true}>yes</option>
                                                    <option value={false}>No</option>
                                                </select> */}
                                                <select
                            name="is_subscribe"
                            className={`form-select ${
                              formik.touched.is_subscribe && formik.errors.is_subscribe
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="is_subscribe"
                            {...formik.getFieldProps('is_subscribe')}
                            aria-label="Select type">
                            <option value="">Select a type...</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                                                {formik.touched.is_subscribe &&
                                                    formik.errors.is_subscribe && (
                                                        <div className="text-danger">
                                                            {formik.errors.is_subscribe}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="message" className="form-label">
                                                    Message
                                                </label>

                                                <input
                                                    name="message"
                                                    type="text"
                                                    className="form-control"
                                                    id="message"
                                                    value={formik.values.message}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'message',
                                                            e.target.value?.trimStart(),
                                                        );
                                                    }}
                                                />
                                                {formik.touched.message &&
                                                    formik.errors.message && (
                                                        <div className="text-danger">
                                                            {formik.errors.message}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                {!loading && (
                                                    <span className="indicator-label">Add Enquiry</span>
                                                )}
                                                {loading && (
                                                    <span
                                                        className="indicator-progress"
                                                        style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                    {/* End Profile Edit Form */}
                                </div>
                            </div>
                            {/* End Bordered Tabs */}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default EnquiryAdd;
