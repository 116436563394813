import React, { useState, useEffect, useContext } from "react";
import { base_url, fetchDataFromAPI } from "../../utils/api";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import fileDownload from "js-file-download";
import moment from "moment";
import { DataContext } from "../../context/dataContext";

const DeletedRecords = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteId, setDeleteId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const [valuationCount, setvaluationCount] = useState(0);

  useEffect(() => {
    getContent(currentPage);
    getValuationCount();
  }, [currentPage]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getContent = async (pageNumber) => {
    console.log("page number", pageNumber);
    setLoading(true);
    const params = {
      page: pageNumber,
    };

    axios({
      url: `${base_url}user/valutions/get-valutions?isSoftDeleted=true&search=${searchQuery}`,
      params: params,
      method: "get",
    })
      .then((response) => {
        console.log("res", response?.data?.totalPage);
        console.log("get reg", response?.data?.data);
        setContent(response?.data?.data);
        setTotalPages(response?.data?.totalPage);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      reg: "",
    },
    validationSchema: Yup.object({
      reg: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      // getReg(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getContent(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getContent(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
          >
            {i}
          </Pagination.Item>
        );
      } else if (paginationItems[paginationItems.length - 1] !== "...") {
        paginationItems.push("...");
      }
    }

    return paginationItems;
  };

  function formatNumber(num) {
    // Convert the number to a string, handling different input types
    var numberString = typeof num === "number" ? num.toString() : String(num);

    // Handle non-decimal numbers by adding ".00"
    if (!numberString.includes(".") && numberString !== "0") {
      numberString += ".00";
    }

    // Split the number into integer and decimal parts
    var parts = numberString.split(".");

    // Format the integer part with commas using Intl.NumberFormat
    var formatter = new Intl.NumberFormat("en-US");
    var formattedInteger = formatter.format(parseInt(parts[0], 10));

    // Combine the formatted integer and decimal parts
    var formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "");

    return formattedNumber;
  }

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(
      `user/valutions/delete-valutions?valuations_id=${deleteId}&delete_type=hard`,
      "delete",
      "",
      ""
    )
      .then((res) => {
        toast.success("Deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getContent();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Content error: ", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setLoading(false);
      });
  };

  // const getCsv = () => {
  //   fetchDataFromAPI(
  //     "user/valutions/get-valutions?downloadCSVfile=true",
  //     "get",
  //     "",
  //     ""
  //   )
  //     .then((res) => {
  //       console.log("res", res);
  //       fileDownload(res, "data.csv");
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };

  const getValuationCount = () => {
    fetchDataFromAPI(
      `user/valutions/get-valutions?pending_msg=false`,
      "get",
      "",
      ""
    )
      .then((res) => {
        console.log("count --> ", res?.pendingMsg);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        setvaluationCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Dashboard</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/salesplates/getplates"}>Valuation Requests</Link>
            </li>
            <li className="breadcrumb-item active">Deleted Requests</li>{" "}
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <form className="mx-3 " onSubmit={formik.handleSubmit}>
                    <div className="d-flex mb-3 align-items-center justify-content-between">
                      <div className="d-flex align-items-center"></div>
                    </div>
                  </form>
                  <div className="ms-3 flex-grow-1">
                    {/* <label htmlFor="reg" className="form-label">
                    Reg :
                  </label> */}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="col-md-6">
                      <div className="input-group ms-4 rounded-1 overflow-hidden">
                        {/* <label htmlFor="reg" className="form-label">
                          Reg:
                        </label> */}
                        <input
                          className=""
                          type="text"
                          name="query"
                          placeholder="Search"
                          title="Enter search keyword"
                          style={{
                            flex: 1,
                            padding: "8px",
                            border: "1px solid #ccc",
                          }}
                          value={searchQuery}
                          onChange={(event) =>
                            setSearchQuery(event.target.value)
                          }
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            getContent();
                          }}
                          type="submit"
                          title="Search"
                          style={{
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            padding: "8px 15px",
                          }}
                        >
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-2">
                      <div className="d-flex gap-2 ms-5 align-items-center">
                        <div onClick={() => getCsv()}>
                          <img
                            role="button"
                            style={{ height: 20, width: 25 }}
                            src={require("../../assets/images/export.png")}
                            alt="logo"
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="table-responsive card-body pb-0">
                    <table className="table text-nowrap  align-items-end table-hover">
                      <thead>
                        <tr className="">
                          <th scope="col" className="">
                            Date & Time
                          </th>
                          <th scope="col" className="">
                            Reg
                          </th>
                          <th scope="col" className="">
                            Sales Price
                          </th>
                          <th scope="col" className="">
                            First Name
                          </th>
                          <th scope="col" className="">
                            Middle Initials
                          </th>
                          <th scope="col" className="">
                            Last Name
                          </th>
                          <th scope="col" className="">
                            Phone
                          </th>
                          <th scope="col" className="">
                            Email
                          </th>
                          <th scope="col" className="">
                            Action
                          </th>
                          {/* <th scope="col">View</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {content.map((item, index) => {
                          const isUnread = !item.isCommentRead;
                          const rowClass = isUnread ? "fw-bold" : "";
                          return (
                            <tr
                              className={`align-middle ${rowClass}`}
                              role="button"
                              onClick={() =>
                                navigate(
                                  `/salesplates/getplates/view/${item?._id}`,
                                  {
                                    state: { user: item },
                                  }
                                )
                              }
                            >
                              <td>
                                {item?.createdAt
                                  ? moment(item?.createdAt).format(
                                      "DD/MM/YYYY h:mm a"
                                    )
                                  : "-"}
                              </td>
                              {/* <td>{(currentPage - 1) * 10 + (index + 1)}</td> */}
                              <td className="">{item?.registrationNumber}</td>
                              <td className="">
                                {item?.admin_price
                                  ? `£${formatNumber(
                                      parseFloat(item?.admin_price).toFixed(2)
                                    )}`
                                  : "-"}
                              </td>
                              <td className="">{item?.firstName}</td>
                              <td className="">
                                {item?.middleInitials
                                  ? item?.middleInitials
                                  : "-"}
                              </td>
                              <td className="">{item?.lastName}</td>
                              <td className="">
                                {item?.phone ? item?.phone : "-"}
                              </td>
                              <td className="">
                                {item?.email ? item?.email : "-"}
                              </td>

                              <td className="">
                                <div className="d-flex justify-content-center ">
                                  {/* <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(
                                        `/salesplates/getplates/view/${item?._id}`,
                                        {
                                          state: { user: item },
                                        }
                                      );
                                    }}
                                  >
                                    <i class="bi bi-pencil-square"></i>
                                  </div> */}
                                  {/* <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() =>
                                      navigate(
                                        `/salesplates/getplates/edit/${item?._id}`,
                                        {
                                          state: { user: item },
                                        }
                                      )
                                    }
                                  >
                                    <i class="bi bi-pencil-square"></i>
                                  </div> */}
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    // onClick={() => deleteContent(item?._id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteId(item?._id);
                                    }}
                                  >
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-5 cursor-pointer"
                    onClick={handlePrevious}
                    role="button"
                  >
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  content.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-5 cursor-pointer"
                      onClick={handleNext}
                      role="button"
                    >
                      Next
                    </p>
                  )}
              </Pagination>
            )}

            {totalPages <= 1 && content?.length === 0 && (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
        {/* delete coupon modal */}
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Valuation Request"
          body="Are you sure you want to delete this record?"
        />
      </section>
    </main>
  );
};

export default DeletedRecords;
