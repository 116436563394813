import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";

const HomeContent = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user/data`, "get", "", "")
      .then((res) => {
        console.log("Content res: ", res);
        setContent(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Content error: ", error);
        setLoading(false);
      });
  };

  const deleteContent = (id) => {
    fetchDataFromAPI(`user/data?id=${id}`, "delete", "", "")
      .then((res) => {
        console.log("Content res: ", res);
        getContent();
      })
      .catch((error) => {
        console.log("Content error: ", error);
      });
  };

  return (
    // <!-- Top Selling -->
    <>
      <main id="main" className="main">
        {loading && <Processing />}
        <div className="pagetitle">
          {/* <h1>Dashboard</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active">Home Content</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card top-selling overflow-auto">
                    <div className="col d-flex justify-content-end mt-2  me-2">
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={() => navigate("/add-content")}
                      >
                        Add Content
                      </button>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Title</th>

                            <th scope="col">Description</th>

                            <th scope="col" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        {content.map((item, index) => {
                          let truncatedDescription = item?.description;

                          if (
                            truncatedDescription &&
                            truncatedDescription.length > 50
                          ) {
                            truncatedDescription =
                              truncatedDescription.slice(0, 50) + "...";
                          }
                          return (
                            <tbody>
                              <tr>
                                <td scope="row">
                                  <span>{index + 1}</span>
                                  {/* <a href="#">
                                    <img
                                      src="assets/img/product-1.jpg"
                                      alt=""
                                    />
                                  </a> */}
                                </td>

                                <td>{item?.title}</td>
                                <td>
                                  {/* <span >{truncatedDescription}</span> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: truncatedDescription,
                                    }}
                                  />
                                </td>
                                <td className="text-center">
                                  <div className="d-flex justify-content-center ">
                                    <div
                                      className="edit-icon btn btn-sm btn-primary me-2"
                                      role="button"
                                      onClick={() =>
                                        navigate(
                                          `/home-content/edit/${item?._id}`,
                                          {
                                            state: { user: item },
                                          }
                                        )
                                      }
                                    >
                                      <i class="bi bi-pencil-square"></i>
                                    </div>
                                    <div
                                      role="button"
                                      className="delete-icon btn btn-sm btn-danger"
                                      // onClick={() => deleteContent(item?._id)}
                                    >
                                      <i class="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
                {/* End Top Selling */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomeContent;
