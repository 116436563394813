import React, { useContext, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../../utils/api";
import { DataContext } from "../../context/dataContext";
import Processing from "../../components/Processing/Processing";

const PlateViewerFormsView = () => {
  const { state } = useLocation();
  console.log("enquiry: ", state?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dataContext = useContext(DataContext);

  const funcationRead = () => {
    const currentIsCommentRead = state?.user?.is_read; // Get the current value of isCommentRead
    const updatedIsCommentRead = !currentIsCommentRead; // Toggle the value (true becomes false, false becomes true)

    setLoading(true);
    const body = {
      is_read: updatedIsCommentRead.toString(), // Convert to string because it seems like your API expects a string value
    };
    fetchDataFromAPI(`user/contactUs?id=${state?.user?._id}`, "put", body, "")
      .then((res) => {
        console.log("res ------->", res);
        getPlateCount();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        navigate("/plate-viewer-forms");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getPlateCount = () => {
    fetchDataFromAPI(`user/contactUs/count?type=2`, "get", "", "")
      .then((res) => {
        console.log("count --> ", res?.data);
        // const unreadComments = res?.data.filter(
        //   (comment) => !comment.isCommentRead
        // );
        dataContext?.setPlateCount(res?.data);
        // setCommentsCount(res?.pendingMsg);
        // setComments(res?.data[0]?.comments.reverse());
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/plate-viewer-forms">Plate Viewer Forms</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Plate Viewer Details</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-start">
          <div className="col-xl-12 ">
            <div className="card ">
              <div className="card-body pt-6 ">
                <div className="tab-content pt-2 ">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview"
                  >
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-3">
                          <div className="row">
                            <div className="col-md-3 label">First Name :</div>
                            <div className="col-md-9">
                              {state?.user?.first_name}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Last Name :</div>
                            <div className="col-md-9">
                              {state?.user?.last_name}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 label">
                              Middle Initials :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.middle_initials
                                ? state?.user?.middle_initials
                                : "-"}
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-md-3 label">Address :</div>
                            <div className="col-md-9">
                              {state?.user?.streetAddress +
                                " " +
                                state?.user?.town +
                                " " +
                                state?.user?.county +
                                " " +
                                state?.user?.country +
                                "," +
                                state?.user?.postcode}
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-3 label">Email :</div>
                            <div className="col-md-9">{state?.user?.email}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Phone Number :</div>
                            <div className="col-md-9">
                              {state?.user?.phone_number
                                ? state?.user?.phone_number
                                : "-"}
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-md-3 label">
                              Vehicle Suitability :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.vehicleSuitability ? "Yes" : "No"}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-md-3 label">
                              Nominee Details :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.nomineeDetails
                                ? state?.user?.nomineeDetails
                                : "-"}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-md-3 label">Style Plate :</div>
                            <div className="col-md-9">
                              {state?.user?.styleNumberPlate
                                ? state?.user?.styleNumberPlate
                                : "-"}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-md-3 label">Rear Plate :</div>
                            <div className="col-md-9">
                              {state?.user?.rearPlate}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-md-3 label">
                              Payment Confirm :
                            </div>
                            <div className="col-md-3">
                              {state?.user?.finalPaymentConfirmation
                                ? "Yes"
                                : "No"}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-md-3 label">Amount :</div>
                            <div className="col-md-3">
                              {state?.user?.amount}
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-3 label">Message :</div>
                            <div className="col-md-9">
                              {state?.user?.message}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 label">Subscribed :</div>
                            <div className="col-md-9">
                              {state?.user?.is_subscribe ? "Yes" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="text-center mx-3">
                        <button
                          onClick={() => funcationRead()}
                          type="submit"
                          // disabled={
                          //   state?.user?.is_read === true ? true : false
                          // } // Disable if isCommentRead is false
                          className="btn btn-primary"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          <i class="bi bi-eye-fill me-2"></i>
                          {state?.user?.is_read === true
                            ? "Mark as Unread"
                            : "Mark as Read"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PlateViewerFormsView;
