import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchDataFromAPI } from "../../utils/api";
const EditSalesPlates = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  console.log("state", state?.user);
  console.log("state", state?.user?._id);
  console.log("state", state?.user?.firstName);

  const navigate = useNavigate();

  useEffect(() => {
    getSalesData();
  }, []);

  const getSalesData = () => {
    setLoading(true);
    formik.setFieldValue("registrationNumber", state?.user?.registrationNumber);
    formik.setFieldValue("plateHoldingStatus", state?.user?.plateHoldingStatus);
    formik.setFieldValue("keywords", state?.user?.keywords);
    formik.setFieldValue("firstName", state?.user?.firstName);
    formik.setFieldValue("lastName", state?.user?.lastName);
    formik.setFieldValue("email", state?.user?.email);
    formik.setFieldValue("phone", state?.user?.phone);
    formik.setFieldValue("streetAddress", state?.user?.streetAddress);
    formik.setFieldValue("town", state?.user?.town);
    formik.setFieldValue("country", state?.user?.country);
    formik.setFieldValue("postcode", state?.user?.postcode);

    formik.setFieldValue("confirmNumPlat", state?.user?.confirmNumPlat);
    formik.setFieldValue("contact_permission", state?.user?.contact_permission);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("Please enter reg"),
    plateHoldingStatus: Yup.string().required(
      "Please enter plate Holding Status"
    ),
    keywords: Yup.string().notRequired(),
    firstName: Yup.string().required("Please enter First name"),
    lastName: Yup.string().required("Please enter lastName"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Email must end with .com"
      )
      .required("Please enter an email"),

    phone: Yup.string()

      .transform((value) => (value ? value.replace(/\s/g, "") : value)) // Remove spaces
      // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),

    streetAddress: Yup.string().required("Please enter street address"),
    town: Yup.string().required("Please enter Town"),
    country: Yup.string().required("Please enter country"),
    postcode: Yup.string().required("Please enter postcode"),
  });

  const formik = useFormik({
    initialValues: {
      registrationNumber: "",
      plateHoldingStatus: "",
      keywords: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress: "",
      town: "",
      country: "",
      postcode: "",
      confirmNumPlat: "",
      contact_permission: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      setLoading(true);

      const body = {
        registrationNumber: values?.registrationNumber,
        firstName: values?.firstName,
        keywords: values?.keywords,
        plateHoldingStatus: values?.plateHoldingStatus,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        streetAddress: values?.streetAddress,
        country: values?.country,
        town: values?.town,
        postcode: values?.postcode,
      };

      console.log("body", body);

      fetchDataFromAPI(
        `user/valutions/update-valutions?valutions_id=${state?.user?._id}`,
        "put",
        body,
        ""
      )
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("update member res:", res?.data);
          navigate("/salesplates/getplates");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating: ", error);
        });
    },
  });

  return (
    <main id="main" className="main">
      {/* {loading && <Processing />} */}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/salesplates/getplates">Valuation Requests</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label
                            htmlFor="registrationNumber"
                            className="form-label"
                          >
                            Reg
                          </label>
                          <input
                            name="registrationNumber"
                            type="text"
                            className="form-control"
                            id="registrationNumber"
                            value={formik.values.registrationNumber}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "registrationNumber",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.registrationNumber &&
                            formik.errors.registrationNumber && (
                              <div className="text-danger">
                                {formik.errors.registrationNumber}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="plateHoldingStatus"
                            className="form-label"
                          >
                            Plate Holding Status
                          </label>
                          <input
                            name="plateHoldingStatus"
                            type="text"
                            className="form-control"
                            id="plateHoldingStatus"
                            value={formik.values.plateHoldingStatus}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "plateHoldingStatus",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.price &&
                            formik.errors.plateHoldingStatus && (
                              <div className="text-danger">
                                {formik.errors.plateHoldingStatus}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label htmlFor="keywords" className="form-label">
                          keywords
                        </label>
                        <input
                          name="keywords"
                          type="text"
                          className="form-control"
                          id="keywords"
                          value={formik.values.keywords}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "keywords",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.keywords && formik.errors.keywords && (
                          <div className="text-danger">
                            {formik.errors.keywords}
                          </div>
                        )}
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            name="firstName"
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={formik?.values?.firstName}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "firstName",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName && (
                              <div className="text-danger">
                                {formik.errors.firstName}
                              </div>
                            )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="lastName" className="form-label">
                            lastName
                          </label>
                          <input
                            name="lastName"
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={formik.values.lastName}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "lastName",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.lastName &&
                            formik.errors.lastName && (
                              <div className="text-danger">
                                {formik.errors.lastName}
                              </div>
                            )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="SPSVLicenceNumber"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            name="email"
                            type="text"
                            className="form-control"
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "email",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label
                            htmlFor="AgreementVersion"
                            className="form-label"
                          >
                            Phone
                          </label>
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            id="phone"
                            value={formik?.values?.phone}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "phone",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="streetAddress" className="form-label">
                            Street Address
                          </label>
                          <input
                            name="streetAddress"
                            type="text"
                            className="form-control"
                            id="streetAddress"
                            value={formik?.values?.streetAddress}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "streetAddress",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.streetAddress &&
                            formik.errors.streetAddress && (
                              <div className="text-danger">
                                {formik.errors.streetAddress}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label htmlFor="town" className="form-label">
                            Town
                          </label>
                          <input
                            name="town"
                            type="text"
                            className="form-control"
                            id="town"
                            value={formik?.values?.town}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "town",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.town && formik.errors.town && (
                            <div className="text-danger">
                              {formik.errors.town}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="country" className="form-label">
                            country
                          </label>
                          <input
                            name="country"
                            type="text"
                            className="form-control"
                            id="country"
                            value={formik?.values?.country}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "country",
                                e.target.value?.trimStart()
                              );
                            }}

                            // Adjust the format as needed
                          />
                          {formik.touched.country && formik.errors.country && (
                            <div className="text-danger">
                              {formik.errors.country}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="postcode" className="form-label">
                            Postcode
                          </label>
                          <input
                            name="postcode"
                            type="text"
                            className="form-control"
                            id="postcode"
                            value={formik.values.postcode}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "postcode",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.postcode &&
                            formik.errors.postcode && (
                              <div className="text-danger">
                                {formik.errors.postcode}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-between my-2">
                        <div className="form-check">
                          <input
                            name="confirmNumPlat"
                            type="checkbox"
                            className="form-check-input"
                            id="confirmNumPlat"
                            checked={formik.values.confirmNumPlat}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "confirmNumPlat",
                                e.target.checked
                              );
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="confirmNumPlat"
                          >
                            Confirm Number Plate
                          </label>
                          {formik.touched.confirmNumPlat &&
                            formik.errors.confirmNumPlat && (
                              <div className="text-danger">
                                {formik.errors.confirmNumPlat}
                              </div>
                            )}
                        </div>
                        <div className="form-check">
                          <input
                            name="contact_permission"
                            type="checkbox"
                            className="form-check-input"
                            id="contact_permission"
                            checked={formik.values.contact_permission}
                            onChange={(e) => {
                              formik.setFieldValue("vat", e.target.checked);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="contact_permission"
                          >
                            Contact Permission
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Save Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditSalesPlates;
