import React, { useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";

const CustomerAdd = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Please enter firstname"),
    lastname: Yup.string().required("Please enter lastname"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Email must end with .com"
      )
      .required("Please enter an email"),

    // mobile: Yup.string()
    //   .transform((value) => (value ? value.replace(/\s/g, "") : value)) // Remove spaces
    //   .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    //   .required("Please enter phone number"),

    mobile: Yup.string()
      .transform((value) => (value ? value.replace(/\s/g, "") : value)) // Remove spaces
      // .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required("Please enter phone number"),

    landline: Yup.string().notRequired(),
    addressline: Yup.string().notRequired(""),
    addresslineoptional: Yup.string().notRequired(),
    town: Yup.string().required("Please enter Town"),
    county: Yup.string().required("Please enter county"),
    postcode: Yup.string().required("Please enter postcode"),
    active: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      landline: "",
      addressline: "",
      addresslineoptional: "",
      town: "",
      county: "",
      postcode: "",
      active: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      setLoading(true);
      //   const admin = JSON.parse(getUser());

      const body = {
        firstName: values?.firstname,
        lastName: values?.lastname,
        email: values?.email,
        mobile: values?.mobile,
        landline: values?.landline,
        addressLine1: values?.addressline,
        addressLine2: values?.addresslineoptional,
        town: values?.town,
        country: values?.county,
        postcode: values?.postcode,
        active: values?.active ? values?.active : false,
      };

      console.log("body", body);

      fetchDataFromAPI(
        "user/managerCustomer/add-manage-customer",
        "post",
        body,
        ""
      )
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("content res:", res?.data);
          formik.resetForm();
          navigate("/customer");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating member: ", error);
        });
    },
  });

  let nonSpaceEntered = false;

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/customer">Customer</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Add</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="form-label">
                          Firstname
                        </label>
                        <input
                          name="firstname"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={formik.values.firstname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "firstname",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="text-danger">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="form-label">
                          Lastname
                        </label>
                        <input
                          name="lastname"
                          type="text"
                          className="form-control"
                          id="lastname"
                          value={formik.values.lastname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "lastname",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="text-danger">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label
                          htmlFor="SPSVLicenceNumber"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="mobile" className="form-label">
                          Phone
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={formik?.values?.mobile}
                          onChange={(e) => {
                            let input = e.target.value;
                            const regex = /^[0-9\s]*$/; // Regular expression to allow only numbers and spaces
                            if (!nonSpaceEntered) {
                              // If no non-space character entered yet, trim spaces
                              input = input.trimStart();
                            }
                            if (regex.test(input) || input === "") {
                              formik.setFieldValue("mobile", input);
                              // Check if a non-space character has been entered
                              if (input !== "" && !nonSpaceEntered) {
                                nonSpaceEntered = true;
                              }
                            }
                          }}
                        />
                        {formik.touched.mobile && formik.errors.mobile && (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="landline" className="form-label">
                          Landline (optional)
                        </label>
                        <input
                          name="landline"
                          type="number"
                          className="form-control"
                          id="landline"
                          value={formik.values.landline}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "landline",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.landline && formik.errors.landline && (
                          <div className="text-danger">
                            {formik.errors.landline}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="addressline" className="form-label">
                          Address line 1 (optional)
                        </label>
                        <input
                          name="addressline"
                          type="text"
                          className="form-control"
                          id="addressline"
                          value={formik?.values?.addressline}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "addressline",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.addressline &&
                          formik.errors.addressline && (
                            <div className="text-danger">
                              {formik.errors.addressline}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="addresslineoptional"
                          className="form-label"
                        >
                          Address line 2 (optional)
                        </label>
                        <input
                          name="addresslineoptional"
                          type="text"
                          className="form-control"
                          id="addresslineoptional"
                          value={formik?.values?.addresslineoptional}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "addresslineoptional",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.addresslineoptional &&
                          formik.errors.addresslineoptional && (
                            <div className="text-danger">
                              {formik.errors.addresslineoptional}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <label htmlFor="town" className="form-label">
                          Town
                        </label>
                        <input
                          name="town"
                          type="text"
                          className="form-control"
                          id="town"
                          value={formik?.values?.town}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "town",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.town && formik.errors.town && (
                          <div className="text-danger">
                            {formik.errors.town}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="county" className="form-label">
                          County
                        </label>
                        <input
                          name="county"
                          type="text"
                          className="form-control"
                          id="county"
                          value={formik?.values?.county}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "county",
                              e.target.value?.trimStart()
                            );
                          }}

                          // Adjust the format as needed
                        />
                        {formik.touched.county && formik.errors.county && (
                          <div className="text-danger">
                            {formik.errors.county}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="postcode" className="form-label">
                          Postcode
                        </label>
                        <input
                          name="postcode"
                          type="text"
                          className="form-control"
                          id="postcode"
                          value={formik.values.postcode}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "postcode",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.postcode && formik.errors.postcode && (
                          <div className="text-danger">
                            {formik.errors.postcode}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 align-self-center d-flex gap-2">
                        <label className="form-check-label" htmlFor="active">
                          Active?
                        </label>
                        <div className="form-check">
                          <input
                            name="active"
                            type="checkbox"
                            className="form-check-input"
                            id="active"
                            checked={formik.values.active}
                            onChange={(e) => {
                              formik.setFieldValue("active", e.target.checked);
                            }}
                          />

                          {formik.touched.active && formik.errors.active && (
                            <div className="text-danger">
                              {formik.errors.active}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Add Customer</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CustomerAdd;
