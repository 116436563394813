import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import Processing from "../../components/Processing/Processing";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const HomeContentFaqEdit = () => {
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  console.log("state", state);

  const navigate = useNavigate();

  useEffect(() => {
    getContentData();
  }, []);

  const getContentData = () => {
    formik.setFieldValue("question", state?.user?.question);
    formik.setFieldValue("answer", state?.user?.answer);
  };

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Please enter question"),
    answer: Yup.string().required("Please enter answer"),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        question: values?.question,
        answer: values?.answer,
      };

      fetchDataFromAPI(`user/faq?id=${state?.user?._id}`, "patch", body, "")
        .then((res) => {
          setLoading(false);

          toast.success("updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("content res:", res?.data);
          navigate("/home-content/faq");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating content: ", error);
        });
    },
  });

  const handleEditorChange = (html) => {
    formik.setFieldValue("answer", html);
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item active">
              <Link to="/driver">Faq Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>Faq Edit</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-">
                        <label htmlFor="question" className="form-label">
                          Question
                        </label>

                        <input
                          name="question"
                          type="text"
                          className="form-control"
                          id="question"
                          value={formik.values.question}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "question",
                              e.target.value?.trimStart()
                            );
                          }}
                        />
                        {formik.touched.question && formik.errors.question && (
                          <div className="text-danger">
                            {formik.errors.question}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="answer" className="form-label">
                          Answer
                        </label>

                        {/* <textarea
                          name="answer"
                          id="answer"
                          className={`form-control ${
                            formik.touched.answer && formik.errors.answer
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.answer}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'answer',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          style={{height: 200}}
                        />
                        {formik.touched.answer && formik.errors.answer && (
                          <div className="text-danger">
                            {formik.errors.answer}
                          </div>
                        )} */}
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.answer}
                            onChange={handleEditorChange}
                            style={{ height: 200 }}
                            // onBlur={formik.handleBlur}

                            // style={{height: 200}}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Save Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeContentFaqEdit;
