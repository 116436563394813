import React, {useState} from 'react';
import Processing from '../../components/Processing/Processing';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {FormGroup} from 'react-bootstrap';

const BlogAdd = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [url, setURL] = useState(null);
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required('Please select image'),
    sub_title: Yup.string().required('Please enter sub title'),
    title: Yup.string().required('Please enter title'),
    discription: Yup.string().required('Please enter description'),
  });

  const formik = useFormik({
    initialValues: {
      image: null,
      sub_title: '',
      title: '',
      discription: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        image: url,
        title: values?.title,
        sub_title: values.sub_title,
        discription: values?.discription,
      };

      fetchDataFromAPI('user/blog', 'post', body, '')
        .then((res) => {
          setLoading(false);

          toast.success('Added successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);
          formik.resetForm();
          navigate('/blog');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating member: ', error);
        });
    },
  });

  const handleFileChange = (e) => {
    console.log('e?.target?.files[0]: ', e?.target?.files[0]);
    // setFile(e?.target?.files[0]);
    formik.setFieldValue('image', e.target.files[0]);
    // const image = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the image field
    // Clear any validation error for the image field
    setLoading(true);
    fetchDataFromAPI('user/upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLoading(false);
      });
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('discription', html);
  };
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>Add Blog</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Selete Image
                        </label>
                        <FormGroup>
                          <input
                            className={`form-control form-control-file${
                              formik.touched.image &&
                              formik.errors.image &&
                              !url
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="file"
                            name="image"
                            accept="image/*"
                            id="image"
                            // onChange={(e) =>
                            //   formik.setFieldValue('image', e.target.files[0])
                            // }
                            onChange={handleFileChange}
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formik.touched.image &&
                          formik.errors.image &&
                          !url && (
                            <div className="text-danger">
                              {formik.errors.image}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>

                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          id="title"
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'title',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="subtitle" className="form-label">
                          Sub Title
                        </label>

                        <input
                          name="subtitle"
                          type="text"
                          className="form-control"
                          id="title"
                          value={formik.values.sub_title}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'sub_title',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.sub_title &&
                          formik.errors.sub_title && (
                            <div className="text-danger">
                              {formik.errors.sub_title}
                            </div>
                          )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label htmlFor="lastName" className="form-label">
                          Description
                        </label>

                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.discription}
                            onChange={handleEditorChange}
                            style={{height: 150}}

                            // style={{height: 200}}
                          />
                        </div>
                        {formik.touched.discription &&
                          formik.errors.discription && (
                            <div className="text-danger">
                              {formik.errors.discription}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Add Blog</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogAdd;
